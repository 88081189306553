@import "../../../index.scss";
.popupOverlay{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.70);
    z-index: 2;
    .popupView{
        display: flex;
        flex-direction: column;
        position:absolute;
        left:50%;
        top:50%;
        transform: translate(-50%, -50%);
        border:2px solid $primaryLightGreen;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
        background-color: $primaryWhite;
        border-radius: 6px;
        .header{
            display:flex;
            justify-content: space-between;
            align-items: center;
            margin-top:18px;
            margin-left: 20px;
            margin-right:20px;
            padding-bottom: 18px;
            border-bottom: 1px solid $tertiaryGray2;
            .heading {
                color: $tertiaryBlack;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                font-family: $UrbanistSemiBold;
            }
            .cancel{
                height:24px;
                cursor:pointer;
            }
        }
    }
}