@import '../../../index.scss';

.info {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    // padding: 0 30px;
    width: 100%;

    .priceFrame {
        margin-left: 1px !important;
    }

    .priceIcon {
        background-color: $tertiaryGray3 !important;
    }

    .priceText {
        color: $tertiaryGray3;
        letter-spacing: 0.1px;
    }
    .generalFrame,
    .priceFrame {
        display: flex;
        align-items: center;
        gap: 7px;
        // margin-left: 30px;
        margin-top: 25px;

        .generalicon,
        .priceIcon {
            height: 20px;
            width: 20px;
            border-radius: 25px;
            background-color: $primaryPink;
            color: $primaryWhite;
            text-align: center;
        }
        .priceIcon1{
            background-color: $primaryLightGreen !important;
        }

        .generalText,
        .priceText {
            color: $headingColorOD;
            font-family: $UrbanistSemiBold;
            font-size: 15px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.2px;
        }
        .priceText1{
            color: $primaryLightGreen !important;
            letter-spacing: 0.1px;
        }
    }

    .infoLine {
        width: 1px;
        margin-top: 25px;
        margin-left: 16px;
        height: 27px;
        background-color: $tertiaryGray3;
        margin-right: 20px;

    }
    .priceFrame{
        margin-left: 0px !important;
    }
}