@import "../../../common/styles/variables.scss";


body {
    overflow: hidden;
}
.myCartPageWrapper{
    margin: 40px;
    height: calc(100% - 70px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $scrollBarColor;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: $scrollBarColor;
        border-radius: 10px;
    }
}
.myCartWrapper {
    background-color: $primaryWhite;
    padding: 20px 30px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    letter-spacing: 0.5px;
}