@import '../../../../index.scss';

.container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;

    .heading {
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
        color: $tertiaryBlack;
    }

    .formContainer {
        .formField {
            margin-top: 30px;

            .label {
                color: $tertiaryBlack;
                font-family: $UrbanistRegular;
                font-size: 14px;
                line-height: normal;
                letter-spacing: 0.5px;
            }

            .dateContainer {
                margin-top: 10px;
            }

            .uploadContainer {
                margin-top: 10px;
            }

            .datePickerContainer {
                margin-top: 10px;
            }
        }

    }

    .buttonConatiner {
        float: right;
        margin-top: 60px;

        .submit {
            all: unset;
            cursor: pointer;
            border-radius: 6px;
            background: $primaryPink;
            width: 120px;
            height: 40px;
            color: $primaryWhite;
            font-family: $UrbanistRegular;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            text-align: center;
            margin-left: 30px;
        }
    }
}