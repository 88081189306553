@import "../../../../common/styles/variables.scss";

.CartProducts {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100vh;
    overflow: auto;
    padding-right: 20px;
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $scrollBarColor;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: $scrollBarColor;
        border-radius: 10px;
    }
}