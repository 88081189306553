@import "../../../../../index.scss";

.card {
  display: flex;
  align-items: center;
  background-color: $primaryWhite;
  width: 100%;
  border-radius: 6px;
  // padding-left: 20px;
  // padding-right: 20px;
  // padding-top: 16px;
  // padding-bottom: 16px;
  border: 1px solid $primaryWhite;
  cursor: pointer;
  padding: 25px 20px;
  height: 76px;
  gap: 5px;
  .product {
    display: flex;
    align-items: center;
    width: 24%;
    max-width: 24%;
    min-width: 24%;
    .productImgContainer {
      height: 44px;
      // width: 44px;
      min-width: 44px;
      max-width: 44px;
      margin-right: 16px;
      .productImg {
        height: 100%;
        width: 100%;
        border: 1px solid $tertiaryGray3;
        border-radius: 4px;
        object-fit: contain;
        box-sizing: border-box;

        &.deactivateImg {
          filter: grayscale(1);
        }
      }
    }

    .productName {
      color: $tertiaryGray10;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistRegular;
      margin-right: 6px;
      max-width: 190px;
      // width: 130px;
    }

    .active {
      color: $activeColor;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistRegular;
      // padding-left: 10px;
      // padding-right: 10px;
      background-color: $secondaryThickGreen;
      border-radius: 40px;
      height: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 10px;
      height: 10px;
    }

    .inActive {
      background-color: #dddddd;
      border-radius: 40px;
      width: 10px;
      height: 10px;
    }
    .offlineTag {
      border-radius: 40px;
      background-color: $activeBgColor;
      color: $activeColor;
      font-family: $UrbanistRegular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0.5px;
      display: inline-block;
      width: 60px;
      height: 20px;
      text-align: center;
      padding: 1px 0px;
    }

    .inactiveMark {
      color: $tertiaryGray1;
      background-color: $inactivebgColor;
      width: 10px;
      height: 10px;
    }
  }

  .activeProduct {
    max-width: 22%;
    min-width: 22%;
    width: 22%;
  }

  .businessName {
    max-width: 16%;
    min-width: 16%;
    width: 16%;
    font-size: 14px;
    padding-right: 10px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    font-family: $UrbanistRegular;
    margin-right: 3%;
  }

  .productId {
    max-width: 17%;
    width: 17%;
    min-width: 17%;
  }

  .quantity {
    max-width: 11%;
    width: 11%;
    min-width: 11%;
  }

  .brandName {
    max-width: 10%;
    width: 10%;
    min-width: 10%;
  }

  .country {
    width: 16%;
    display: flex;
    align-items: center;
    padding-right: 10px;

    .countryFlag {
      margin-right: 3%;
      height: 15px;
      width: 15%;

      img {
        object-fit: cover;
        height: 15px;
        width: 22.5px;
      }
    }
  }

  .productType {
    display: flex;
    // align-items: center;
    width: 15%;
    max-width: 15%;
    min-width: 15%;

    .productSubCategory {
      // max-width: 150px;
      max-width: 100%;
    }

    .separator {
      // margin-left: 8px;
    }

    .productCategory {
      color: $tertiaryGray2;
      font-size: 14px;
      font-style: italic;
      line-height: normal;
      letter-spacing: 0.5px;
      width: 15%;
      font-family: $UrbanistItalic;
      // margin-left: 10px;
      // max-width: 150px;
      // width: 100%;
      width: fit-content;
      max-width: 100%;
    }
  }

  .actions {
    display: flex;
    margin-left: auto;
    position: relative;

    .actionItems {
      background-color: $primaryWhite;
      border-radius: 6px;
      padding-top: 10px;
      padding-bottom: 10px;
      position: absolute;
      top: 39px;
      z-index: 1;
      right: -21px;
      width: 251px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);

      .option {
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;

        .label {
          padding-left: 10px;
          color: $tertiaryBlack;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          font-family: $UrbanistRegular;
        }

        &:hover {
          background-color: $tertiaryGray9;
        }
      }

      &::after {
        content: " ";
        position: absolute;
        right: 24px;
        border-width: 16px 10px 16px 10px;
        top: -31px;
        border-style: solid;
        border-color: transparent transparent $primaryWhite transparent;
      }
    }
  }

  .textStyle {
    color: $textColor;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    font-family: $UrbanistRegular;
  }

  &:hover {
    border: 1px solid $primaryPink;
    // filter: drop-shadow(0px 0px 24px rgba(236, 24, 123, 0.15));
  }

  &:has(.actionItems) {
    border: 1px solid $primaryPink;
  }
}

.outOfStock {
  background-color: $tertiaryGray12;
  border: 1px solid $tertiaryGray3;
}

.inactive {
  color: rgba(44, 47, 53, 0.28) !important;
}

.checkBoxStyles {
  margin-right: 16px;
  z-index: 111;
}

@include minWidth(1500) {
  .card {
    width: 100%;
  }
}

@include minWidth(1700) {
  .card {
    width: 100%;
  }
}

.orderUnits {
  text-transform: capitalize;
}

.textOverflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
