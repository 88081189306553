@import "../../../../../index.scss";
.card {
    display: flex;
    background-color: $primaryWhite;
    border: 1px solid $primaryWhite;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    height: 66px;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
    border-radius: 6px;
    width: 100%;
    cursor: pointer;
    .details {
        display: flex;
        align-items: center;
        width:100%;
        .orderID {
            display: flex;
            align-items: center;
            max-width: 29.5%;
            min-width: 29.5%;
            width: 29.5%;
            text-overflow: ellipsis;
            overflow: hidden;
            .orderDetails {
                display: flex;
                flex-direction: column;
                margin-left: 3%;
                min-width: 80%;
                .id {
                    display: flex;
                    align-items: center;
                    .idNo {
                        color: $gray2;
                        font-size: 20px;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        font-family: $UrbanistRegular;
                        max-width: 80%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .paid {
                        margin-left: 2%;
                        color: $paidColor;
                        font-size: 14px;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        font-family: $UrbanistRegular;
                        border-radius: 40px;
                        background: $paidBackgroundColor;
                        height: 20px;
                        padding: 5px 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                    .unpaid {
                        margin-left: 6px;
                        color: $tertiaryGray1;
                        font-size: 14px;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        font-family: $UrbanistRegular;
                        border-radius: 40px;
                        background: $unpaidBackgroundColor;
                        height: 20px;
                        padding: 5px 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
        .deliveryStatus {
            color: $gray2;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistRegular;
        }
        .placedOn {
            width: 16.5%;
            min-width: 16.5%;
            max-width: 16.5%;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .buyerName {
            width: 14%;
            min-width: 14%;
            max-width: 14%;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .orderValue {
            width: 14.5%;
            min-width: 14.5%;
            max-width: 14.5%;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .actualAmount {
            width: 14%;
            min-width: 14%;
            max-width: 14%;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .orderItems {
            max-width: 10%;
            min-width: 10%;
            width: 10%;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        
    }

    &:hover{
        border: 1px solid $primaryPink;
        filter: drop-shadow(0px 0px 24px rgba(236, 24, 123, 0.15));
    }
    &.hover:hover {
        border: 1px solid $primaryWhite;
        filter: none;
        cursor: default;
    }
}

.flip{
    top: -160px !important;
    &::after {
        top: 150px !important;
        border-color:  $primaryWhite transparent  transparent transparent !important;
    }
}

// @include minWidth(1500){
//     .details{
//         gap:3%;
//         width: 100%;
//     }
// }

// @include minWidth(1700){
//     .details{
//         gap:5%;
//         width: 100%;
//     }
// }
