@import "../../../../../index.scss";

.approvalCard {
  display: flex;
  width:100%;
  background-color: $primaryWhite;
  height: 76px;
  align-items: center;
  padding: 0px 20px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 6px;

  &.cardStyles {
    padding: 27px 20px;
  }

  .logo {
    width: 50px;
    height: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .details {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding-left: 20px;
  }
  .cardDetails{
    padding-left: 0px !important;
  }

  .businessName {
    font-size: 20px;
  }

  .column {
    color: $tertiaryGray10;
    font-family: $UrbanistRegular;
    font-size: 14px;
    letter-spacing: 0.5px;
    min-width: 210px;
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    border: 1px solid $primaryPink;
    filter: drop-shadow(0px 0px 24px rgba(236, 24, 123, 0.15));
    
  }
}