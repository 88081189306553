@import "../../../index.scss";

.tableContainer {
    margin-top: 8px;
    > div {
        background-color: $primaryWhite;
    }
    margin-right: 40px;
    height: 600px;
    border-radius: 4px;
    padding: 0px 40px;

    .paginationContainer {
        display: flex;
        width: fit-content;
    }

    .inputFieldWrap {
        border: 1px solid $tertiaryGray2 !important;
        height: 40px;
        vertical-align: top;
        min-width: 400px;
        max-width: 400px;
        padding: 11px 10px;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: start;
        flex: 1;
        position: relative;

        &:hover {
            box-shadow: 0px 0px 8px 0px rgba($tertiaryGray6, 0.25);
        }

        &:focus-visible,
        &:focus {
            border: 1px solid $primaryPink !important;
            box-shadow: 0px 0px 24px rgba($primaryPink, 0.15) !important;
        }
    }

    .lookupDropDown {
        background-color: $primaryWhite;
        div {
            margin: 0px;
        }
    }
}

.heading {
    padding-top: 24px;
    padding-bottom: 16px;
    padding-left: 40px;
    padding-right: 40px;
    color: $tertiaryGray1;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    font-family: $UrbanistSemiBold;
}

.transparentInput {
    div {
        background: $primaryWhite;
    }
    padding: 4px 10px 4px 0px;
}

.commonBtn {
    height: 36px;
    background: $primaryPink;
    border: 1px solid transparent;
    color: $primaryWhite;
    border-radius: 6px;
    text-align: center;
    font-size: 14px;
    font-family: $UrbanistRegular;
    letter-spacing: 0.5px;
    cursor: pointer;
    transition: all ease 0.5s;
    width: 100%;
    padding: 8px 16px;

    &:hover {
        background: $primaryWhite;
        color: $primaryPink;
        border: 1px solid $primaryPink;
    }

    &:disabled {
        background: $secondaryPink;
        color: $disabledFontColor;
        border: 1px solid $secondaryPink;
        cursor: not-allowed;
    }
}
