@import "../../index.scss";

.login {
  background-color: $primaryWhite;

  height: 100%;
  background-image: url("../../assets/images/login/login-illustration.svg");

  background-repeat: no-repeat;
  background-position: bottom left;
  padding: 50px 60px 60px 60px;
  background-position-y: calc(100% - 50px);
  font-family: "UrbanistRegular";
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  &__logoSection {
    padding-top: 22px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 550px;

    .getStarted {
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 62px;
      font-family: $UrbanistBold;
      color: $tertiaryGray1;
      font-family: $UrbanistBold;

      &__line1,
      span {
        color: $primaryPink;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 62px;
        font-family: $UrbanistBold;
      }
      span:first-child {
        color: $tertiaryGray1;
      }
    }
  }

  &__formSection {
    background: $primaryWhite;
    box-shadow: 4px 4px 20px rgba($primaryBlack, 0.1);
    flex: 1;
    width: 446px;
    max-width: 460px;
    display: flex;
  }
  &.buyerBgImg {
    background-image: url("../../assets/images/login/buyerBgImg.svg");
    background-position: left bottom;
  }
}

@include maxWidth(1080) {
  .login {
    padding: 50px 40px 60px 40px;
    background-position-x: 189%;
    background-size: 116%;

    .logo {
      img {
        width: 80%;
      }
    }

    .getStarted {
      font-size: 28px;
      line-height: 44px;
      max-width: 290px;
    }

    &__formSection {
      max-width: 400px !important;
      width: 400px !important;
    }
  }
}

@include minWidth(1500) {
  .login {
    justify-content: space-around;

    .getStarted {
      font-size: 50px;
      font-weight: 700;
      line-height: 62px;
    }

    &__logoSection {
      max-width: 754px;
    }

    &__formSection {
      height: 91%;
      margin-top: 22px;
    }
  }
}

@include minWidth(1700) {
  .login {
    padding: 100px;
    background-image: url("../../assets/images/login_Illustration1920.svg");

    &__formSection {
      height: 85%;
      margin-left: 5%;
    }
  }
}

@include maxWidth(1281) {
  .login {
    background-size: 70%;
  }
}
@include minWidth(1900) {
  .login {
    &__logoSection {
      gap: 50px;
      max-width: 667px;
      .getStarted {
        &__line1,
        span {
          font-size: 70px;
          line-height: 86px;
        }
      }
    }
  }
}
