@import "../../../index.scss";

.productCarouselZoomContainer {
  width: 500px;
  height: 452px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 45px 45px 10px 45px;
  box-shadow: 0px 0px 20px 0px rgba($primaryBlack, 0.1);
  position: sticky;
  flex: 1 0 65%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.keen_slider__preview {
  height: 281px;
  width: 100%;
}

.ProductCarouselZoomText {
  color: $tertiaryGray2;
  font-size: 14px;
  font-style: italic;
  line-height: normal;
  letter-spacing: 0.5px;
  margin-top: 10px;
}

.preview_divider {
  border: solid 0.5px $tertiaryGray3;
  margin-top: 12px;
  margin-bottom: 16px;
  width: 100%;
}
.thumbnail {
  height: 50px;
  width: 90% !important;
  overflow-x: auto;
  width: fit-content;
  gap: 20px;
  padding: 4px;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
}

.thumbnailImageContainer {
  object-position: center;
  border-radius: 6px;
  min-height: 40px !important;
  min-width: 40px !important;
  max-height: 40px !important;
  max-width: 40px !important;
  transform: translate3d(0px, 0px, 0px) !important;
  img{
    object-fit: cover;

  }
}
.imagePreviewContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.imagePreviewContainer > div > img:first-child {
  height: 100%;
  object-fit: contain;
  width: auto !important;
}
.imagePreviewContainer > div {
  height: 100%;
  width: fit-content !important;
}
.active {
  border: 2px solid $primaryPink;
}

.glassMagnifier {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
@include minWidth(1500) {
  .productCarouselZoomContainer {
    height: 580px !important;
    width: 590px !important;
  }

  .keen_slider__preview {
    height: 70%;
  }

  .thumbnail {
    height: 90px !important;
    width: 100% !important;
    gap: 30px;
  }

  .thumbnailImageContainer {
    min-height: 70px !important;
    min-width: 70px !important;
  }

  .ProductCarouselZoomText {
    margin-top: 0 !important;
    font-size: 18px !important;
  }

  .preview_divider {
    margin-top: 25px !important;
  }
}

@include minWidth(1700) {
  .productCarouselZoomContainer {
    height: 670px !important;
    width: 680px !important;
  }

  .keen_slider__preview {
    height: 70%;
  }

  .ProductCarouselZoomText {
    margin-top: 0 !important;
    font-size: 22px !important;
  }

  .preview_divider {
    margin-top: 30px !important;
  }

  .thumbnail {
    height: 90px !important;
    width: 100% !important;
    gap: 35px;
  }

  .thumbnailImageContainer {
    min-height: 80px !important;
    min-width: 80px !important;
  }
}

@include maxWidth(1300) {
  .productCarouselZoomContainer {
    height: 400px !important;
    width: 450px !important;
  }
  .thumbnail {
    height: 65px !important;
    width: 100% !important;
    gap: 10px;
  }
}

@include maxWidth(1200) {
  .productCarouselZoomContainer {
    height: 340px !important;
    width: 360px !important;
  }

  .thumbnail {
    height: 65px !important;
    width: 100% !important;
    gap: 10px;
  }
}
