@import "../../../index.scss";

.card {
    display: flex;
    align-items: center;
    min-width: 353px;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 20px;
    padding-top: 27px;
    padding-bottom: 26px;
    border: 1px solid $primaryWhite;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: $primaryWhite;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    flex-grow:1;

    .icon {
        height: 90px;
        margin-top: 3px;
    }

    .cardContent {
        display: flex;
        flex-direction: column;
        padding-left: 34px;
        text-align: start;

        .heading {
            padding-bottom: 6px;
            color: $tertiaryGray1;
            font-size: 14px;
            font-style: normal;
            font-family: $UrbanistSemiBold;
            line-height: normal;
            letter-spacing: 0.5px;
        }

        .reviewHeading {
            color: $primaryRed  !important;
        }

        .successHeading {
            color: $primaryGreen;
        }

        .errorHeading {
            color: $secondaryRed;
        }

        .content {
            padding-bottom: 12px;
            color: $tertiaryBlack;
            font-size: 14px;
            font-style: normal;
            line-height: 20px;
            letter-spacing: 0.5px;
            font-family: $UrbanistRegular;
            white-space: pre-wrap;
        }

        .link {
            color: $primaryPink;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistRegular;
            border: none;
            background: none;
            text-align: start;
            padding: 0px;
            cursor: pointer;
        }

        .supplierLink {
            text-align: center;
        }

    }

    .supplierCardContent {
        padding-left: 0px;
        text-align: center;
        margin-top: 20px;
        width: 197px;
        white-space: pre-wrap;

        .supplierContent {
            padding-bottom: 20px;
        }
    }

    .stateIcon {
        border: 1px solid $tertiaryGold;
        border-radius: 50%;
        width: 78px;
        height: 78px;
        position: absolute;
        top: -32px;
        right: -21px;
        z-index: 0;
        box-shadow: -2px 2px 9px rgba(0, 0, 0, 0.19);

        .stateIconImage {
            left: 19px;
            width: 24px;
            bottom: 14px;
            height: 24px;
            position: absolute;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &.error {
            border-color: $secondaryRed;
        }

        &.success {
            border-color: $primaryLightGreen;
        }

        &.review {
            border-color: $tertiaryGold;
        }
    }

    &.card:hover {
        border: 1px solid $primaryPink;
        filter: drop-shadow(0px 0px 10px rgba(56, 52, 52, 0.25));
    }

    &.redBorder:hover {
        border-color: $secondaryRed;
    }

    &.greenBorder:hover {
        border-color: $primaryLightGreen;
    }

    &.orangeBorder:hover {
        border-color: $tertiaryGold;
    }

    &.supplierCard {
        min-width: 257px;
        flex-direction: column;
        padding: 30px;
        height: 290px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}

@include maxWidth(2100) {
    .card {
        width: 360px;
    }
}

@include maxWidth(1600) {
    .card {
        width: 353px;
    }
}

@include maxWidth(1300) {
    .card {
        width: 490px;
    }
}

@include maxWidth(1266) {
    .card {
        width: 365px;
    }
}