@import "./font.scss";
@import "./mediaQueries.scss";
@import "./variables.scss";

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input,
textarea {
  border: none !important;

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: $tertiaryGray2;
    font-family: $UrbanistRegular;
    font-size: 14px;
    letter-spacing: 0.5px;
  }
}

.inputLabelStyles {
  font-size: 14px;
  font-family: $UrbanistRegular;
  color: $tertiaryBlack;
  letter-spacing: 0.5px;
  // margin-bottom: 10px;
  margin-bottom: 6px;

  &.errorStyle {
    color: $secondaryRed !important;
  }
}
// label will always be black
// .disableLabelStyles{
//   color: #949494 !important;
// }

.errorBorder {
  border: 1px solid $secondaryRed !important;
}

.errorMessage {
  font-family: $UrbanistItalic;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: $secondaryRed;
  margin-top: 10px;
}

.countryDropdown {
  border-radius: 40px !important;
  padding-left: 32px !important;
  background: $backgroundThemeColor;

  /* link styles */
  .link_primary {
    font-size: 12px;
    line-height: 14px;
    color: $primaryPink;
    cursor: pointer;
    font-family: $UrbanistMedium;
  }
}

.reportCountryDropDown {
  border: 0px solid black !important;
  background-color: none !important;
  border-radius: 25px 0px 0px 25px !important;
}

.datePickerCustom {
  border: none !important;
  height: 36px !important;
  top: 0 !important;
  gap: 20px !important;
}

.commonHeading {
  color: $tertiaryGray1;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  font-family: $UrbanistSemiBold;
}

.w_15 {
  width: 15% !important;
  max-width: 15% !important;
  min-width: 15% !important;
}

.w_20 {
  min-width: 20% !important;
  width: 20% !important;
  max-width: 20% !important;
}
.w_30 {
  min-width: 30% !important;
  width: 30% !important;
  max-width: 30% !important;
}

.w_10 {
  min-width: 10% !important;
  width: 10% !important;
  max-width: 10% !important;
}
.w_40 {
  min-width: 40% !important;
  width: 40% !important;
  max-width: 40% !important;
}

.hide {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

// specially added for safari browsers to remove person icon with arrow in input fields
input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.hiddenMode {
  visibility: hidden;
}
.flexColumn {
  flex-direction: column;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.textOverflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.pointerEventsNone {
  pointer-events: none;
}
.pointerEventsAuto {
  pointer-events: auto;
}
.grayScale {
  -webkit-filter: grayscale(80%);
  filter: grayscale(80%);
}
.flex {
  display: flex;
}
