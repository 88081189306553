@import "../../../index.scss";

.parentContainer {
  width: 100%;
  height: 450px;
  flex-shrink: 0;
  background-color: $primaryWhite;
  padding: 30px;
  border-radius: 6px;

  .barGraph {
    height: 330px;
  }

  .footerContainer {
    width: 100%;
    height: 60px;
    background-color: $primaryWhite;
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 16px;
    gap: 15px;

    .footerIcon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
    }

    .footerText {
      color: $tertiaryGray1;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 142.857% */
      letter-spacing: 0.07px;
    }
  }
}

.tooltip {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
}

.custom-tooltip {
  position: absolute;
  &::before {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 8px 8px 0;
    border-color: rgba(104, 161, 75, 1) transparent transparent;
    bottom: 100%;
    transform: translateX(-50%);
  }
}
