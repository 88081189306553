@import "../../../../../common/styles/variables.scss";

.productsGroupBySupplierWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .allSupplierCartProducts {
    width: 100%;
  }
  .productNotes {
    min-width: 100% !important;
    height: 76px;
  }
}

.productsGroupBySupplierWrapper:not(:first-child){
  margin-top: 40px;
}
