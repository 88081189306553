@import '../../../index.scss';

.errorMessage {
    color: $secondaryRed;
    font-family: $UrbanistRegular;
    font-size: 12px;
    font-style: italic;
    line-height: normal;
    letter-spacing: 0.5px;
}

.checkbox {
    color: $primaryPink;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.rememberMeText {
    color: $tertiaryGray1;
    font-family: $UrbanistRegular;
    font-size: 16px;
    font-style: normal;
    line-height: 14px;
}

