@import "../../../index.scss";

.parent {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: row;
  height: 100%;

  .parentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px 40px 40px 40px;
  }

  .searchContainer {
    margin-bottom: 26px;
    width: 100%;
  }

  .tableContainer {
    // z-index: -1;
    background: $primaryWhite;
    overflow-x: auto;
    overflow-y: auto;
    border-radius: 6px;
    // padding-bottom: 30px;
    position: relative;
    height: 100%;

    .tableParent {
      height: 100%;

      table {
        width: 100%;
        position: relative;
        border-spacing: 0 10px;

        tr {
          &:not(:last-child) {
            box-shadow: 0 1px 1px #e3e3e3;

          }

          // background-color: blue;
          // &:first-child{
          //   // background-color: red;
          //   position: sticky !important;
          // }
          td {
            position: relative;

            &:first-child {
              position: sticky;
              left: 0;
              width: 172px;
              z-index: 3;
              background: $primaryWhite;
              // box-shadow: 10px 0px 4px -6px rgba(0, 0, 0, 0.12);
            }
          }
        }

        .theading {
          th {
            padding-top: 5px;
            min-width: 176px;
            width: 106px;
            color: $tertiaryGray2;
            font-family: $UrbanistRegular;
            font-size: 14px;
            letter-spacing: 0.5px;
            border-bottom: 1px solid $tertiaryGray2;
            padding-right: 50px;
            padding-bottom: 12px;
            padding-left: 14px;
            box-sizing: content-box;
            text-align: left;
            top: 0;
            position: sticky;
            background-color: #fff;
            z-index: 3;

            .required {
              color: $secondaryRed2;
            }

            &:first-child {
              position: sticky;
              left: 0;
              width: 172px;
              z-index: 5;
              background: $primaryWhite;
              // box-shadow: 10px 0px 4px -6px rgba(0, 0, 0, 0.12);
            }
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 6px !important;
      height: 6px !important;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      margin-top: 90px !important;
      margin-left: 240px !important;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollBarColor;
      border-radius: 10px;
    }

  }

  .column {
    display: flex;
    align-items: center;
    padding-left: 0px;
    padding-bottom: 3px;
    padding-top: 3px;

    input,
    select,
    span {
      color: $gray2;
      font-family: $UrbanistRegular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0.5px;
      padding: 15px;
      width: 170px;
      max-width: fit-content;
      // white-space: nowrap;
      margin-bottom: 0 !important;
    }

    input {
      border: 1px solid grey !important;
      border-radius: 5px !important;

      &:not(input[type="file"]) {
        min-width: 160px;
      }

      input[type="file"]:disabled {
        border-color: #949494;
        background-color: #949494;
      }
    }

    // div {
    //   color: $gray2;
    //   font-family: $UrbanistRegular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0.5px;
    //   padding: 15px;
    //   .description {
    //     width: 700px;
    //     max-width: 700px;
    //   }
    //   .brand {
    //     width: 240px;
    //     max-width: 240px;
    //   }
    // }
    select {
      border: none;
    }

    .disabledText {
      color: rgb(148, 148, 148);
      background-color: rgba(194, 195, 197, 0.2784313725);
      border: 1px solid rgba(44, 47, 53, 0.2784313725);
      border-radius: 5px;
      padding: 8px 16px;
      display: inline-block;
      min-width: 160px;
    }
  }

  .imageContainer {
    display: flex;
    align-items: center;
    gap: 6px;
    width: 100%;
    padding: 0 15px;

    .imageBox {
      width: 40px;
      border-radius: 4px;
      padding: 0;
      display: flex;
      flex-direction: column;
      position: relative;

      img {
        width: 40px;
        height: 40px;
        border: 1px solid gray;
        border-radius: 6px;
        object-fit: cover;
      }

    }

    .uploadLabel {
      height: 40px;
      width: 40px !important;
      border-radius: 4px;
      border: 1px dashed $primaryPink;
      background: $primaryWhite;
      display: flex;
      justify-content: center;
      align-items: center;

      label {
        border: 1px dashed $primaryPink;
        height: 40px;
        min-width: 40px;
        border-radius: 4px;
        background: $primaryWhite;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

      }

     
    }
 
    .imgLoading {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: #c0c0c0c9;
      pointer-events: none;

      span {
        padding: 0;
        max-width: none;
        color: #1976d2;
      }
    }
  }

  .errorShown {
    background: rgb(235, 72, 72, 0.2) !important;
  }

  .universalErrorDataContainer {
    background: $primaryWhite;
    margin-bottom: 46px;
    border-radius: 6px;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftContainer {
      display: flex;
      gap: 5px;

      .detailsContainer {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .heading {
          color: $tertiaryGray1;
          font-family: $UrbanistSemiBold;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.5px;
        }

        .details {
          color: $tertiaryGray1;
          font-family: $UrbanistRegular;
          font-size: 14px;
          line-height: normal;
          letter-spacing: 0.5px;
        }
      }
    }

    .middleContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .upContainer {
        display: flex;
        align-items: end;
        gap: 6px;

        .data {
          span {
            &:first-child {
              color: $secondaryRed;
              font-family: $UrbanistRegular;
              font-size: 24px;
              line-height: 20px;
              letter-spacing: 1.5px;
            }

            &:nth-child(2) {
              color: $tertiaryGray1;
              font-family: $UrbanistRegular;
              font-size: 24px;
              line-height: 20px;
              letter-spacing: 1.5px;
            }

            &:last-child {
              color: $tertiaryGray1;
              font-family: $UrbanistRegular;
              font-size: 24px;
              line-height: 20px;
              letter-spacing: 0.5px;
            }
          }
        }

        .heading {
          color: $tertiaryGray1;
          font-family: $UrbanistRegular;
          font-size: 10px;
          line-height: normal;
          letter-spacing: 0.5px;
        }
      }

      .downContainer {
        color: $tertiaryGray1;
        font-family: $UrbanistRegular;
        font-size: 14px;
        line-height: 12px;
        letter-spacing: 0.07px;
      }
    }

    .rightContainer {
      label {
        cursor: pointer;
        padding: 15px 30px;
        border-radius: 6px;
        border: 1px solid $primaryPink;
        background: $primaryWhite;
        color: $primaryPink;
        text-align: center;
        font-family: $UrbanistRegular;
        font-size: 14px;
        line-height: normal;
        letter-spacing: 0.5px;
      }
    }
  }

  .breadcrumb {
    margin-bottom: 20px;
  }

  .buttonContainer {
    display: flex;
    justify-content: end;
    margin-top: 40px;
    gap: 30px;
    align-self: end;

    button {
      padding: 15px 38px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.pendingImgLoad {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageModalBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  .header {
    background-color: #000;
    position: absolute;
    top: 0;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    color: #fff;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      transform: rotate(180deg);
      cursor: pointer;
    }
  }

  .topdiv {
    width: 400px;
    display: flex;
    justify-content: end;
  }
}

.imageModalBox img {
  width: 400px;
  height: 400px;
  object-fit: contain;
}

.uploadOverlay {
  height: 100%;
  width: fit-content;
  position: absolute;
  background-color: rgba($primaryPink, 0.05);
  border-radius: 4px;
  top: 0;
  opacity: 1;
  visibility: visible;
  bottom: -14px;

  .overlayButtons {
    position: absolute;
    bottom: 0;
    // right: 130px;
    background-color: #ec187b;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    padding: 2px;
    border-radius: 0px 0px 5px 5px;

    .eyeButton,
    .deleteButton {
      cursor: pointer;
      width: 15px;
      height: 15px;

      img {
        height: 100%;
        width: 100%;
        border-radius: 4px;
      }
    }

    .deleteButton {
      cursor: pointer;
    }
  }
}

.uploadOverlay {
  opacity: 1;
  visibility: visible;
}

.condition {
  width: 560px;
  text-wrap: wrap;

  // display: flex;
  // justify-content: end;
  // align-items: center;
  &:not(:first-child) {
    margin-top: 14px;

  }

}

.popperPaper {
  padding: 14px 10px;
  margin: 10px;
}

.clsBtn {
  width: 60px;
}

.customDropDown {
  margin-bottom: 0px !important;
}

.editcelltext {
  display: flex;
  justify-content: flex-end;
  padding: 0 5px 5px 0;
  font-weight: 400;
  align-items: center;
  gap: 4px;
}
.textCenter{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #383434;
  font-family: $UrbanistSemiBold;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.shadowParent {
  position: relative;
  background: #fff;
  overflow-x: auto;
  overflow-y: auto;
  border-radius: 6px;
  height: 787px;

}




.shadow {
  // position: fixed;
  // height: 58%;
  width: 240px;
  z-index: 8;

  &::before {
    content: "";
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    box-shadow: 10px 0px 4px -6px rgba(0, 0, 0, 0.12);
  }
}

// xxxs: 320px

// xxs: 480px

// xs: 600px

// sm: 640px

// md: 768px

// lg: 1024px

// xl: 1280px

// 2xl: 1536px

.shadowAdmin {
  // height: 562px;
  height: calc(100% - 396px) !important;

}

.shadowSupplier {
  // height: 495px;
  height: calc(100% - 457px) !important;
}


// @media screen and (min-height: 600px) {
//   .shadowAdmin {
//     height: 201px;
//   }
//   .shadowSupplier {
//     height: 133px;
//   }
// }

// @media screen and (min-height: 620px) {
//   .shadowAdmin {
//     height: 222px;
//   }
//   .shadowSupplier {
//     height: 152px;
//   }
// }

// @media screen and (min-height: 640px) {
//   .shadowAdmin {
//     height: 284px;
//   }
//   .shadowSupplier {
//     height: 173px;
//   }
// }

// @media screen and (min-height: 768px) {
//   .shadowAdmin {
//     height: 370px;
//   }
//   .shadowSupplier {
//     height: 303px;
//   }
// }
// @media screen and (min-height: 720px) {
//   .shadowAdmin {
//     height: 321px;
//   }
//   // .shadowSupplier {
//   //   height: 252px;
//   // }
// }
// @media screen and (min-height: 820px) {
//   .shadowAdmin {
//     height: 423px;
//   }
//   .shadowSupplier {
//     height: 352px;
//   }
// }
// // admin 498

// @media screen and (min-height: 900px) {
//   .shadowAdmin {
//     height: 492px;
//   }
//   .shadowSupplier {
//     height: 345px;
//   }
// }
// // supplier 

// @media screen and (min-height: 963px) {
//   .shadowAdmin {
//     height: 564px;
//   }
//   .shadowSupplier {
//     height: 497px;
//   }
// }

// @media screen and (min-height: 1024px) {
//   .shadowAdmin {
//     height: 625px;
//   }
//   .shadowSupplier {
//     height: 558px;
//   }
// }

@media screen and (min-width: 1900px) {
  .shadowAdmin {
    height: -webkit-fill-available !important;
    height: calc(100% - 434px) !important;

  }

  .shadowSupplier {
    height: -webkit-fill-available !important;
    height: calc(100% - 495px) !important;
  }
}


@media screen and (min-height: 1200px) {
  .shadowAdmin {
    height: 780px !important;
  }

  .shadowSupplier {
    height: 784px !important;
  }
}

@media screen and (min-height: 1260px) {

  .shadowSupplier {
    height: 784px !important;
  }
}