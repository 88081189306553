@import '../../../index.scss';

.icon {
    cursor: pointer;
}
.body{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0px 30px 30px 30px;
    .form {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .filterActions {
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        column-gap: 30px;
        .reset {
            color: $primaryPink;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistRegular;
            cursor: pointer;
        }
    }
}
// .status {
//     padding-top: 28px;
//     .statusCheckbox {
//         display: flex;
//         gap: 10px;
//         align-items: center;
//         .statusLabel {
//             color: $tertiaryGray1;
//             font-size: 14px;
//             font-style: normal;
//             line-height: normal;
//             letter-spacing: 0.5px;
//             font-family: $UrbanistSemiBold;
//         }
//     }
//     .statusInputs {
//         padding-top: 20px;
//       .column {
//         display: flex;
//         gap: 20px;
//         flex-wrap: wrap;
//         .statusOption {
//             display: flex;
//             gap: 10px;
//             align-items: center;
//             min-width: 40%;
//         }
//       }
//     }
// }
.buyer {
    margin-top: 30px;
}