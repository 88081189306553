@import '../../../../../../index.scss';
.card{
  background-color: $primaryWhite;
  border: 1px solid $primaryWhite;
  min-width: 151px;
  max-width: 151px;
  width: 100%;
  padding: 12px 15px 23px;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.10));
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  .icon{
    width: 70px;
    height: 70px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .line{
    width: 93%;
    height: 1px;
    margin: 5px 0px 21px;
    background-color: $tertiaryGray3;
  }
  .title{
    color: $primaryBlack;
    font-family: $UrbanistRegular;
    letter-spacing: 0.5px;
    line-height: 17px;
    max-width: 121px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.selected{
  border: 1px solid $primaryPink;
}