@import "../../../index.scss";

body{
  overflow: hidden;
}
.container {
  width: 460px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 17px;
    margin-left: 30px;
    margin-right: 30px;
    padding-bottom: 16px;
    border-bottom: 1px solid $tertiaryGray2;

    .heading {
      color: $tertiaryBlack;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistSemiBold;
    }

    .backArrow {
      cursor: pointer;
      width: 14px;
      img{
        width: 100%;
        height: 100%;
      }
    }

    .backArrowConatiner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 6px;
    }

    .cancel {
      cursor: pointer;
    }
  }

  .content {
    overflow-y: auto;
    flex: 1;
    display: flex;
  }

}

@include minWidth(1500) {
  .container {
    width: 510px !important;
  }
}

@include minWidth(1700) {
  .container {
    width: 580px !important;
  }
}