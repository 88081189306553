@import "../../../index.scss";

.specialPricing {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;

  .right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .search {
      padding-top: 18px;
    }

    .heading {
      padding-top: 24px;
      padding-bottom: 16px;
      padding-left: 40px;
      padding-right: 40px;
      color: $tertiaryGray1;
      font-size: 20px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistSemiBold;
    }

    .orders {
      padding-left: 40px;
      padding-right: 40px;
      display: flex;
      flex: 1;
    }

  }
}

.popper {
  z-index: 10;

  .popperPaper {
    padding: 14px 10px;
    margin: 10px;

    .condition {
      width: max-content;
      text-wrap: wrap;

      .font {
        color: $tertiaryGray10;
        font-family: $UrbanistRegular;
        font-size: 14px;
        letter-spacing: 0.5px;
      }
    }

  }
}