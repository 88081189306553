@import '../../../../../../index.scss';
.filterTypes{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  background-color: $primaryWhite;
  flex-wrap: nowrap;
  padding: 20px 0px;
  position: relative;
  .body{
    display: flex;
    flex-direction: column;
    .filterSearch{
      display: flex;
      flex-wrap: nowrap;
      padding: 6px 4px;
      border: 1px solid $tertiaryGray2;
      border-radius: 4px;
      background-color: $primaryWhite;
      height: 31px;
      align-items: center;
      margin-bottom: 16px;
      .icon{
        width: 24px;
        height: 24px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .filterOptions{
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      flex: 1;
      .filterItem{
        display: flex;
        column-gap: 10px;
        align-items: center;
        flex-wrap: nowrap;
        .filterLabel{
          color: $primaryBlack;
          font-family: $UrbanistRegular;
          font-size: 14px;
          letter-spacing: 0.5px;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    .remainingCount{
      display: flex;
      column-gap: 10px;
      align-items: center;
      margin-top: 20px;
      .count{
        color: $primaryPink;
        font-size: 14px;
        font-family: $UrbanistRegular;
        cursor: pointer;
      }
      .line{
        width: 100%;
        flex: 1;
        height: 1px;
        background-color: $tertiaryGray3;
        align-self: center;
        margin-top: 3px;
      }
    }
    .totalOptions{
      position: absolute;
      left: -85px;
      top: 30px;
      
    }
  }
}