@import "../../../index.scss";

.reportContainer {
  width: 100%;
  height: 100%;
  padding-left: 40px;
  overflow: auto;

  .heading {
    color: $tertiaryBlack3;
    font-size: 20px;
    font-style: normal;
    font-family: $UrbanistSemiBold;
    line-height: normal;
    letter-spacing: 0.5px;
    margin-top: 20px;
  }

  .tableFilterContainer {
    background-color: $primaryWhite;
    margin-right: 40px;
    margin-top: 20px;
    padding: 20px;
    border-radius: 4px;

    .filterText {
      color: $tertiaryBlack3;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.07px;
    }

    .filteSection {
      position: relative;
      margin-top: 20px;
      border: 1px solid $tertiaryBlack5;
      width: 100%;
      height: 40px;
      border-radius: 25px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .divider {
        width: 0px;
        height: 20px;
        border-right: 1px solid $tertiaryGray7;
        margin-left: 10px;
        margin-right: 10px;
      }

      .date {
        flex-grow: 1;
      }

      .buttonContainer {
        border-radius: 25px;
        overflow: hidden;
        width: 143px;
        margin-right: 1px;

        .generateButton {
          background-color: $primaryPink;
          border: none;
          border-radius: 25px;
          color: $primaryWhite;
          width: 100%;
          height: 36px;
          cursor: pointer;
        }
      }
    }

    .reportInfoContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;

      .reportInfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 40px;

        .totalOrders {
          display: flex;
          flex-direction: row;
          column-gap: 20px;
        }

        .totalAmount {
          display: flex;
          flex-direction: row;
          column-gap: 10px;
        }
      }

      .downloaLink {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
        cursor: pointer;

        .downloadText {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
        }

        .downloadIcon {
          margin: 0px 5px;
          cursor: pointer;
        }
      }

      .typography1 {
        color: $tertiaryGray1;
        font-size: 36px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0.5px;
      }

      .typography2 {
        color: $tertiaryGray1;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.07px;
      }
    }
  }

  .tableContainer {
    margin-top: 40px;
    background-color: $primaryWhite;
    margin-right: 40px;
    height: 380px;
    border-radius: 4px;

    .paginationContainer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
}

.dropDownStyles {
  padding-left: 32px !important;
}

.errorMessage {
  position: absolute;
  color: $secondaryRed;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.07px;
  top: 40px;
}

.customDropdownClass{
  border-radius: 0px !important;
  border: none !important;
  box-shadow: none !important;
  margin-left: 10px !important;

}
