@import "../../../index.scss";

.parent {
  border-top-right-radius: 23px;
  border-top-left-radius: 23px;
  padding: 3px 4px 3px 4px;
  position: relative;
  &.focusedParent {
    background: $primaryWhite;
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
  }
  &.parent_radius {
    border-radius: 23px !important;
  }
  .container {
    position: relative;
    display: flex;
    border: 1px solid $tertiaryGray1;
    align-items: center;
    border-radius: 40px;
    overflow: hidden;
    background: $primaryGrayBackground;
    z-index: 100;

    .cancelSearch {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 45px;
      cursor: pointer;
    }

    &:has(input:focus) {
      background: $primaryWhite;
    }
    .seperator {
      width: 1px;
      height: 20px;
      background-color: $tertiaryGray2;
    }

    input {
      background: $primaryGrayBackground;
      width: 100%;
      padding: 10px 90px 10px 20px;
      outline: none;
      font-family: $UrbanistRegular;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      letter-spacing: 0.07px;
      &:focus {
        background: $primaryWhite;
      }
    }

    .globalSearch {
      position: absolute;
      right: 3px;
      top: 4px;
      cursor: pointer;
    }

    .cancelSearch {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 60px;
    }

    .dropdownContainer {
      display: flex;
      min-width: 145px;
      align-items: center;
      background: $primaryGrayBackground;
    }
  }

  .searchSuggestionsContainer {
    display: block;
    position: absolute;
    background-color: $primaryWhite;
    min-height: max-content;
    max-height: 303px;
    width: 100%;
    fill: $primaryWhite;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    left: 0;
    top: 45px;
    z-index: 99;
    overflow: auto;
    padding-top: 6px;
    padding-bottom: 4px;

    &::-webkit-scrollbar {
      width: 6px !important;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px $scrollBarColor;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollBarColor;
      border-radius: 10px;
    }

    .noDataFound {
      height: 290px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      .noDataFoundText {
        color: $tertiaryBlack;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        margin-top: 15px;
      }
    }

    .suggestedContainer {
      .suggestedBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 13px 30px;
        cursor: pointer;

        .iconContainer {
          display: flex;
        }

        .closeAction {
          display: none;
        }

        .leftContainer {
          display: flex;
          align-items: center;
          gap: 6px;

          .suggestedText {
            color: $tertiaryBlack;
            font-family: $UrbanistRegular;
            font-size: 14px;
            line-height: normal;
            letter-spacing: 0.5px;

            span {
              font-family: $UrbanistSemiBold;
            }
          }
        }

        &:hover {
          background: $tertiaryGray9;

          .closeAction {
            display: flex;
          }
        }
      }

      .seperator {
        width: 95.8%;
        height: 1px;
        background: $tertiaryGray3;
        left: 2%;
        position: relative;
      }

      &:last-child {
        .seperator {
          display: none;
        }
      }
    }

    &.searchSuggestionsChanged {
      top: 25px !important;
      padding-top: 20px !important;
      border-bottom-left-radius: 23px;
      border-bottom-right-radius: 23px;
    }
  }

  .errorContainer {
    position: relative;
    color: $secondaryRed;
    left: 15px;
    margin-top: 8px;
    margin-bottom: 10px;
  }
}

.parentModified {
  border-radius: 40px;
  padding: 5px 0px;
  position: relative;

  .parentFullWidth {
    margin: 0;
  }

  &.parent_radius {
    border-radius: 23px !important;
  }

  &:has(input:focus) {
    // &:hover {
    //   background: $primaryWhite;
    // }

    background: $primaryWhite;
  }

  .container {
    position: relative;
    display: flex;
    border: 1px solid $tertiaryGray1;
    align-items: center;
    border-radius: 40px;
    overflow: hidden;
    background: $primaryGrayBackground;
    padding-left: 11px;
    z-index: 100;

    &:has(input:focus) {
      background: $primaryWhite;
    }

    input {
      background: $primaryGrayBackground;
      width: 100%;
      padding: 8px 4px;
      outline: none;
      font-family: $UrbanistRegular;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      letter-spacing: 0.07px;
    }

    .globalSearch {
      position: absolute;
      right: 2px;
      top: 1px;
      cursor: pointer;
    }

    .dropdownContainer {
      display: flex;
      min-width: 145px;
      align-items: center;
      background: $primaryGrayBackground;
    }

    &:hover {
      box-shadow: 0px 0px 8px 0px $tertiaryBlue;
      // background: $primaryWhite;

      .dropdownContainer,
      input {
        background: $primaryWhite;
      }
    }
  }

  .searchSuggestionsContainer {
    display: block;
    position: absolute;
    z-index: 99;
    background-color: $primaryWhite;
    max-height: 303px;
    height: max-content;
    width: 100%;
    fill: $primaryWhite;
    border-radius: 8px;
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
    top: 25px;
    overflow: auto;
    padding-top: 11px;

    .noDataFound {
      height: 303px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      .noDataFoundText {
        color: $tertiaryBlack;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        margin-top: 15px;
      }
    }

    .suggestedContainer {
      .suggestedBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 30px;
        cursor: pointer;

        .iconContainer {
          display: flex;
        }

        .closeAction {
          display: none;
        }

        .leftContainer {
          display: flex;
          align-items: center;
          gap: 6px;

          .suggestedText {
            color: $teritaryBlack;
            font-family: $UrbanistRegular;
            font-size: 14px;
            line-height: normal;
            letter-spacing: 0.5px;

            span {
              font-family: $UrbanistSemiBold;
            }
          }
        }

        &:hover {
          background: $tertiaryGray9;

          .closeAction {
            display: flex;
          }
        }
      }

      .seperator {
        width: 95.8%;
        height: 1px;
        background: $tertiaryGray3;
        left: 2%;
        position: relative;
      }

      &:last-child {
        .seperator {
          display: none;
        }
      }
    }

    &.searchSuggestionsChanged {
      top: 25px !important;
      padding-top: 26px !important;
    }
  }
}

@include minWidth(1900) {
  .parentModified {
    .searchSuggestionsContainer {
      left: 270px;
    }
  }
}

.seeAll{
  color: $primaryPink;
  font-family: $UrbanistRegular;
  font-size: 16px;
  padding-top: 10px;
  align-items: center;

}
