@import "../../../../index.scss";

.resetPassword {
  padding: 32px 30px 46px;
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  flex: 1;
  overflow: auto;
  width: 100%;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 7px;

    .headerText {
      letter-spacing: 0.5px;
      text-transform: uppercase;
      font-family: $UrbanistSemiBold;
      color: $tertiaryGray6; // changed after rebase
      font-size: 24px;
      line-height: 29px;
    }

    .subheaderText {
      letter-spacing: 0.5px;
      color: $teritaryBlack;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__form {
    margin-top: 22px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    form {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .formField {
      position: relative;

      &.currentPassword{
        margin-top: 30px;
        margin-bottom: 30px;
      }
      &.confirmPassword {
        margin-top: 21px;
        margin-bottom: 24px;
      }

      .formFieldItem {
        display: flex;
        flex-direction: column;
        position: inherit;

        
        

        .label {
          font-size: 14px;
          line-height: 16px;
          color: $tertiaryGray1;
          display: flex;
          justify-content: space-between;
        }

        .forminput {
          background: $primaryWhite;
          border: 1px solid $tertiaryGray4;
          border-radius: 4px;
          height: 36px;
          min-height: 36px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-left: 15px;

          &:has(input:focus-visible) {
            z-index: 20;
          }

          &:has(input:focus-visible) {
            border: 1px solid $primaryPink !important;
            box-shadow: 0px 0px 24px rgba(236, 24, 123, 0.15) !important;
          }

          input {
            border: none;
            flex: 1;
          }

          .eye-icon {
            padding: 15px 21.24px 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }

    .resetPasswordButton {
      width: 100%;
    }
  }
  &.changePswd {
    padding-top: 19px;
    padding-bottom: 30px; 
  }
}

.passwordCheck {
  .mainHeading {
    color: $tertiaryBlack;
    font-family: $UrbanistSemiBold;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.5px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .conditionBox {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 6px;
    .default {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: $tertiaryGray3;
    }
    .condtionHeading {
      color: $teritaryBlack;
      font-family: $UrbanistRegular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0.5px;
    }
  }
}

@include maxWidth(1080) {
  .resetPassword {
    &__header {
      .header-text {
        font-size: 20px;
      }
    }
  }
}
.resetPwContainer{
  background-color: white;
  height: 100dvh;
}