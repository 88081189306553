@import "../../../../index.scss";

.inputCustom {
  border: none !important;
}

.inputFieldWrap {
  height: 40px;
  vertical-align: top;
  min-width: 400px;
  max-width: 400px;
  padding: 11px 10px;
  border-radius: 4px;
  border: 1px solid $tertiaryGray2;
  display: flex;
  flex-direction: row;
  align-items: start;
  flex: 1;
  position: relative;
  input {
    background: transparent;
  }

  &.errorStyle {
    border-color: $secondaryRed !important;
  }

  &:hover {
    box-shadow: 0px 0px 8px 0px rgba($tertiaryGray6, 0.25);
  }

  &.passwordField {
    padding: 11px 10px !important;
  }

  &:has(input:focus-visible),
  &:has(input:focus),
  &:has(.textarea:focus-visible) {
    border: 1px solid $primaryPink !important;
    box-shadow: 0px 0px 24px rgba($primaryPink, 0.15) !important;
  }

  .inputField {
    width: 100%;
    background-color: $primaryWhite;
    border: none !important;
    color: $tertiaryBlack;
    font-size: 14px;
  }

  .fullHeight {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .textarea {
    resize: none;
    height: 100%;
  }

  .icon {
    display: flex;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid $tertiaryGray7;
  }
  .resetFieldIcon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute !important;
    right: 5px;
    bottom: 7px;
    pointer-events: all;
  }

  .passwordIcon {
    width: 24px;
    height: 24px;
    display: flex;
    cursor: pointer;
    margin-top: -3px;

    &.hide {
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url(../../../../assets/images/formFieldIcons/eyeClose.svg);
      margin-top: -3px;

      &:hover {
        background-image: url(../../../../assets/images/formFieldIcons/eyeCloseHover.svg);
      }
    }

    &.show {
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url(../../../../assets/images/formFieldIcons/eyeOpen.svg);

      &:hover {
        background-image: url(../../../../assets/images/formFieldIcons/eyeOpenHover.svg);
      }
    }
  }
}

.disabledIban {
  background: $disabledFormFieldColor !important;
  border: 1px solid $disabledFormFieldBorderColor !important;
  pointer-events: none;

  input {
    background: transparent !important;
    color: $disabledFontColor !important;
  }
}

// need label black only always
// .disabledIbanLabel {
//   color: $tertiaryGray2 !important;
// }

.editFormStyles {
  background: $disabledFormFieldColor !important;
  border: 1px solid $disabledFormFieldBorderColor !important;
  pointer-events: none;

  input {
    background: transparent !important;
    color: $disabledFontColor !important;
    width: 100%;
  }
}

.editFormInputStyles {
  background: transparent !important;
  pointer-events: none;
}

@media screen and (max-width: 1080px) {
  .inputFieldWrap {
    min-width: auto;
  }
}
