@import "../../../../../common/styles//variables.scss";

.paymentSummaryWrapper {
  width: 30%;
  padding: 0 28px 28px 28px;
  background-color: $primaryWhite;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);

  .totalCartAmountWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    .amountText {
      font-size: 1.5rem;
    }
    .currency {
      font-size: 0.625rem;
    }
    .estimatedText {
      font-size: 0.875rem;
    }
  }
  .payBtn{
    width: 75%;
    margin-top: 30px;
  }
}
