@import "../../index.scss";
.app {
    display: flex;

    .bodyContainer {
        padding: 0 40px;
        height: 100vh;
        overflow: auto;
        width: 100%;
    }

    .commonComponent {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        h1 {
            margin: 20px 0;
        }

        .buttonComponent {
            display: flex;
            gap: 10px;
        }
    }
}

body {
    background: $primaryGrayBackground;
}

.breadcrumb {
    margin: 80px 0 20px 0;
}