@import "../../../index.scss";
.specialPricing {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    .right {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .search {
            padding-top: 18px;
        }
        .header{
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0px 40px;
            margin-top: 24px;
            margin-bottom: 16px;
            align-items: baseline;
            .leftPart {
               display: flex;
               align-items: center;
               gap: 10px;
                .heading {
                    color: $tertiaryGray1;
                    font-size: 20px;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    font-family: $UrbanistSemiBold;
                }
                .editcelltext {
                    display: flex;
                    justify-content: flex-end;
                    font-weight: 400;
                    align-items: center;
                    gap: 4px;
                  }
                  .textCenter{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 2px;
                    color: #383434;
                    font-family: $UrbanistRegular;
                    font-size: 14px;
                    line-height: 12px;
                    letter-spacing: 0.5px;
                    cursor: pointer;
                  }
            }
            .rightPart{
                width: 120px;
                height: 40px;
            }
        }
        .orders {
            padding-left: 40px;
            padding-right: 40px;
            display: flex;
            flex:1;
        }
    }
}
.popper {
    z-index: 10;
    .popperPaper {
      padding: 14px 10px;
      margin: 10px;
      .condition {
        width: max-content;
        text-wrap: wrap;
        .font {
          color: $tertiaryGray10;
          font-family: $UrbanistRegular;
          font-size: 14px;
          letter-spacing: 0.5px;
        }
      }
      
    }
  }