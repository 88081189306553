@font-face {
  font-family: "UrbanistLight";
  src: url("../../assets/fonts/Urbanist-Light.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "UrbanistRegular";
  src: url("../../assets/fonts/Urbanist-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "UrbanistSemiBold";
  src: url("../../assets/fonts/Urbanist-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "UrbanistItalic";
  src: url("../../assets/fonts/Urbanist-Italic.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "UrbanistBold";
  src: url("../../assets/fonts/Urbanist-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "UrbanistMedium";
  src: url("../../assets/fonts/Urbanist-Medium.ttf");
  font-style: normal;
  font-weight: 500;
}