@import "../../../index.scss";

.parentContainer {
  padding: 30px;
  width: 100%;
  height: 170px;
  flex-shrink: 0;
  background-color: $primaryWhite;
  border-radius: 6px;

  .detailsCardContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 109px;

    .detailsCard {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;

      .cardImage {
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .amount {
        color: $tertiaryGray1;
        font-family: $UrbanistRegular;
        font-size: 36px;
        font-style: normal;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 0.5px;

        span {
          color: $tertiaryGray1;
          font-family: $UrbanistRegular;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 14px;
          /* 100% */
          letter-spacing: 0.5px;

          &:first-child {
            margin-right: 6px;
          }
        }
      }

      .cardTitle {
        color: $tertiaryGray1;
        font-family: $UrbanistRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.07px;
        position: relative;
      }
      .overlay {
        display: none;
      }

      &:hover {
        cursor: pointer;
        .overlay {
          display: flex;
        }
        .amount {
          color: $primaryPink;

          span {
            color: $primaryPink;
          }
        }
        .cardTitle {
          color: $primaryPink;
        }
      }
      // &:nth-last-child(-n + 2) {
      //   cursor: auto;
      //   .overlay {
      //     display: flex;
      //   }
      //   .amount {
      //     color: $tertiaryGray1;

      //     span {
      //       color: $tertiaryGray1;
      //     }
      //   }
      //   .cardTitle {
      //     color: $tertiaryGray1;
      //   }
      // }
    }
  }
}
