@import "../../../../../index.scss";
.card {
    position: relative;
    tr{
    td {
        height: 50px;
        padding-top: 5px;
        min-width: 220px;
        width: 106px;
        color: $tertiaryGray10;
        font-family: $UrbanistRegular;
        font-size: 14px;
        letter-spacing: 0.5px;
        padding-right: 50px;
        padding-bottom: 12px;
        padding-left: 14px;
        box-sizing: content-box;
        text-align: left;
        border-bottom: 1px solid $tertiaryGray2;
        margin-right: 10px;
        .prodName {
            min-width: 340px;
        }
        &:last-child {
            // display: flex;
            // gap: 30px;
            // align-items: center;
            // padding-bottom: 24px;
        }
        // &:nth-child(4), &:nth-child(5), &:nth-child(8){
        //     min-width: 80px;
        // }
        .actions {
            display: flex;
            gap:30px;
        }
        .editableField {
            display: flex;
            align-items: center;
            padding-left: 0px;
            padding-bottom: 3px;
            padding-top: 3px;    
            input{
              color: $gray2;
              font-family: $UrbanistRegular;
              font-size: 14px;
              line-height: normal;
              letter-spacing: 0.5px;
              padding: 15px;
              width: 90px;
              max-width: fit-content;
              height: 36px;
              margin-bottom: 0 !important;
              border: 1px solid grey !important;
              border-radius: 5px !important;
            }
        }
        .errorMessage{  
            font-family: $UrbanistItalic;
        font-size: 12px;
        letter-spacing: 0.5px;
        color: $secondaryRed;
        margin-top: 5px;
        }
    }
    }
}
.width {
    min-width: 85px !important;
    .ellipse {
        max-width: 85px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}