@import "../../../../common//styles/variables.scss";

.preferencesContainer {
    padding: 30px 40px 50px;
    width: 100%;
    height: 100%;

    .header {
        color: $tertiaryGray1;
        font-family: $UrbanistSemiBold;
        font-size: 20px;
        line-height: normal;
        letter-spacing: 0.5px;
        margin-bottom: 20px;
    }

    .each {
        width: 100%;
        height: 100%;
        max-height: calc(100% - 45px);
        overflow: hidden;
    }

    .accHeader {
        color: $tertiaryGray1;
        font-family: $UrbanistBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.5px;
        margin-bottom: 10px;
    }

    .outletSection {
        background-color: $primaryWhite;
        width: 100%;
        height: 100%;
        padding: 30px;
        border-radius: 6px;
        border: 1px solid $primaryWhite;

        .label {
            color: $tertiaryGray10;
            font-family: $UrbanistRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            margin-bottom: 10px;
        }

        .outlets {
            width: 100%;
            overflow: auto;
            max-height: calc(100% - 50px);
            padding-bottom: 40px;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px $scrollBarColor;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background: $scrollBarColor;
                border-radius: 10px;
            }
        }

    }
}

.addOutletBtnWrapper {
    background-color: white;
    padding: 10px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: sticky;
    bottom: 0;
}