@import "../../../../../index.scss";

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .tabs {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 34px;

        .tabsStyle {
            display: flex;
            flex-direction: column;
            flex: 1;

            .tabList {
                display: flex;
                gap: 40px;
                flex: 1;
                padding-bottom: 6px;

                .tabListItem {
                    cursor: pointer;

                    .label {
                        color: $tertiaryGray1;
                        font-family: $UrbanistRegular;
                        font-size: 14px;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.5px;
                    }

                    .activeLabel {
                        color: $primaryPink;
                        font-size: 14px;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        font-family: $UrbanistSemiBold;
                    }

                    .border {
                        border-top: 3px solid $primaryPink;
                        border-radius: 4px;
                        position: relative;
                        bottom: -4px;
                        z-index: 1;
                    }
                }
            }

            .hr {
                border-bottom: 1px solid $tertiaryGray3;
                position: relative;
                top: -4px;
            }
        }

        .actions {
            display: flex;
            align-items: center;
            margin-left: 20px;
            gap: 30px;

            .icon {
                height: 24px;
                cursor: pointer;
            }

            .search {
                .searchContainer {
                    display: flex;
                    justify-content: space-between;
                    width: 516px;
                    background-color: $primaryWhite;
                    border: 1px solid $primaryWhite;
                    border-radius: 40px;
                    align-items: center;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    padding-right: 6px;
                    padding-left: 10px;

                    .searchIconInput {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        .searchInput {
                            height: 24px;
                            padding-left: 10px;
                            display: flex;
                            flex: 1;
                        }
                    }

                    .cancel {
                        cursor: pointer;
                        height: 24px;
                    }

                    &:hover {
                        border-color: $primaryPink;
                    }

                }

                .searchIcon {
                    height: 24px;
                    cursor: pointer;
                }
            }

            .sort {
                position: relative;

                .sortList {
                    background-color: $primaryWhite;
                    border-radius: 6px;
                    padding-top: 20px;
                    position: absolute;
                    top: 39px;
                    z-index: 1;
                    right: -26px;
                    width: 251px;
                    display: flex;
                    flex-direction: column;
                    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);

                    .sortBy {
                        color: $teritaryBlack;
                        font-size: 14px;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        font-family: $UrbanistSemiBold;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-bottom: 10px;
                    }

                    .sortOption {
                        color: $teritaryBlack;
                        font-size: 14px;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        font-family: $UrbanistRegular;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 10px;
                        padding-bottom: 10px;

                        &:hover {
                            background-color: $tertiaryGray9;
                        }

                        &:active {
                            color: $primaryPink;
                        }
                    }

                    .lastOption {
                        margin-bottom: 10px;
                    }

                    .selectedOpt {
                        color: $primaryPink;
                    }

                    &::after {
                        content: " ";
                        position: absolute;
                        right: 25px;
                        border-width: 16px;
                        top: -31px;
                        border-style: solid;
                        border-color: transparent transparent $primaryWhite transparent;
                    }
                }

            }
        }

        .actions2 {
            position: relative;
            top: 8px;
            height: 32px;
        }
    }

    .tableHeader {
        background-color: $backgroundThemeColor;
        height: unset;
        align-items: unset;
        padding-left: 0px;
        margin: 15px 0px;
        cursor: unset;

        .businessName {
            min-width: 200px;
            max-width: 200px;
        }
        .contactName,.email{
            padding-left: 5px;
        }
        .address{
            margin-right: 10px;
        }

        .headerLogo {
            height: unset;
            width: unset;
        }

        .headerColor {
            color: $tertiaryGray2;
        }

        &:hover {
            border: 1px solid transparent;
            filter: none;
        }
    }

    .tabsContent {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        flex: 1;
        height: calc(100vh - 250px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 5px;
        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $scrollBarColor;
        border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
        background: $scrollBarColor;
        border-radius: 10px;
        }
    }
    .emptyContainer {
        margin-top: 20px;
        margin-bottom: 10px;
        display: flex;
        flex: 1;
    }
}

.paginationContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

input[type=search]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}