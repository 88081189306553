@import "../../../../index.scss";


.signin {
    padding: 40px 30px 30px;
    overflow: auto;
    width: 100%;

    &__header {
        font-size: 24px;
        line-height: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $tertiaryGray6;
        margin-bottom: 28px;
        font-family: $UrbanistSemiBold;
        letter-spacing: 0.5px;
        margin-top: -6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__rightContainer {
        color: $tertiaryGray1;
        text-align: center;
        font-family: $UrbanistSemiBold;
        font-size: 12px;
        line-height: normal;
        display: flex;
        align-items: center;
        margin-top: 3px;

        .heading {
            text-transform: capitalize;
            font-family: $UrbanistSemiBold;
            letter-spacing: 0.2px;
        }
    }

    &__form {

        .formField {
            margin-bottom: 21px;

            &.password {
                margin-bottom: 10px;
            }

            .formFieldItem {
                display: flex;
                flex-direction: column;


                .label {
                    font-size: 14px;
                    line-height: 16px;
                    color: $tertiaryGray1;
                    display: flex;
                    justify-content: space-between;
                    letter-spacing: 0.5px;

                    .suggestion {
                        display: flex;
                        gap: 8px;
                        letter-spacing: 0.5px;
                        color: $tertiaryGray1;
                        font-size: 12px;
                        line-height: 14px;
                        font-family: $UrbanistItalic;
                        align-items: center;

                        .icon {
                            display: flex;
                        }
                    }
                }

                .forminput {
                    background: $primaryWhite;
                    border: 1px solid $tertiaryGray4;
                    border-radius: 4px;
                    height: 36px;
                    min-height: 36px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding-left: 15px;

                    input {
                        border: none;
                        flex: 1;
                    }

                    .eye-icon {
                        padding: 15px 21.24px 13px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }
                }
            }
        }

        .signin-button {
            width: 100%;
            font-family: $UrbanistMedium;
            font-size: 15px;
            line-height: 16px;
        }

        .alternateText {
            color: $headingColorOD;
            font-size: 14px;
            font-style: normal;
            line-height: 14px;
            width: 100%;
            text-align: center;
            opacity: 0.5;
            margin-left: 7px;
        }

        .alternateSignIn {
            display: flex;
            flex-direction: row;
            gap: 40px;
            height: 36px;
            margin-top: 20px;

        }



        .rememberSection {
            margin-bottom: 58px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .checkbox label {
                font-size: 16px;
                line-height: 14px;
                color: $tertiaryGray1;
                margin-left: 8px;
            }

            .checkbox {
                display: flex;
                align-items: center;
                gap: 8px;
                cursor: pointer;

            }

            ::ng-deep .p-checkbox {
                width: 20px !important;
                height: 20px !important;
                display: flex;

                .p-checkbox-box {
                    width: 20px !important;
                    height: 20px !important;
                    border: 1px solid $tertiaryGray4;
                }

                .p-checkbox-box.p-highlight {
                    background: $primaryPink  !important;
                    border-radius: 2px !important;
                    border: none !important;
                }

                .p-checkbox-box.p-focus {
                    box-shadow: none !important;
                }
            }
        }
    }

    &__signup {
        text-align: center;
        margin-top: 54px;
        font-size: 14px;
        line-height: 16px;
        color: $tertiaryGray1;
        font-family: $UrbanistRegular;

        .link_primary {
            margin-left: 7px;
            font-family: $UrbanistMedium;
            font-size: 14px;
            line-height: 19px;
            color: $primaryPink;
            cursor: pointer;
        }
    }
}

@include maxWidth(1080) {
    .signin {
        &__header {
            font-size: 20px;
            line-height: 14px;
        }
    }
}

.forgotPassword {
    font-family: $UrbanistMedium;
    font-size: 14px;
    line-height: 19px;
    color: $primaryPink;
    font-family: $UrbanistRegular;
    cursor: pointer;
    letter-spacing: 0.5px;
}