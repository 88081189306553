@import "../../../index.scss";

.section {
  display: flex;
  justify-content: flex-start;
  align-items: self-start;
  margin-top: 6px;
  gap: 20px
}

.error {
  color: $secondaryRed;
  font-family: $UrbanistRegular;
  font-size: 12px;
  font-style: italic;
  line-height: normal;
  letter-spacing: 0.5px;
  margin-top: 10px;
}

.inputAttributeValueInput {
  height: 40px;
  vertical-align: top;
  min-width: 400px;
  max-width: 400px;
  padding: 11px 10px;
  border-radius: 4px;
  border: 1px solid #949494 !important;
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-top: 6px;
  // border-bottom-left-radius: 0;
  // border-top-left-radius: 0;
  // border-left-width: 0 !important;
}

.attributesLabel {
  color: $headingColorOD;
  font-family: $UrbanistSemiBold;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.attributButton {
  // width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  height: 31px;
  background: $primaryPink;
  border: 1px solid $primaryPink;
  color: $primaryWhite;
  margin-top: 10px;
  padding: 0 10px;
  font-size: 14px;
  font-family: $UrbanistRegular;
  border-radius: 6px;
  text-align: center;
  letter-spacing: 0.5px;
  cursor: pointer;

  .btnIcon {
    font-size: 20px;
  }

  &:disabled {
    background-color: grey;
    border: none;
    cursor: default;
  }

  &:hover:enabled {
    color: $primaryPink;
    background: $primaryWhite;
  }

  img {
    width: 90%;
  }
}

.attrDeleteBtn {
  background: none;
  border: none;
  margin-left: -15px;
}

.Dropddown_selectWithBorder {
  border-right-width: 0 !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}