@import "../../../index.scss";
.right{
  background-color: $backgroundThemeColor;
  overflow-y: auto ;
  overflow-x: hidden;
  height: 100%;
  padding: 35px 34px 40px 40px;
  &::-webkit-scrollbar {
    width: 6px ;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $scrollBarColor;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollBarColor;
    border-radius: 10px;
  }
  .greetings{
    color: $tertiaryGray1;
    font-family: $UrbanistRegular;
    font-size: 24px;
    line-height: 27px;
    letter-spacing: 0.5px;
  }
  .onboardingCards{
    display: flex;
    flex-wrap: wrap;
    gap: 29px;
    margin-top: 15px;
  }
  .howToSection{
    margin-top: 47px;
    .cardHeader {
      display: flex;
      justify-content: space-between;
      .heading {
        color: $tertiaryGray1;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistSemiBold;
        margin-bottom: 11px;
      }

      .viewAll {
        text-decoration: none;
        height: auto;
      }
    }
    .howToSectionCards{
      display: flex;
      flex-wrap: nowrap;
      gap: 30px;
      background-color: $primaryWhite;
      padding: 30px;
      margin-top: 11px;
      overflow-x: auto;
      border-radius: 6px;
      &::-webkit-scrollbar {
        width: 6px ;
      }
    
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $scrollBarColor;
        border-radius: 10px;
      }
    
      &::-webkit-scrollbar-thumb {
        background: $scrollBarColor;
        border-radius: 10px;
      }
    }
  }
  .blogsAndArticlesSection{
    margin-top: 47px;
    .cardHeader {
      display: flex;
      justify-content: space-between;
      .heading {
        color: $tertiaryGray1;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistSemiBold;
        margin-bottom: 11px;
      }

      .viewAll {
        text-decoration: none;
        height: auto;
      }
    }
    .blogsAndArticlesCards{
      display: flex;
      gap: 30px;
      background-color: $primaryWhite;
      border-radius: 6px;
      overflow: hidden;
      padding: 30px;
      flex-wrap: nowrap;
      overflow-x: auto;
      &::-webkit-scrollbar {
        width: 6px ;
      }
    
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $scrollBarColor;
        border-radius: 10px;
      }
    
      &::-webkit-scrollbar-thumb {
        background: $scrollBarColor;
        border-radius: 10px;
      }
    }
  }
}
