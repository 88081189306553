@import "../../../index.scss";
.toast {
  display: flex;
  justify-content: space-between;
  width: 461px;
  height: 58px;
  border-radius: 6px;
  background: $primaryWhite;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
  position: fixed;
  z-index: 9999;
  overflow: hidden;
  .toastLeft {
    display: flex;
    .icon {
      margin-right: 12px;
      display: flex;
      align-items: center;
    }
    .content {
      color: $tertiaryGray1;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistRegular;
      padding-top: 21px;
      padding-bottom: 20px;
      text-align: start;
      overflow: hidden;
    }
    .bigContent {
      padding-top: 18px;
      padding-bottom: 18px;
      line-height: 20px;
    }
    .link {
      all: unset;
      cursor: pointer;
      color: $primaryPink;
      font-family: $UrbanistRegular;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      padding-left: 9px;
    }
  }
  .cancelButton {
    all: unset;
    cursor: pointer;
    margin-right: 16px;
    margin-left: 20px;
  }
  .bigCancelButton {
    margin-right: 14px;
  }
  &.topRight {
    top: 0;
    right: 0;
  }
  &.topLeft {
    top: 0;
    left: 0;
  }
  &.bottomLeft {
    bottom: 0;
    left: 0;
  }
  &.bottomRight {
    bottom: 0;
    right: 0;
  }
  &.bigToast {
    height: 76px;
  }
}
