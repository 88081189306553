@import "../../../../index.scss";

.signup {
  padding: 32px 30px 30px;
  overflow: auto;
  width: 100%;

  &__header {
    font-size: 24px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $tertiaryGray6;
    margin-bottom: 30px;
    margin-top: 11px;
    font-family: $UrbanistSemiBold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__heading {
    font-family: $UrbanistSemiBold;
    letter-spacing: 0.5px;
  }

  &__rightContainer {
    color: $tertiaryGray1;
    text-align: center;
    font-family: $UrbanistSemiBold;
    font-size: 12px;
    line-height: normal;
    display: flex;
    align-items: center;
    margin-top: 3px;

    .heading {
      text-transform: capitalize;
      font-family: $UrbanistSemiBold;
      letter-spacing: 0.2px;
    }
  }

  &__form {
    .formField {
      margin-bottom: 24px;
      position: relative;

      &.confirmPassword {
        margin-bottom: 13px !important;
      }

      &_inputLabelStyles {
        margin-bottom: 2px !important;
      }

      .formFieldItem {
        display: flex;
        flex-direction: column;
        position: inherit;

        .label {
          font-size: 14px;
          line-height: 16px;
          color: $tertiaryGray1;
          display: flex;
          justify-content: space-between;
          letter-spacing: 0.5px;

          .suggestion {
            display: flex;
            gap: 8px;
            letter-spacing: 0.5px;
            color: $tertiaryGray1;
            font-size: 12px;
            line-height: 14px;
            font-family: $UrbanistItalic;
            align-items: center;

            .icon {
              display: flex;
            }
          }
        }

        .forminput {
          background: $primaryWhite;
          border: 1px solid $tertiaryGray4;
          border-radius: 4px;
          height: 36px;
          min-height: 36px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-left: 15px;

          &:has(input:focus-visible) {
            z-index: 20;
          }

          input {
            border: none;
            flex: 1;
          }

          .eyeIcon {
            padding: 15px 21.24px 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }

    .signup-button {
      width: 100%;
      margin-top: 5px;
    }

    .signupMargin {
      margin-bottom: 20px;
    }

    .alternateText {
      color: $headingColorOD;
      font-size: 14px;
      font-style: normal;
      line-height: 14px;
      width: 100%;
      text-align: center;
      opacity: 0.5;
      padding-left: 14px;
    }

    .alternateSignIn {
      display: flex;
      flex-direction: row;
      gap: 30px;
      height: 36px;
      margin-top: 20px;
    }
  }

  &__signin {
    text-align: center;
    margin-top: 56px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.5px;
    font-family: $UrbanistRegular;
    color: $tertiaryGray1;

    .link_primary {
      margin-left: 7px;
      font-family: $UrbanistMedium;
      font-size: 14px;
      line-height: 19px;
      color: $primaryPink;
      cursor: pointer;
    }
  }
}

.inputFieldMargin {
  margin-bottom: 3px !important;
}

.tncContainer {
  position: relative;

  .tnc {
    display: flex;
    align-items: center;
    gap: 8px;

    .tncHeading {
      color: $tertiaryGray1;
      font-family: $UrbanistRegular;
      font-size: 16px;
      line-height: 14px;
      cursor: pointer;

      a {
        margin-left: 5px;
        text-decoration: none;
        color: $primaryPink;
        cursor: pointer;
      }
    }
  }

  .errorMessage {
    margin-top: -20px;
    margin-bottom: 30px;
  }
}

@include maxWidth(1080) {
  .signup {
    &__header {
      font-size: 20px;
      line-height: 14px;
    }
  }
}

.inputField {
  margin-bottom: 24px;
}
