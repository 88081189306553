@import '../../../index.scss';

.categoryCardContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    min-height: 250px;
    height: 267px;
    transition: all 0.4s ease-in-out;
    border-radius: 6px;
    background: $primaryWhite;
    box-shadow: 0px 0px 20px 0px rgba($primaryBlack, 0.1);
    border: 1px solid transparent;
    cursor: pointer;
    overflow: hidden;

    &:hover {
        .cardContainer {
            opacity: 0;
            visibility: hidden;
        }

        .hoverContainer {
            left: 0;
        }
    }

    .cardContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        transition: all 0.4s ease-in-out;

        .imgContainer {
            width: 68.4000015258789px;
            height: 68.4000015258789px;
            top: 10.7998046875px;
            left: 10.80078125px;
            border: 1px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .title {
            color: $tertiaryBlack;
            text-align: left;
            font-family: $UrbanistRegular;
            font-size: 14px;
            line-height: normal;
            letter-spacing: 0.1px;
            margin-top: 31px;
        }
    }

    .hoverContainer {
        transition: all 0.4s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 30px;
        position: absolute;
        left: -100%;

        .hoverTitle {
            color: $tertiaryBlack;
            text-align: center;
            font-family: $UrbanistBold;
            font-size: 20px;
            line-height: normal;
            letter-spacing: 0.5px;
        }
    }
}

.searchCard {
    .cardContainer {
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 0;
        position: relative;

        .imgContainer {
            margin-bottom: 24px;
            margin-top: 10px;

            &::after {
                content: "";
                content: "";
                position: absolute;
                bottom: 34px;
                width: 13%;
                height: 1px;
                left: 43%;
                background: $tertiaryGray2;

            }
        }
        .modify {
            &::after {
              height: 0px;
            }
        }
        .title {
            margin-top: 31px;
        }


    }

    &:hover {
        .cardContainer {
            opacity: 1;
            visibility: visible;
        }

        .hoverContainer {
            left: -100%;
        }
    }
}

.head {
    color: $teritaryBlack;
    font-family: $UrbanistRegular;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    margin-top: 9px;
}

@include minWidth(1500){

    .categoryCardContainer{
      height:290px !important;
      transition: none !important;
    }
      
    }

@include minWidth(1700){

  .categoryCardContainer{
    height:340px !important;
    transition: none !important;
  }
    
  }