@import '../../../../index.scss';
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 40px 48px;
  position: relative;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 21px;
    align-items: center;
    .searchText{
      color: $tertiaryGray1;
      font-family:$UrbanistRegular;
      font-size: 14px;
      letter-spacing: 0.5px;
      span{
        font-family: $UrbanistSemiBold;
      }
    }
    .sort {
      position: relative;
      cursor: pointer;

      .selectedSort {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        .content {
          display: flex;
          column-gap: 4px;
          flex-wrap: nowrap;
          padding-bottom: 6px;

          .sortByText {
            color: $tertiaryGray2;
            font-size: 14px;
            font-family: $UrbanistRegular;
            letter-spacing: 0.5px;
          }

          .selected {
            color: $tertiaryGrays2;
            font-size: 14px;
            font-family: $UrbanistRegular;
            letter-spacing: 0.5px;
          }
        }

        .icon {
          width: 24px;
          height: 24px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .sortList {
        background-color: $primaryWhite;
        border-radius: 6px;
        padding-top: 20px;
        position: absolute;
        top: 33px;
        z-index: 1;
        right: -30px;
        width: 320px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);

        .sortBy {
          color: $teritaryBlack;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          font-family: $UrbanistSemiBold;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 10px;
        }

        .sortOption {
          color: $teritaryBlack;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          font-family: $UrbanistRegular;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
          cursor: pointer;

          &:hover {
            background-color: $tertiaryGray9;
          }

          &:active {
            color: $primaryPink;
          }
        }

        .lastOption {
          margin-bottom: 10px;
        }

        .selectedOpt {
          color: $primaryPink;
        }

        &::after {
          content: "";
          position: absolute;
          right: 25px;
          border-width: 16px;
          top: -31px;
          border-style: solid;
          border-color: transparent transparent $primaryWhite transparent;
        }
      }
    }
  }
  .contentWrapper{
    display: flex;
    column-gap: 40px;
    flex: 1;
    max-height: calc(100% - 50px);
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px $scrollBarColor;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollBarColor;
      border-radius: 10px;
    }
    .filters {
      min-width: 219px;
      max-width: 219px;
    }
    .productsContainer{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .products{
        display: flex;
        column-gap: 30px;
        row-gap: 23px;
        flex-wrap: wrap;
        flex: 1;
      }
      .paginationContainer{
        position: absolute;
        bottom: 10px;
        right: 40px;
      }
    }
    .emptyContainer{
      width: 100%;
      height: 100%;
      flex: 1;
    }
    .customStyles{
      margin-top: unset;
    }
  }
}
