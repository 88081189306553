@import "../../../index.scss";

.container {
    display: flex;
    gap: 40px;
    width: 100%;
    height: 100%;
    .rightContainer {
        width: 100%;
        padding-left: 40px;
        display: flex;
        flex-direction: column;
        .supplierContainer {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            flex: 1;
            .heading {
                display: flex;
                justify-content: space-between;
                padding-right: 40px;
                align-items: center;
                min-height: 36px;
                .addForms {
                    display: flex;
                    gap: 20px;
                }
            }
        }
    }
}

.ratingModal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .modalFullContainer {
        background: $primaryWhite;
        border: 2px solid $primaryLightGreen;
        padding: 18px 30px 30px;
        filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
        max-width: 460px;
        min-width: 460px;
        max-height: 544px;
        min-height: 544px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;

        .modalBody {
            flex: 1;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;

            .heading {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .leftContainer {
                    color: $teritaryBlack;
                    font-size: 14px;
                    letter-spacing: 0.5px;
                    line-height: normal;
                    font-family: $UrbanistSemiBold;
                }

                .rightContainer {
                    cursor: pointer;
                }
            }

            .seperator {
                height: 1px;
                width: 100%;
                background-color: $tertiaryGray2;
                margin-top: 20px;
            }

            .body {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                flex: 1;

                .ratingContainer {
                    display: flex;
                    justify-content: space-between;

                    .ratingBox {
                        display: flex;
                        flex-direction: column;

                        .upContainer {
                            display: flex;
                            gap: 10px;
                            font-family: $UrbanistRegular;
                            font-size: 12px;

                            .ratingValue {
                                span {
                                    color: $tertiaryGray1;
                                    font-family: $UrbanistRegular;
                                    font-size: 14px;
                                    line-height: normal;
                                    letter-spacing: 0.5px;

                                    &:first-child {
                                        font-size: 24px;
                                    }
                                }
                            }

                            .ratingStarContainer {
                                display: flex;
                                align-items: center;
                                padding-top: 3px;
                            }
                        }

                        .ratingBlock {
                            color: $tertiaryGray1;
                            font-family: $UrbanistRegular;
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: 0.07px;
                        }
                    }
                }

                .questionContainer {
                    font-family: $UrbanistSemiBold;
                    flex: 1;
                    margin-top: 20px;

                    .heading {
                        color: $tertiaryGray1;
                        font-family: $UrbanistSemiBold;
                        font-size: 14px;
                        line-height: normal;
                        letter-spacing: 0.5px;
                    }

                    .options {
                        display: flex;
                        flex-direction: column;
                        margin-top: 20px;
                        max-height: 322px;
                        overflow-y: auto;

                        .option {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            margin-bottom: 12px;

                            .label {
                                color: $tertiaryGray1;
                                font-family: $UrbanistRegular;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                letter-spacing: 0.5px;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    &::-webkit-scrollbar {
                        width: 6px;
                    }

                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 5px $scrollBarColor;
                        border-radius: 10px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: $scrollBarColor;
                        border-radius: 10px;
                        ;
                    }
                }

                .textAreaContainer {
                    display: flex;
                    height: 80px;
                    align-items: flex-start;
                    gap: 10px;
                    flex-shrink: 0;
                    align-self: stretch;
                    border-radius: 4px;
                    border: 1px solid $tertiaryGray2;
                    background: $primaryWhite;
                    overflow: hidden;
                    margin-top: 30px;

                    textArea {
                        width: 100%;
                        height: 100%;
                        padding: 11px 10px;
                        resize: none;

                    }
                }
            }
        }

        .footer {
            display: flex;
            justify-content: flex-end;
            column-gap: 30px;

            .button {
                height: 40px;
                margin-top: -15px;
            }

        }
    }
}