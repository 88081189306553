@import "../../common/styles/variables.scss";

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
  .backNav {
    display: flex;
    gap: 4px;
    margin-top: 37px;
    margin-left: 40px;
    width: max-content;
    cursor: pointer;
    .backIcon {
      height: 24px;
      width: 24px;
    }
    .backTxt {
      color: $tertiaryGray1;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.5px;
    }
  }
  .container {
    background-color: $primaryWhite;
    border-radius: 6px;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 17px 40px 50px;
    padding: 20px;
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px ;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px $scrollBarColor;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollBarColor;
      border-radius: 10px;
    }
    .productDetailsContainer{
      display: flex;
    }

    .imagePreview {
      margin-right: 40px;
      display: flex;
      // flex: 1;
    }
    .productDescription {
      // width: 50%;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      flex: 1;
      .shareIcon{
        width: 24px;
        height: 24px;
        align-self: flex-end;
        cursor: pointer;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .productName {
        color: $teritaryBlack;
        font-size: 24px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
      }
      .additionalProductDetails {
        margin-top: 43.5px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .accordion {
          padding: 0px;
        }
      }
    }
    .productTitle {
      color: $tertiaryBlack;
      font-size: 24px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
    }
    .totalCost{
      margin: 34px 0px -34px;
      .margin{
        margin-top: 14px
      }
      .totalText{
        color: $tertiaryGray2;
        font-family: $UrbanistRegular;
        font-size: 36px;
        letter-spacing: 0.5px;
        margin-right: 10px;
      }
    }
    .productPricing {
      display: flex;
      margin-top: 24px;
      gap: 69px;
      align-items: end;
      padding-bottom: 16px;
      border-bottom: 1px solid $tertiaryGray3;
      .productPrice {
        display: flex;
        min-width: 204px;
        flex-direction: column;
        .productPriceBottom {
          color: $tertiaryGray1;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
        }
      }
      .productDelivery{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        .truck {
          height: 38px;
        }
        .orderMessage{
          font-size: 14px;
        }
      }
    }
    .productPriceTop {
      .productPriceDenomination {
        color: $primaryPink;
        font-size: 10px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        margin-right: 6px;
      }
      .productPriceValue {
        color: $primaryPink;
        font-size: 36px;
        font-style: normal;
        font-weight: 300;
        line-height: 36px; /* 100% */
        letter-spacing: 0.5px;
      }
      .productPriceDecimal {
        color: $primaryPink;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 36px;
        letter-spacing: 0.5px;
      }
      .productPriceDiscount {
        font-size: 10px;
        text-decoration: line-through;
        margin-right: 4px;
      }
    }
    .productBrand {
      color: $teritaryBlack;
      font-family: $UrbanistRegular;
      font-size: 10px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
    }
    .productQuantityGrid {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      // grid-template-columns: 1fr 1fr;
      grid-row: 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }

    .buttonContainer {
      margin-top: 70px;
      display: flex;
      gap: 24px;
      div {
        width: 192px;
        height: 40px;
      }
    }
    .productReviewContainer {
      margin-top: 50px;
      .allReviews {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .hr {
          height: 1px;
          background-color: $tertiaryGray3;
          flex: 1;
        }
        .allReviewsTxt {
          color: $primaryPink;
          font-size: 14px;
          letter-spacing: 0.5px;
          margin-left: 20px;
          cursor: pointer;
        }
      }
    }
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $scrollBarColor;
    border-radius: 10px;
}

&::-webkit-scrollbar-thumb {
    background: $scrollBarColor;
    border-radius: 10px;
}
}
.accordionItem {
  display: flex;
  flex-direction: column;
  * {
    font-size: 14px;
  }
  .productKey {
    color: $tertiaryGray2;
  }
  .description {
    color: $teritaryBlack;
    margin-top: 2px;
  }
}
.productInformationGrid {
  display: grid;
  grid-template-rows: auto auto auto auto auto;
  grid-template-columns: auto auto;
  grid-row-gap: 16px;
}
.supplierDetails {
  display: flex;
  gap: 30px;
  .logo {
    width: 172px;
    height: 154px;
    border-radius: 6px;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 6px;
    }
  }
  .details {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}