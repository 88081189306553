@import "../../../index.scss";

.headingBox {
  .text {
    margin-left: 40px;
    margin-top: 26px;
    font-family: $UrbanistSemiBold;
    color: $tertiaryGray1;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
  }
  .text2 {
    color: $tertiaryGray1;
    margin-top: 5px;
    margin-left: 40px;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.5px;
  }
}

.contentBox {
  display: flex;
  gap: 30px;
  background: $primaryWhite;
  margin: 26px 40px 0;
  padding: 30px;
  border-radius: 5px;
  .card1 {
    cursor: pointer;
    position: relative;
    width: 100%;
    // height: 269px;
    background: $primaryWhite;
    box-shadow: 0px 0px 20px 0px rgba($primaryBlack, 0.1);
    border: 1px solid transparent;
    border-radius: 6px;

    &:hover {
      border: 1px solid $primaryPink;
    }
  }

  .card2 {
    width: 100%;
    cursor: pointer;
    position: relative;
    // height: 269px;
    background: $primaryWhite;
    box-shadow: 0px 0px 20px 0px rgba($primaryBlack, 0.1);
    border: 1px solid transparent;
    border-radius: 6px;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    color: $tertiaryGray1;
    letter-spacing: 0.5px;

    .card2Text {
      padding: 20px 16px 18px 12px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: $primaryWhite;
      box-shadow: 0px 0px 20px 0px rgba($primaryBlack, 0.1);
      border: 1px solid transparent;
      position: absolute;
      width: 100%;
      border-radius: 6px;
      bottom: 0px;
      gap: 3px;
    }
    .inputField {
      display: none;
    }

    &:hover {
      border: 1px solid $primaryPink;
    }
  }
}
