@import "../../../index.scss";
.container {
  background-color: $primaryWhite;
  padding: 28px 30px 30px 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: calc(100vh - 65px);
  width: 100%;
  .otpStatus {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    margin: 0px 0px 40px 15px;
    .icon {
      width: 90px;
      height: 90px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info {
      padding-top: 2px;
      flex: 1;
      .label {
        color: $tertiaryBlack;
        font-family: $UrbanistSemiBold;
        font-size: 14px;
        letter-spacing: 0.5px;
        margin-bottom: 5px;
      }
      .desc {
        color: $tertiaryBlack;
        font-family: $UrbanistRegular;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.5px;
        max-width: 254px;
      }
    }
  }
  .body {
    flex: 1;
    padding-left: 30px;
    .getOtpFieldWrap {
      .inputLabel {
        color: $teritaryBlack;
        font-family: $UrbanistRegular;
        font-size: 14px;
        letter-spacing: 0.5px;
        margin-bottom: 3px;
      }
      .inputField {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 10px;
        border-radius: 4px;
        border: 1px solid $tertiaryGray2;
        padding-left: 0;
        width: 100%;
        .rowSeperator {
          border-right: 1px solid $tertiaryGray6;
          width: 1px;
          height: 20px;
          margin-right: 8px;
          align-self: center;
        }
        .inputTag {
          flex: 1;
          &::placeholder {
            color: $tertiaryGray2;
            font-family: $UrbanistRegular;
            font-size: 14px;
            letter-spacing: 0.5px;
          }
        }
        .getOtp {
          border: 0px;
          color: $primaryPink;
          font-family: $UrbanistRegular;
          font-size: 14px;
          letter-spacing: 0.5px;
          background-color: $primaryWhite;
          cursor: pointer;
          margin-left: 10px;
        }
        .disabled {
          opacity: 0.5;
        }
        .activated {
          opacity: 1;
        }
        &:hover {
          box-shadow: 0px 0px 8px 0px rgba(22, 32, 46, 0.25);
        }
        &:has(input:focus-visible),
        &:has(input:focus) {
          border: 1px solid $primaryPink;
          box-shadow: 0px 0px 24px rgba($primaryPink, 0.15);
        }
      }
    }
    .error {
      font-family: $UrbanistItalic;
      font-size: 12px;
      letter-spacing: 0.5px;
      color: $secondaryRed;
      margin-top: 10px;
    }
    .otpBoxesWrapper {
      margin-top: 30px;
      .otpBoxesLabel {
        color: $teritaryBlack;
        font-family: $UrbanistRegular;
        font-size: 14px;
        letter-spacing: 0.5px;
        margin-bottom: 8px;
      }
      .otpBoxes {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 20px;
        .otpBox {
          width: 50px;
          height: 40px;
          border: 1px solid $tertiaryGray2 !important;
          text-align: center;
          border-radius: 4px;
          &:hover {
            box-shadow: 0px 0px 8px 0px rgba(22, 32, 46, 0.25);
          }
          &:focus,
          &:focus-visible {
            border: 1px solid $primaryPink !important;
            box-shadow: 0px 0px 24px rgba($primaryPink, 0.15) !important;
          }
        }
      }
      .otpSentInfo {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        .otpSentDesc {
          color: $teritaryBlack;
          font-family: $UrbanistRegular;
          font-size: 12px;
          letter-spacing: 0.5px;
          line-height: 14px;
        }
        .timer {
          color: $tertiaryGray2;
          font-family: $UrbanistRegular;
          line-height: 14px;
          font-size: 12px;
          letter-spacing: 0.5px;
        }
      }
      .verificationStatus {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        column-gap: 4px;
        margin: 10px 0px 20px 0px;
        .statusIcon {
          width: 24px;
          height: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .success {
          color: $primaryGreen;
          font-family: $UrbanistSemiBold;
          font-size: 12px;
          letter-spacing: 0.5px;
          line-height: 14px;
        }
        .failed {
          color: $secondaryRed;
          font-family: $UrbanistRegular;
          font-size: 12px;
          letter-spacing: 0.5px;
          line-height: 14px;
        }
      }
      .seperator {
        flex: 1;
        height: 1px;
        background-color: $tertiaryGray1;
        opacity: 0.1;
        margin: 19px 0px;
      }
      .resendOtpWrapper {
        display: flex;
        align-items: center;
        column-gap: 6px;
        .resendOtpDesc {
          color: $teritaryBlack;
          line-height: 14px;
          font-family: $UrbanistRegular;
          font-size: 12px;
          letter-spacing: 0.5px;
        }
        .resendOtp {
          color: $primaryPink;
          font-family: $UrbanistRegular;
          font-size: 14px;
          letter-spacing: 0.5px;
          border: 0px;
          background-color: $primaryWhite;
          cursor: pointer;
        }
      }
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 30px;
    .cancel {
      color: $primaryPink;
      font-family: $UrbanistRegular;
      font-size: 14px;
      letter-spacing: 0.5px;
      cursor: pointer;
      border: 0px;
      background-color: $primaryWhite;
    }
    .confirm {
      font-family: $UrbanistRegular;
      font-size: 14px;
      letter-spacing: 0.5px;
      height: 40px;
      width: 120px;
      border-radius: 6px;
      border: 0px;
      cursor: pointer;
    }
    .disable {
      background-color: $tertiaryGray3;
      color: $teritaryBlack;
      pointer-events: none;
    }
    .active {
      background-color: $primaryPink;
      color: $primaryWhite;
      pointer-events: all;
    }
  }
  .labelError {
    color: $secondaryRed !important;
  }
}
