@import "../../../../../index.scss";
.movContainer {
    padding: 18px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    flex: 1;
    .label {
        color: $teritaryBlack;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
    }
    .inputField {
        display: flex;
        align-items: center;
        border: 1px solid $tertiaryGray2;
        border-radius: 4px;
        padding: 11px;
        margin-top: 25px;
        input{
            flex: 1;
        }
        &:hover {
            box-shadow: 0px 0px 8px 0px rgba($tertiaryGray6, 0.25);
        }
        &:has(input:focus-visible),
        &:has(.textarea:focus-visible) {
            border: 1px solid $primaryPink !important;
            box-shadow: 0px 0px 24px rgba($primaryPink, 0.15) !important;
        }
    
        .aed {
            color: $teritaryBlack;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistRegular;
            margin-right: 10px;
        }
    }
    .errorBorder {
        border-color: $secondaryRed !important;
    }
    .errorMessage {
        color: $secondaryRed;
        font-family: $UrbanistRegular;
        font-size: 12px;
        font-style: italic;
        line-height: normal;
        letter-spacing: 0.5px;
        margin-top: 10px;
    }
    
    .btns {
        display: flex;
        gap: 30px;
        align-self: end;
        flex: 1;
        align-items: center;
        margin-top: 40px;
        .cancel {
            color: $primaryPink;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistRegular;
            cursor: pointer;
        }
        .confirm {
            width: 120px;
        }
    }
}