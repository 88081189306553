@import "../../../../common//styles/variables.scss";
.placeOrderPageWrapper {
  height: calc(100% - 70px);
  margin: 30px 40px 40px 40px;
}
.placeOrderWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
  height: 100%;

  .addressPaymentWrapper {
    width: 100%;
    background-color: $primaryWhite;
    padding: 30px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    overflow-y: scroll;
  }
}
