@import "../../../../index.scss";
.form {
  display: flex;
  flex-direction: column;
  padding: 0px 30px 30px 30px;
  flex: 1;
  justify-content: space-between;
  row-gap: 30px;
  .formInputs {
    .payment {
      padding-top: 28px;
      .paymentLabel {
        color: $tertiaryGray1;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistSemiBold;
        padding-bottom: 20px;
      }
      .paymentInput {
        display: flex;
        gap: 30px;
        .paid {
          display: flex;
          align-items: center;
          .paidLabel {
            color: $tertiaryGray1;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistRegular;
            padding-left: 10px;
          }
        }
      }
    }
    .status {
      padding-top: 28px;
      .statusCheckbox {
        display: flex;
        gap: 10px;
        align-items: center;
        .statusLabel {
          color: $tertiaryGray1;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          font-family: $UrbanistSemiBold;
        }
      }
      .statusInputs {
        padding-top: 20px;
        display: flex;
        gap: 30px;
        .column {
          display: flex;
          gap: 20px;
          flex-direction: column;
          min-width: 130px;
          .statusOption {
            display: flex;
            gap: 10px;
            align-items: center;
          }
        }
      }
    }
    .date {
      padding-top: 30px;
      .dateCustomClass{
        min-width: 100%;
        justify-content: space-between;
      }
      .label {
        display: flex;
        padding-bottom: 10px;
        .from {
          display: flex;
          flex: 1;
        }
        .to {
          display: flex;
          flex: 1;
        }
      }
      .dateInputs {
        display: flex;
      }
    }
    .buyer {
      margin-top: 30px;
    }
  }
  .filterActions {
    // position: absolute;
    // bottom: 30px;
    // padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 30px;
    .reset {
      color: $primaryPink;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistRegular;
      cursor: pointer;
    }
  }
}
.date2{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 30px;
}
.dateLabel{
  font-size: 14px;
  font-family: "UrbanistRegular";
  color: #1c1919;
  letter-spacing: 0.5px;
  margin-bottom: 6px;
}