.reviewContainer{
    display: flex;
    justify-content: space-between;
    .averageRatingSection{
        width:50%;
        flex: 1;
    }
    .customerReviewSection{
        width: 50%;  
        flex:1;      
    }

}