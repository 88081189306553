@import "../../../../../common/styles/variables.scss";
.cartContainer{
  display: flex;
  column-gap: 20px;
  align-items: center;
  .productQuantityButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    width: 132px;
    height: 40px;
    border: 1px solid $tertiaryGray3;
    &.border {
      border: 1px solid $primaryPink;
    }
    .productQuantityButton {
      height: 100%;
      border: none;
      background-color: transparent;
      padding: 12px 16.5px;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primaryPink;
      align-self: center;
      cursor: pointer;
    }
    .productQuantityDecrement {
      border-right: 1px solid $tertiaryGray3 !important;
      &.borderRight {
        border-right: 1px solid $primaryPink !important;
      }
    }
    .decrement {
      height: 1px;
      width: 12px;
      background-color: $primaryPink;
    }
    .productQuantityIncrement {
      border-left: 1px solid $tertiaryGray3 !important;
      &.borderLeft {
        border-left: 1px solid $primaryPink !important;
      }
    }
    .productQuantityInput {
      text-align: center;
      width: 47px;
      padding: 0 5px;
    }
  }
  .delete{
    width: 24px ;
    height: 24px;
    cursor: pointer;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
