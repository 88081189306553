@import "../../../../../common//styles//variables.scss";

.editCartHeaderWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border: 2px solid $primaryGreen;
  border-radius: 6px;
  background-color: $primaryWhite;
  position: relative;
  .textWrapper {
    color: $teritaryBlack;
    margin-left: 20px;
    letter-spacing: 0.5px;
    margin-right: 57px;
    .text {
      font-size: 0.875rem;
      margin-top: 8px;
    }
    .textHeader {
      font-size: 1.5rem;
    }
  }

  .ellipse1 {
    position: absolute;
    bottom: 0;
    left: 0px;
  }
  .ellipse2 {
    position: absolute;
    bottom: 0;
    left: 19px;
  }
}
