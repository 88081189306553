@import "../../../../common//styles//variables.scss";

.allReviewsWrapper {
  padding: 0px 30px 50px 30px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px !important;
 }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $scrollBarColor;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollBarColor;
    border-radius: 10px;
  }
  .reviewsWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;

    .totalReviewsContainer {
      margin-top: 20px;
    }
    .totalReviewsText {
      font-size: 2.25rem;
    }
    .colored {
      color: $primaryPink;
    }
    .headerSubText {
      font-family: $UrbanistRegular;
      font-size: 0.875rem;
      margin-left: 10px;
    }
    .ratingDataWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 7px;
    }
    .allReviewCommentsWrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 30px;
    }
  }
}

.drawerHeader {
  padding: 22px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $tertiaryGray2;
  min-width: 460px;
  .drawerHeaderText {
    font-size: 0.875rem;
    font-family: $UrbanistBold;
    font-weight: 600;
  }
  .drawerCloseBtn {
    background-color: transparent;
    outline: none;
    border: none;
  }
}
