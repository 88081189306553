@import '../../../index.scss';

.container {
    display: flex;
    height: 100%;
    background-color: $backgroundThemeColor;

    .rightBodyContainer {
        display: flex;
        flex-direction: column;
        padding: 0 40px;
        padding-bottom: 50px;
        padding-top: 30px;
        gap: 40px;
        // height: 100vh;
        overflow: auto;
        width: 100%;
        .banner {
            // margin-top: 50px;
            // padding-top: 30px;
            img {
                width: 100%;
            }
        }
    }
}