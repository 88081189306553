@import "../../../../index.scss";



.accountVerification,
.resetLink,
.resetComplete {
    padding: 0 57px 29px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 100%;


    &__goback {
        display: flex;
        gap: 8px;
        cursor: pointer;
        align-items: center;
        img {
            margin-left: 4px;
            width: 14px;
            height: 10px;
        }
    
        span {
          letter-spacing: 0.5px;
          color: $tertiaryGray1;
          font-size: 14px;
          line-height: 17px;
        }
      }

    &__body {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 107px;

        .icon {
            display: flex;
            margin-bottom: 35px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .headerText {
            letter-spacing: 0.5px;
            text-transform: uppercase;
            font-family: $UrbanistSemiBold;
            color: $tertiaryGray6; // changed after rebase
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 10px;
        }

        .subheaderText {
            letter-spacing: 0.5px;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: $teritaryBlack;
            max-width: 306px;
            margin-bottom: 24px;

            .email {
                font-family: $UrbanistSemiBold;
                margin-left: 2px ;
            }
        }

        .resendSection {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.5px;
            color: $tertiaryGray1;

            .link_primary {
                margin-left: 5px;
                font-family: $UrbanistRegular;
                font-size: 14px;
                line-height: 19px;
                color: $primaryPink;
                cursor: pointer;
            }
        }


      
    }
}

.accountVerification__footer{
            

    .link_primary {
        font-family: $UrbanistRegular;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: normal;
        color: $primaryPink;
        cursor: pointer;
    }
}
.resetLink {
    padding: 27px 30px 29.5px;

    &__goback {
        display: flex;
        gap: 10px;
        width: 100%;
        cursor: pointer;

        span {
            letter-spacing: 0.5px;
            color: $tertiaryGray1;
            font-size: 14px;
            line-height: 17px;
        }
    }

    &__body {
        margin-top: 108px;
    }
}

.resetComplete {
    padding: 137px 30px;

    &__body {
        margin-top: 0;
        flex: revert;

        .icon {
            width: 66px;
            height: 84px;
            margin-bottom: 34px;
        }

        .header-text {
            margin-bottom: 11px;
        }

        .subheader-text {
            margin-bottom: 47px;
        }
    }

    .resetComplete-button {
        width: 100%;
    }
}