@import "../../../index.scss";

.myProfileContainer {
  .formContainer {
    height: 100%;
    width: 460px;
    background-color: $primaryWhite;
    top: 0px;
    right: 0px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;

    .avatarContainer {
      width: 100%;
      height: 152px;
      position: relative;
      background-image: url(../../../assets/images/avatarBackground.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      z-index: 0;
      filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
      fill: $primaryWhite;

      .avatar {
        position: absolute;
        z-index: 1;
        right: 0;
        background-image: url(../../../assets/images/avatarWatermelon.svg);
        width: 175px;
        height: 115px;
        background-repeat: no-repeat;
        left: 112px;
        top: 75px;

        .upload {
          width: 134.42px;
          height: 134.42px;
          flex-shrink: 0;
          background: $primaryPink;
          border-radius: 50%;
          .ButtonContainer {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 10px;

            .uploadButton {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              column-gap: 6px;
              font-size: 14px;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.5px;
              color: $primaryWhite;
              cursor: pointer;

              label {
                display: inline-block;
              }
              label img {
                width: 24px;
                height: 24px;
                cursor: pointer;
              }
            }

            .deleteButton {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              column-gap: 6px;
              width: 24px;
              height: 24px;
              font-size: 14px;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.5px;
              color: $primaryWhite;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .avatarHover:hover {
        .upload {
          position: relative;
        }
      }
      .avatarHoverDelay:hover{
        pointer-events: none;
        cursor: none;
        .upload {
          position: static;
        }
      }
    }

    .profileContainer {
      margin-top: 61px;
      display: flex;
      flex-direction: column;

      .profileDetails {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: 50px;
        column-gap: 10px;

        .profileName {
          font-family: $UrbanistSemiBold;
          color: $tertiaryGray1;
          font-size: 24px;
          font-style: normal;
          line-height: normal;
          text-align: center;
        }

        .editProfile {
          display: flex;
          flex-direction: row;
          column-gap: 6px;
          align-items: center;

          img {
            cursor: pointer;
          }
        }

        .editProfileText {
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          color: $primaryPink;
          font-family: $UrbanistRegular;
          cursor: pointer;
        }
      }

      .profileRole {
        background: #d9f3cb;
        border-radius: 6px;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        color: #383434;
        font-family: $UrbanistSemiBold;
        text-align: center;
        display: flex;
        justify-content: center;
        width: fit-content;
        margin: 10px auto -10px auto;
        padding: 5px 10px;
      }

      .divider {
        align-self: center;
        width: 66px;
        border-top: 1px solid $tertiaryGray2;
        margin-top: 16px;
        margin-bottom: 20px;
      }

      .contactDetails {
        display: flex;
        flex-direction: row;
        align-self: center;
        gap: 24px;

        .phoneNumber {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 12px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          color: $tertiaryGray1;
          font-family: $UrbanistRegular;
          gap: 5px;
        }

        .emailId {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 12px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          color: $tertiaryGray1;
          font-family: $UrbanistRegular;
          gap: 5px;
        }
      }
    }

    .cardsContainer {
      display: flex;
      flex-direction: row;
      gap: 30px;
      margin-top: 45px;
    }
  }
}

.card {
  width: 185px;
  height: 174px;
  background-color: $primaryWhite;
  cursor: pointer;
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 6px;
  .cardLabel {
    font-family: $UrbanistRegular;
    color: $tertiaryBlack;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
  }
}

@include minWidth(1500) {
  .formContainer {
    width: 510px !important;

    .avatar {
      left: 140px !important;
    }

    .cardsContainer {
      justify-content: space-evenly;
    }
  }
}

@include minWidth(1700) {
  .formContainer {
    width: 580px !important;

    .avatar {
      left: 175px !important;
    }

    .cardsContainer {
      justify-content: space-evenly;
    }
  }
}
