@import '../../../index.scss';

.container {
  display: flex;
  height: 100%;
  width: 100%;

  .right {
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .search {
      padding-top: 24px;
    }

   

    .content {
      margin-top: 32px;
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 100px;
      
     

      .cards {
        margin-bottom: 48px;
        width:100%;

        .cardHeader {
          display: flex;
          justify-content: space-between;

          .heading {
            color: $tertiaryGray1;
            font-size: 14px;
            font-style: normal;
            line-height: 21px;
            letter-spacing: 0.5px;
            font-family: $UrbanistSemiBold;
            margin-bottom: 12px;
          }

        }
      }
    
  }
  }
}

.twoCards{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
}