@import "../../../../index.scss";

.cardContainer {
  width: 317px;
  height: 317px;
  flex-shrink: 0;
  background-color: $primaryWhite;
  box-shadow: 0px 0px 24px rgba($primaryBlack, 0.15);
  border-radius: 4px;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;

  .cardHeader {
    color: $tertiaryGray1;
    font-size: 14px;
    font-style: normal;
    font-family: $UrbanistSemiBold;
    line-height: normal;
    letter-spacing: 0.5px;
    align-self: flex-start;
    margin-left: 5px;
  }

  .divider {
    width: 100%;
    border-top: 1px solid $tertiaryGray2;
    margin-top: 12px;
    margin-bottom: 14px;
  }
  .divider2 {
    width: 100%;
    border-top: 1px solid $tertiaryGray2;
  }
  .cardDataContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-left: 10px;
    .carddataRow {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 17px;
    }
    .aedData {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      column-gap: 5px;
    }
    .aedValue {
      color: $tertiaryGray1;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      display: flex;
      flex-direction: column;
    }
  }

  .amountRecieved {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    margin-top: 24px;

    .totalAed {
      display: flex;
      flex-direction: row;
      column-gap: 7px;
      align-items: baseline;
    }

    .totalAmount {
      color: $tertiaryGray1;
      font-size: 24px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
    }
    .redText {
      color: red;
    }
  }

  .aedLabel {
    color: $tertiaryGray1;
    font-size: 10px;
    font-style: normal;
    line-height: normal;
  }

  .labelContainer {
    color: $tertiaryGray1;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
  }
  .recievedAmount {
    color: $tertiaryGray1;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.07px;
  }
}
