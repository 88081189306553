@import "../../../index.scss";

.sidenavContainer {
  background: $primaryLightGreen;
  height: 100vh;
  min-width: 169px;
  width: 169px;
  // z-index: 4;
  overflow: auto;

  &:after {
    content: "";
    position: absolute;
    height: 100%;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 12.5%;
    background: url("../../../assets/images/logobg.svg") no-repeat;
  }

  .logoContainer {
    padding: 15px 20px 0 20px;
    margin-bottom: 26px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .avatarContainer {
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      bottom: -15px;
      left: 12px;
      background: url("../../../assets/images/avatarbg.svg") no-repeat;
    }
  }

  .divider {
    margin: 20px 19.5px 14px;
    background: $primaryWhite;
  }
  .notificationsLink {
    position: relative;
  }
  .arrowActive {
    display: block !important;
  }
  .notificationsArrowIndicator {
    display: none;
    position: absolute;
    right: 0px;
    width: 0;
    height: 0;
    z-index: 101;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 20px solid white;
  }
  .navLinks {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .link {
      display: flex;
      gap: 6px;
      align-items: center;
      padding: 9px 20px;
      cursor: pointer;
      position: relative;
      &:hover {
        background: $primaryGreen;
      }

      p {
        color: $primaryWhite;
        font-family: $UrbanistSemiBold;
        font-size: 10px;
        line-height: normal;
        text-align: left;
      }
    }

    .divider {
      margin: 4px 20px;
    }
  }
}

.moreLink {
  padding: 8px 20px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: $tertiaryBlack;
  font-size: 14px;
  font-family: $UrbanistRegular;
  line-height: normal;
  letter-spacing: 0.5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: $tertiaryGray9;
  }
}

.tooltipBtn {
  width: 100%;
  position: absolute;
  opacity: 0;
  height: 44px;
  cursor: pointer;
    left: 0;
  }
  
  .sideNavRoleText {
    margin-top: 6px;
    font-weight: bold;
    font-size: 13px;
    background-color: #f3c900;
    display: inline-block;
    padding: 3px 8px;
    border-radius: 25px;
    letter-spacing: 0.6px;
  }
  
  @include maxHeight(720) {
    .navLinks {
      gap: 0px !important;
  }
}

@include minWidth(1900) {
  .sidenavContainer {
    min-width: 230px !important;

    p {
      font-size: 12px !important;
    }
  }
}

.adminSidenavContainer {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    background: url("../../../assets/images/Admin_Musturd_curve.svg") no-repeat;
  }
  .navLinks {
    .link {
      display: flex;
      gap: 6px;
      align-items: center;
      padding: 9px 20px;
      cursor: pointer;
      position: relative;
      img {
        fill: #533a0a;
      }
      p {
        color: #533a0a;
      }
      &:hover {
        background: #e7b962;
      }
    }
  }
}
