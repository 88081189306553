@import "../../../index.scss";

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 513px;
  align-items: center;
  background-color: $primaryWhite;
  border-radius: 8px;

  .SupplierProductCard {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 513px;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: 1px solid $primaryWhite;
    cursor: pointer;

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        height: 90px;
      }

      .heading {
        padding-top: 20px;
        color: $tertiaryBlack;
        font-size: 24px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
      }

      .bulkHeading {
        padding-bottom: 30px;
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      align-items: center;

      .horizontalRule {
        width: 100%;
        max-width: 66px;
        border-top: 1px solid $tertiaryGray2;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .content {
        color: $tertiaryGray1;
        font-family: $UrbanistRegular;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        letter-spacing: 0.07px;
        text-align: center;
      }
    }

    &.bulkCard {
      padding-bottom: 0px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      height: 217px;
      &:hover {
        border: 1px solid $primaryPink;
        border-bottom: 1px solid $primaryWhite;
      }
    }

    &:hover {
      border: 1px solid $primaryPink;

      .heading {
        color: $primaryPink;
      }
    }
  }

  .cardFooter {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    filter: drop-shadow(0px -5px 10px rgba(56, 52, 52, 0.10));
    width: 100%;
    max-width: 513px;
    color: $tertiaryGray1;
    font-family: $UrbanistRegular;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.07px;
    text-align: center;
    background-color: $primaryWhite;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    position: relative;
    top: -5px;
    .download {
      line-height: normal;
      color: $primaryPink;
      margin-right: 5px;
    }

    .arrow {
      height: 18px;
    }

    &:hover {
      display: flex;
      justify-content: space-between;
      background-color: $primaryPink;
      color: $primaryWhite;
      padding-left: 20px;
      padding-right: 20px;

      .download {
        color: $primaryWhite;
      }
    }
  }
}