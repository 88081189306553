@import "../../../index.scss";

.parentContainer {
  width: 100%;
  height: 450px;
  flex-shrink: 0;
  background-color: $primaryWhite;
  padding: 23px 30px 25px 25px;
  border-radius: 6px;

  .InfoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 18px;
    cursor: pointer;

    .infoDetails {
      height: 100%;
      width: 89%;
      display: inline-flex;
      align-items: center;
      gap: 4px;

      .imageCOntainer {
        height: 42px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }

      .infoData {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        padding-left: 6px;

        .productData {
          color: $tertiaryBlack;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.5px;
        }

        .productInfo {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: $tertiaryGray2;
          font-size: 14px;
          font-style: italic;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.5px;
        }
      }
    }

    &:first-child {
      padding-top: 0px;
    }

    .orderAction {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 0px;
      align-items: center;
      white-space: nowrap;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
      margin-top: 15px;
      color: $primaryPink;
      cursor: pointer;

      .spearator {
        background-color: $tertiaryGray3;
        height: 1px;
        width: 100%;
        margin-right: 5px;
      }
    }
  }
}
