@import '../../../../index.scss';

.card {
  display: flex;
  align-items: center;
  background-color: $primaryWhite;
  width: 100%;
  border-radius: 6px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
  border: 1px solid $primaryWhite;
  height: 76px;
  column-gap: 5px;
  cursor: pointer;

  .columnValue {
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.5px;
    font-family: $UrbanistRegular;
    color: $tertiaryGray10;
  }

  .name {
    font-size: 20px;
    text-transform: capitalize;
  }

  &:hover {
    border: 1px solid $primaryPink;
    filter: drop-shadow(0px 0px 24px rgba(236, 24, 123, 0.15));
  }
}