@import "../../../index.scss";
.videoCard{
    display:flex;
    flex-direction: column;
    min-width:332px;
    height: 267px;
    max-width: 332px;
    width: 100%;
    border-radius: 8px;
    background-color: $primaryWhite;
    cursor:pointer;
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
    box-shadow:0px 0px 24px rgba(0, 0, 0, 0.15);
    .videoImage {    
        min-height:187px;
        & img{
            object-fit: cover;
            width:100%;
            height:100%;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
    }
    .videoDetails{
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right:19px;
        padding-top:20px;
        padding-bottom:17px;
        .videoTitle{
            color:$tertiaryBlack;
            font-family: $UrbanistSemiBold;
            font-size: 14px;
            line-height: normal;
            letter-spacing: 0.5px;
            width:183px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .videoDuration{
            display: flex;
            flex-direction:column;
            justify-content: space-between;
            align-items: center;
            .pauseIcon{
                height:24px;
                cursor: pointer;
            }
            .duration{
                color:  $tertiaryBlack;
                font-family: $UrbanistRegular;
                font-size: 12px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                padding-top:5px;
            }
        }        
    }
    &:hover {
        .videoDetails {
          background-color: $primaryPink;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          .videoTitle{
            color:$primaryWhite;
          }
          .pauseIcon{
            height:42px;
          }
          .duration{
            display:none;
          }
        }
    }
}
.overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.70);
    z-index: 4;
    .videoHeader{
        width:100%;
        background-color: rgba(0, 0, 0, 0.70);
        display:flex;
        align-items: center;
        padding-left: 20px;
        padding-top:20px;
        padding-bottom:20px;
        .leftArrow{
            width:24px;
            height:24px;
            cursor:pointer;
        }
        .heading{
            color: $primaryWhite;
            font-size: 20px;
            font-style: normal;
            font-family: $UrbanistRegular;
            line-height: normal;
            letter-spacing: 0.5px;
            margin-left:4px;
        }
    }
    .video{
        width:75%;
        height: 70%;
        position:absolute;
        left:50%;
        top:50%;
        transform: translate(-50%, -50%);
    }
}



@include maxWidth(2100){
    .videoCard{
        max-width: 370px;
    }
  }
  

  @include maxWidth(1600){
    .videoCard{
        max-width: 280px;
        min-width: 280px;
        
    }
  }
  
  @include maxWidth(1500){
    .videoCard{
        max-width: 332px;
        min-width: 332px;
    }
  }
  
  @include maxWidth(1300){
    .videoCard{
        max-width: 450px;
        
    }
  }
  
  @include maxWidth(1210){
    .videoCard{
        max-width: 332px;
        
    }
  }