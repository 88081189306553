@import "../../../index.scss";

.productListContainer {
  width: 100%;
  height: 100%;
  max-height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $scrollBarColor;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollBarColor;
    border-radius: 10px;
  }

  h5 {
    margin-bottom: 15px;
    font-size: 14px;
    font-style: normal;
    font-family: $UrbanistBold;
    line-height: normal;
    letter-spacing: 0.5px;
  }

  .tableContainer {
    background: $primaryWhite;
    padding: 27px 20px;
    padding-bottom: 10px;
    width: 100%;
    border-radius: 6px;
    overflow: auto;

    table {
      border-spacing: 0;
      width: 100%;

      tr {
        vertical-align: baseline;
      }

      thead {
        position: relative;

        th {
          color: $tertiaryGray2;
          font-family: $UrbanistRegular;
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.5px;
          border-bottom: 1px solid $tertiaryGray2;
          text-align: left;
          white-space: nowrap;
          padding-bottom: 8px;
          padding-right: 60px;
        }

        th:last-child {
          width: 322px;
        }
      }

      .inputField {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        white-space: nowrap;
      }

      tbody {
        tr {
          td {
            padding: 19px 18px 20px 0;
            color: $tertiaryGrays2;
            font-family: $UrbanistRegular;
            font-size: 14px;
            line-height: normal;
            letter-spacing: 0.5px;
            border-bottom: 1px solid $tertiaryGray7;
          }

          &:last-child {
            td {
              border: none;
            }
          }

          .textOverflow {
            max-width: 150px;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
      .delete {
        cursor: pointer;
      }
      .disble {
        cursor: default;
        filter: grayscale(1);
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px $scrollBarColor;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollBarColor;
      border-radius: 10px;
    }
  }
}


.modalFullContainer {
  background: $primaryWhite;
  border: 2px solid $primaryLightGreen;
  padding: 18px 30px 30px;
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
  width: 70%;
  max-height: 650px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;

  .modalBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: auto;

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .leftContainer {
        color: $teritaryBlack;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: normal;
        font-family: $UrbanistSemiBold;
      }

      .rightContainer {
        cursor: pointer;
      }
    }

    .seperator {
      height: 1px;
      width: 100%;
      background-color: $tertiaryGray2;
      margin-top: 20px;
    }

    .body {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      flex: 1;

      .modifyOrder {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .modalFullContainer {
          background: $primaryWhite;
          border: 2px solid $primaryLightGreen;
          padding: 18px 30px 30px;
          filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
          width: 70%;
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: space-between;

          .modalBody {
            flex: 1;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;

            .heading {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .leftContainer {
                color: $teritaryBlack;
                font-size: 14px;
                letter-spacing: 0.5px;
                line-height: normal;
                font-family: $UrbanistSemiBold;
              }

              .rightContainer {
                cursor: pointer;
              }
            }

            .seperator {
              height: 1px;
              width: 100%;
              background-color: $tertiaryGray2;
              margin-top: 20px;
            }

            .body {
              margin-top: 20px;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              flex: 1;
            }
          }
        }
      }

    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    .errorMessage {
      color: $secondaryRed;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.07px;
    }
    .button {
      height: 40px;
      margin-top: -15px;
    }
  }
}