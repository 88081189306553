.wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px;
  // margin-top: 40px;
  overflow-y: auto;
  height: 100%;
}
.headingSection {
  margin-bottom: 20px;
}
.heading {
  text-align: center;
}
.contentSection {
  margin-top: 20px;
}
.contentText {
  font-size: 16px;
  line-height: 2;
  margin-bottom: 32px;

  color: "#7A7A7A";
  font-weight: 200;
}
.contentpoints {
  font-size: 16px;
  line-height: 2;
  color: "#7A7A7A";
  font-weight: 200;
}
.contentHeading {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
