@import "../../../index.scss";

.parent {
  display: flex;
  margin-right: 20px;
  height: 100%;

  .outerContainer {
    width: 100%;
    margin-left: 40px;
    padding-top: 20px;

    .searchContainer {
      width: 100%;
      margin-top: 20px;
      padding-right: 20px;
    }

    .buttonOuterContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      margin-top: 13px;
      padding-right: 30px;
    }

    .mainContainer {
      height: calc(100% - 124px);
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 20px;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $scrollBarColor;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: $scrollBarColor;
        border-radius: 10px;
      }
    }

    .buttonInnerContainer {
      display: flex;
      flex-direction: row;
      gap: 30px;
      flex: 1;
      justify-content: flex-end;

      .contactSupport {
        position: relative;
      }

      .actions {
        display: flex;
        position: relative;

        .actionItems {
          background-color: $primaryWhite;
          border-radius: 6px;
          padding-top: 10px;
          padding-bottom: 10px;
          position: absolute;
          top: 39px;
          z-index: 1;
          right: -20px;
          width: 251px;
          display: flex;
          flex-direction: column;
          box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);

          .option {
            display: flex;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            cursor: pointer;

            .label {
              padding-left: 10px;
              color: $tertiaryBlack;
              font-size: 14px;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.5px;
              font-family: $UrbanistRegular;
            }

            &:hover {
              background-color: $tertiaryGray9;
            }
          }

          &::after {
            content: " ";
            position: absolute;
            right: 16px;
            border-width: 16px;
            top: -31px;
            border-style: solid;
            border-color: transparent transparent $primaryWhite transparent;
          }
        }
      }
    }

    .buttonContainerDelivery {
      display: flex;
      flex-direction: row;
      gap: 30px;
      flex: 1;
      justify-content: flex-end;
    }

    .container {
      h5 {
        margin-bottom: 15px;
        font-size: 14px;
        font-style: normal;
        font-family: $UrbanistBold;
        line-height: normal;
        letter-spacing: 0.5px;
      }

      .tableContainer {
        background: $primaryWhite;
        padding: 27px 20px;
        width: 100%;
        border-radius: 6px;
        // margin-bottom: 50px;

        table {
          border-spacing: 0;
          width: 100%;

          thead {
            position: relative;

            th {
              color: $tertiaryGray2;
              font-family: $UrbanistRegular;
              font-size: 14px;
              line-height: normal;
              letter-spacing: 0.5px;
              border-bottom: 1px solid $tertiaryGray2;
              padding: 0px 60px 8px 0;
              text-align: left;
            }

            th:last-child {
              width: 322px;
            }
          }

          tbody {
            tr {
              td {
                padding: 19px 18px 20px 0;
                color: $tertiaryGrays2;
                font-family: $UrbanistRegular;
                font-size: 14px;
                line-height: normal;
                letter-spacing: 0.5px;
                border-bottom: 1px solid $tertiaryGray7;
              }

              &:last-child {
                td {
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.ratingModal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .modalFullContainer {
    background: $primaryWhite;
    border: 2px solid $primaryLightGreen;
    padding: 18px 30px 30px;
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
    max-width: 460px;
    min-width: 460px;
    max-height: 544px;
    // min-height: 544px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;

    .modalBody {
      flex: 1;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .leftContainer {
          color: $teritaryBlack;
          font-size: 14px;
          letter-spacing: 0.5px;
          line-height: normal;
          font-family: $UrbanistSemiBold;
        }

        .rightContainer {
          cursor: pointer;
        }
      }

      .seperator {
        height: 1px;
        width: 100%;
        background-color: $tertiaryGray2;
        margin-top: 20px;
      }

      .body {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        flex: 1;

        .ratingContainer {
          display: flex;
          justify-content: space-between;

          .ratingBox {
            display: flex;
            flex-direction: column;

            .upContainer {
              display: flex;
              gap: 10px;
              font-family: $UrbanistRegular;
              font-size: 12px;

              .ratingValue {
                span {
                  color: $tertiaryGray1;
                  font-family: $UrbanistRegular;
                  font-size: 14px;
                  line-height: normal;
                  letter-spacing: 0.5px;

                  &:first-child {
                    font-size: 24px;
                  }
                }
              }

              .ratingStarContainer {
                display: flex;
                align-items: center;
                padding-top: 3px;
              }
            }

            .ratingBlock {
              color: $tertiaryGray1;
              font-family: $UrbanistRegular;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.07px;
            }
          }
        }

        .questionContainer {
          margin-top: 30px;
          font-family: $UrbanistSemiBold;
          flex: 1;

          .heading {
            color: $tertiaryGray1;
            font-family: $UrbanistSemiBold;
            font-size: 14px;
            line-height: normal;
            letter-spacing: 0.5px;
          }

          .options {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            max-height: 132px;
            overflow-y: auto;

            .option {
              display: flex;
              align-items: center;
              gap: 5px;
              margin-bottom: 12px;

              .label {
                color: $tertiaryGray1;
                font-family: $UrbanistRegular;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        .textAreaContainer {
          display: flex;
          height: 80px;
          align-items: flex-start;
          gap: 10px;
          flex-shrink: 0;
          align-self: stretch;
          border-radius: 4px;
          border: 1px solid $tertiaryGray2;
          background: $primaryWhite;
          overflow: hidden;
          margin-top: 30px;

          textArea {
            width: 100%;
            height: 100%;
            padding: 11px 10px;
            resize: none;
          }
        }
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;

      .button {
        height: 40px;
        margin-top: -15px;
        margin-left: 20px;
      }
    }
  }
}

.modifyOrder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@include maxWidth(1300) {
  .buttonContainerDelivery {
    width: 50% !important;
    gap: 15px !important;
  }
}

.viewMode {
  pointer-events: none;
}
.clickable {
  cursor: pointer;
}
.selectAll {
  display: flex;
  margin-top: 10px;
  gap: 5px;
}
.selectAll label {
  display: flex;
  color: #383434;
  font-family: "UrbanistRegular";
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  align-items: center;
}
