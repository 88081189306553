@import '../../../index.scss';


.mainContainer {

    height: 100%;
    width: 100%;
    padding: 20px;

    .sliderContainer {
        padding: 0px 10px;

        .iconContainer {
            width: 100%;
            display: flex !important;
            flex-direction: column !important;
            justify-content: center !important;
            align-items: center !important;

            margin-right: 8px;
            cursor: pointer;
            .icon{
                height: 60px;
                width: 60px;
                margin-bottom: 10px;
            }
            img {
                width: 100%;
                height: 100%;
            }
            .title {
                width: 100%;
                color: $tertiaryBlack;
                font-size: 14px;
                font-style: normal;
                line-height: 20px;
                letter-spacing: 0.5px;
                font-family: $UrbanistRegular ;
                text-align: center;
            }
        }

    }

    .pathContainer {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        padding: 20px 20px;
        // border: 1px solid $tertiaryGray7;
        border-radius: 6px;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
        .actionContainer{
            display: flex;
            border: 1px solid $tertiaryGray7;
            border-radius: 6px;
            width: 100%;
            align-items: center;
            padding-right: 10px;
            .input{
                flex: 1;
                pointer-events: none;
            }
            .inputLink{
                margin-bottom: 0px;
            }
        }
        .commonButton {
            height: 30px;
            background: $primaryPink;
            border: 1px solid transparent;
            color: $primaryWhite;
            border-radius: 6px;
            text-align: center;
            font-size: 14px;
            font-family: $UrbanistRegular;
            letter-spacing: 0.5px;
            cursor: pointer;
            transition: all ease 0.5s;
            width: 120px;
        }
        

    }

}