@import "../../../../../common/styles/variables.scss";

.paymentModeCardWrapper {
  margin-top: 30px;
  .paymentModeHeaderWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding-bottom: 6px;
    border-bottom: 1px solid $tertiaryGray3;
    .paymentModeHeader {
      font-size: 0.875rem;
      font-family: $UrbanistSemiBold;
    }
  }
  .labelValueWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    .labelText {
      font-size: 0.875rem;
    }
    .labelValue {
      font-size: 0.875rem;
    }
  }
}
