@import "../../../../index.scss";

.cardContainer {
  width: 317px;
  height: 317px;
  flex-shrink: 0;
  background-color: $primaryWhite;
  box-shadow: 0px 0px 24px rgba($primaryBlack, 0.15);
  border-radius: 4px;
  padding: 36px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;

  .cardTitleContainer {
    width: 100%;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cardTitle {
    color: $tertiaryGrays2;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
  }

  .cardSvgContainer {
    margin-top: 22px;
    margin-left: 3px;
  }
  .cardSubTitle {
    color: $teritaryBlack2;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.5px;
    margin-top: 3px;
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 20px;
    margin-top: 8px;
  }
}
