.deleteConfirmContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 25px;

    .popupActions {
        align-self: flex-end;
        width: 55%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 10px;
    }

}