@import "../../styles/variables.scss";

.averageRating {
  .ratingSummary {
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.5px;
    .ratingSummaryTop {
      .numericValue {
        color: $tertiaryGray1;
        margin-right: 10px;
        .actualValue {
          color: $primaryPink;
        }
      }
    }
    .ratingSummaryBottom {
      display: flex;
      gap: 10px;
    }
    .basedOn {
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
    }
  }

  .ratingDistribution {
    display: flex;
    flex-direction: column;
    .rating {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
     
      align-items: center;
      .numberOfRatings {
        color: $tertiaryBlack4;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
      }
      div:first-child {
        text-align: center;
      }
    }
  }
}
progress {
  height: 8px;
}

progress::-webkit-progress-bar {
  background-color: grey;
}
