@import "../../../index.scss";

.container {
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    flex: 1;

    .tabsContent {
        .tabslistContainer {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            overflow-y: hidden;

            .ordersList {
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-top: 15px;
                height: calc(100vh - 244px);
                overflow-y: auto;
            }

            .paginationContainer {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }
}


.searchContainer {
    display: flex;
    justify-content: space-between;
    width: 516px;
    background-color: $primaryWhite;
    border: 1px solid $primaryWhite;
    border-radius: 40px;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 6px;
    padding-left: 10px;

    .searchIconInput {
        display: flex;
        align-items: center;
        width: 100%;

        .searchInput {
            height: 24px;
            padding-left: 10px;
            display: flex;
            flex: 1;
        }
    }

    .cancel {
        cursor: pointer;
        height: 24px;
    }

    &:hover {
        border-color: $primaryPink;
    }

}

.searchIcon {
    height: 24px;
    cursor: pointer;
}

.hr {
    border-bottom: 1px solid $tertiaryGray3;
    position: relative;
    top: 0;
    width: 92%;
}

.searchMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.actions {
    display: flex;
    margin-left: 20px;
    gap: 30px;

    .icon {
        height: 24px;
        cursor: pointer;
    }

    .search {
        .searchContainer {
            display: flex;
            justify-content: space-between;
            width: 516px;
            background-color: $primaryWhite;
            border: 1px solid $primaryWhite;
            border-radius: 40px;
            align-items: center;
            padding-top: 3px;
            padding-bottom: 3px;
            padding-right: 6px;
            padding-left: 10px;

            .searchIconInput {
                display: flex;
                align-items: center;
                width: 100%;

                .searchInput {
                    height: 24px;
                    padding-left: 10px;
                    display: flex;
                    flex: 1;
                }
            }

            .cancel {
                cursor: pointer;
                height: 24px;
            }

            &:hover {
                border-color: $primaryPink;
            }

        }

        .searchIcon {
            height: 24px;
            cursor: pointer;
        }
    }

    .sort {
        position: relative;

        .sortList {
            background-color: $primaryWhite;
            border-radius: 6px;
            padding-top: 20px;
            position: absolute;
            top: 39px;
            z-index: 1;
            right: -26px;
            width: 251px;
            display: flex;
            flex-direction: column;
            box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);

            .sortBy {
                color: $teritaryBlack;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                font-family: $UrbanistSemiBold;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 10px;
            }

            .sortOption {
                color: $teritaryBlack;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                font-family: $UrbanistRegular;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 10px;
                padding-bottom: 10px;

                &:hover {
                    background-color: $tertiaryGray9;
                }

                &:active {
                    color: $primaryPink;
                }
            }

            .lastOption {
                margin-bottom: 10px;
            }

            .selectedOpt {
                color: $primaryPink;
            }

            &::after {
                content: " ";
                position: absolute;
                right: 25px;
                border-width: 16px;
                top: -31px;
                border-style: solid;
                border-color: transparent transparent $primaryWhite transparent;
            }
        }

    }
}

.actions2 {
    position: relative;
    display: flex;
    align-items: center;
}
.tableHeaderColumn{
    font-family: $UrbanistRegular !important;
    color: $tertiaryGray2 !important;
    font-size: 14px !important;
    letter-spacing: 0.5px !important;
}