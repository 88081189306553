@import "../../../../../../common//styles//variables.scss";
.selectAddressWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid $tertiaryGray3;
  padding: 20px;
  color: $tertiaryBlack;
  letter-spacing: 0.5px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  //   :not(:first-child) {
  //   }
  .addressUserName {
    font-size: 1.125rem;
  }
  .addressText {
    font-size: 0.875rem;
    line-height: 24px;
    min-width: 20%;
  }
  .badge {
    display: inline-block;
    padding: 4px 10px;
    background-color: #e9e9e9;
    font-size: 0.875rem;
    border-radius: 25px;
    color: $tertiaryGray1;
    margin-left: 8px;
  }

  .defaultBadge {
    background-color: $paidBackgroundColor2;
    color: $activeColor;
  }
  .addressActions {
    position: absolute;
    top: 22%;
    right: 30px;
    margin: auto 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    .addressActionBtn {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: transparent;
      border: none;
      outline: none;
      color: $primaryPink;
      gap: 3.5px;
      cursor: pointer;
    }
  }
}

.addressHovered {
  border: 1px solid $primaryPink;
  box-shadow: 0px 0px 24px 0px rgba(236, 24, 123, 0.15);
}

.addressSelected {
  border: 1px solid $primaryPink;
}
