@import "../../../index.scss";

.container {
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 20px;
    .tabs {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 34px;

        .tabsStyle {
            display: flex;
            flex-direction: column;
            flex: 1;

            .tabList {
                display: flex;
                gap: 40px;
                flex: 1;
                padding-bottom: 6px;

                .tabListItem {
                    cursor: pointer;

                    .label {
                        color: $tertiaryGray1;
                        font-family: $UrbanistRegular;
                        font-size: 14px;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.5px;
                    }

                    .activeLabel {
                        color: $primaryPink;
                        font-size: 14px;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        font-family: $UrbanistSemiBold;
                    }

                    .border {
                        border-top: 3px solid $primaryPink;
                        border-radius: 4px;
                        position: relative;
                        bottom: -4px;
                        z-index: 1;
                    }
                }
            }

            .hr {
                border-bottom: 1px solid $tertiaryGray3;
                position: relative;
                top: -4px;
            }
        }

        .actions {
            display: flex;
            align-items: center;
            margin-left: 20px;
            gap: 30px;

            .icon {
                height: 24px;
                cursor: pointer;
            }

            .search {
                .searchContainer {
                    display: flex;
                    justify-content: space-between;
                    width: 516px;
                    background-color: $primaryWhite;
                    border: 1px solid $primaryWhite;
                    border-radius: 40px;
                    align-items: center;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    padding-right: 6px;
                    padding-left: 10px;

                    .searchIconInput {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        .searchInput {
                            height: 24px;
                            padding-left: 10px;
                            display: flex;
                            flex: 1;
                        }
                    }

                    .cancel {
                        cursor: pointer;
                        height: 24px;
                    }

                    &:hover {
                        border-color: $primaryPink;
                    }

                }

                .searchIcon {
                    height: 24px;
                    cursor: pointer;
                }
            }

            .sort {
                position: relative;

                .sortList {
                    background-color: $primaryWhite;
                    border-radius: 6px;
                    padding-top: 20px;
                    position: absolute;
                    top: 39px;
                    z-index: 1;
                    right: -26px;
                    width: 251px;
                    display: flex;
                    flex-direction: column;
                    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);

                    .sortBy {
                        color: $teritaryBlack;
                        font-size: 14px;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        font-family: $UrbanistSemiBold;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-bottom: 10px;
                    }

                    .sortOption {
                        color: $teritaryBlack;
                        font-size: 14px;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        font-family: $UrbanistRegular;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 10px;
                        padding-bottom: 10px;

                        &:hover {
                            background-color: $tertiaryGray9;
                        }

                        &:active {
                            color: $primaryPink;
                        }
                    }

                    .lastOption {
                        margin-bottom: 10px;
                    }

                    .selectedOpt {
                        color: $primaryPink;
                    }

                    &::after {
                        content: " ";
                        position: absolute;
                        right: 25px;
                        border-width: 16px;
                        top: -31px;
                        border-style: solid;
                        border-color: transparent transparent $primaryWhite transparent;
                    }
                }

            }
        }

        .actions2 {
            position: relative;
            top: 8px;
            height: 32px;
        }
    }

    .tabsContent {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        flex: 1;
        .tableHeader {
            display: flex;
            padding: 0px 35px 0px 20px ;
            width: 100%;

            .headerColumn1 {
                width: 20%;
                min-width: 20%;
                font-family: $UrbanistRegular;
                color: $tertiaryGray2;
                font-size: 14px;
                letter-spacing: 0.5px;
            }
            .width {
                min-width: 31% !important;
            }
            .headerColumn2 {
                width: 15%;
                min-width: 15%;
            }

            .headerColumn3 {
                width: 15%;
                min-width: 15%;
            }

            .headerColumn4 {
                width: 15%;
                min-width: 15%;
            }

            .headerColumn5 {
                width: 15%;
                min-width: 15%;
            }

            .headerColumn6 {
                width: 10%;
                min-width: 10%;
            }
        }

        .tabslistContainer {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            overflow-y: hidden;

            .ordersList {
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-top: 15px;
                height: calc(100vh - 250px);
                overflow-y: auto;
                padding-right: 5px;
                &::-webkit-scrollbar {
                    width: 6px;
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px $scrollBarColor;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb {
                    background: $scrollBarColor;
                    border-radius: 10px;
                }
            }

            .paginationContainer {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }
    .emptyContainer {
        display: flex;
        flex: 1;
        margin-top: 20px;
        margin-bottom: 50px;
    }
}

.searchContainer {
    display: flex;
    justify-content: space-between;
    width: 516px;
    background-color: $primaryWhite;
    border: 1px solid $primaryWhite;
    border-radius: 40px;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 6px;
    padding-left: 10px;

    .searchIconInput {
        display: flex;
        align-items: center;
        width: 100%;

        .searchInput {
            height: 24px;
            padding-left: 10px;
            display: flex;
            flex: 1;
        }
    }

    .cancel {
        cursor: pointer;
        height: 24px;
    }

    &:hover {
        border-color: $primaryPink;
    }

}

.searchIcon {
    height: 24px;
    cursor: pointer;
}

.hr {
    border-bottom: 1px solid $tertiaryGray3;
    position: relative;
    top: 0;
    width: 100%;
}

.searchMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    height: 32px;
}

.actions {
    display: flex;
    margin-left: 20px;
    gap: 30px;
    align-items: center;

    .icon {
        height: 24px;
        cursor: pointer;
    }

    .search {
        .searchContainer {
            display: flex;
            justify-content: space-between;
            width: 516px;
            background-color: $primaryWhite;
            border: 1px solid $primaryWhite;
            border-radius: 40px;
            align-items: center;
            padding-top: 3px;
            padding-bottom: 3px;
            padding-right: 6px;
            padding-left: 10px;

            .searchIconInput {
                display: flex;
                align-items: center;
                width: 100%;

                .searchInput {
                    height: 24px;
                    padding-left: 10px;
                    display: flex;
                    flex: 1;
                }
            }

            .cancel {
                cursor: pointer;
                height: 24px;
            }

            &:hover {
                border-color: $primaryPink;
            }

        }

        .searchIcon {
            height: 24px;
            cursor: pointer;
        }
    }

    .sort {
        position: relative;

        .sortList {
            background-color: $primaryWhite;
            border-radius: 6px;
            padding-top: 20px;
            position: absolute;
            top: 39px;
            z-index: 1;
            right: -26px;
            width: 251px;
            display: flex;
            flex-direction: column;
            box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);

            .sortBy {
                color: $teritaryBlack;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                font-family: $UrbanistSemiBold;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 10px;
            }

            .sortOption {
                color: $teritaryBlack;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                font-family: $UrbanistRegular;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 10px;
                padding-bottom: 10px;

                &:hover {
                    background-color: $tertiaryGray9;
                }

                &:active {
                    color: $primaryPink;
                }
            }

            .lastOption {
                margin-bottom: 10px;
            }

            .selectedOpt {
                color: $primaryPink;
            }

            &::after {
                content: " ";
                position: absolute;
                right: 25px;
                border-width: 16px;
                top: -31px;
                border-style: solid;
                border-color: transparent transparent $primaryWhite transparent;
            }
        }

    }
}

.actions2 {
    position: relative;
    display: flex;
    align-items: center;
}