@import "../../../index.scss";

.container {
  width: 460px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 17px;
    margin-left: 30px;
    margin-right: 30px;
    padding-bottom: 16px;
    border-bottom: 1px solid $tertiaryGray2;

    .heading {
      color: $tertiaryBlack;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistSemiBold;
    }

    .cancel {
      cursor: pointer;
    }
  }
  .content {
    padding: 19px 30px 30px 30px;
    overflow: auto;

    .logo {
      border: 1px solid $tertiaryGray2;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 133px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      gap: 25px;
      margin-top: 31px;
      max-width: 316px;

      .label {
        color: $tertiaryGray2;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
        margin-bottom: 10px;
      }

      .value {
        color: $primaryBlack;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
      }

      .view {
        color: $primaryPink;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
        cursor: pointer;
        pointer-events: all;
      }
      .disable{
        pointer-events: none;
        color: #949499;
      }
    }

    .add {
      display: flex;
      align-items: center;
      gap: 30px;
      margin-top: 60px;

      .addCard {
        padding: 20px 33px;
        width: 185px;
        height: 174px;
        filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $primaryWhite;
        border-radius: 6px;
        cursor: pointer;

        .icon {
          height: 90px;
          width: 90px;
          display: flex;

          img {
            margin: auto;
          }
        }

        .addLabel {
          color: $teritaryBlack;
          font-family: $UrbanistRegular;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          text-align: center;
          margin-top: 10px;
        }
      }
    }
  }

  .btns {
    display: flex;
    align-self: end;
    flex: 1;
    gap: 20px;
    align-items: end;
    margin-right: 30px;
    margin-bottom: 30px;

    .editBtn {
      width: 120px;
    }
  }
}

@include minWidth(1500) {
  .container {
    width: 510px !important;

    .add {
      justify-content: space-evenly;
    }
  }
}

@include minWidth(1700) {
  .container {
    width: 580px !important;

    .add {
      justify-content: space-evenly;
    }
  }
}


.disabledCard{
  pointer-events: none;
}