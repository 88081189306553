@import "../../../index.scss";

.textBtn{
  align-self: baseline;
  // margin-right: 32px;
  font-size: 14px;
  color: #ec187b;
  margin-top: 10px;
  cursor: pointer;
}
.invoicePage {
  display: flex;
  height: 100%;
  &__heading {
    font-family: $UrbanistSemiBold;
    font-size: 20px;
    color: $tertiaryGrays2;
    letter-spacing: 0.5px;
  }

  &__tableSection {
    width: 100%;
    padding-top: 30px;
    padding-left: 41px;
    padding-right: 36px;
    // width:calc(100% - 169px);
    display: flex;
    flex-direction: column;

    .tableContainer {
      width: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
      margin-top: 50px;

      &::-webkit-scrollbar {
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $scrollBarColor;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: $scrollBarColor;
        border-radius: 10px;
      }
    }

    .header {
      display: flex;
      padding-left: 20px;
      padding-right: 5px;

      .heading {
        color: $tertiaryGray2;
        font-size: 14px;
        font-style: normal;
        letter-spacing: 0.5px;
        line-height: normal;
        font-family: $UrbanistRegular;
      }

      .invoiceNo {
        max-width: 29.5%;
        min-width: 29.5%;
        width: 29.5%;
      }

      .orderId {
        max-width: 15.5%;
        min-width: 15.5%;
        width: 15.5%;
      }

      .outlet {
        max-width: 13.5%;
        min-width: 13.5%;
        width: 13.5%;
      }

      .supplier {
        max-width: 13.5%;
        min-width: 13.5%;
        width: 13.5%;
      }

      .estAmnt {
        max-width: 13.5%;
        min-width: 13.5%;
        width: 13.5%;
      }

      .actualAmnt {
        max-width: 10%;
        min-width: 10%;
        width: 10%;
      }
    }

    .tabslistContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-y: hidden;
      overflow-x: hidden;
      width: 100%;

      .ordersList {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 15px;
        height: calc(100vh - 252px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 5px;
        scrollbar-width: thin; // for mozilla

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px $scrollBarColor;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: $scrollBarColor;
          border-radius: 10px;
        }
      }

      // override for admin invoices
      .overrideOrdersList {
        height: calc(100vh - 240px);
      }

      .paginationContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
      }
    }
    .emptyContainer {
      display: flex;
      flex: 1;
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }

  &__headingSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
    padding-right: 5px;
    position: relative;

    .header{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__tableFunctions {
    display: flex;
    gap: 30px;
    height: 24px;
    align-items: center;
  }

  .setMargin {
    margin-bottom: 30px;
    // width: 100%;
  }

  .adminSearchContainer {
    display: flex;
    gap: 20px;
    height: 24px;
    align-items: center;
    position: absolute;
    top: 40px;
    width: 100%;

    .line {
      border-bottom: 1px solid $tertiaryGray3;
      width: 100%;
    }
  }

  .searchContainer {
    width: 100%;
    margin-bottom: 30px;
    padding-right: 5px;
  }

  .hr {
    border-bottom: 1px solid $tertiaryGray3;
    position: relative;
    top: -3px;
  }
}

@include maxWidth(1300) {
  // .tableContainer {
  //     overflow-x: scroll !important;
  // }

  .tabslistContainer {
    width: max-content;
    overflow-x: hidden;

    .ordersList {
      overflow-x: hidden;
    }
  }

  .paginationContainer {
    position: fixed;
    right: 0;
    bottom: 0;
    margin-right: 30px;
  }
}

// @include minWidth(1500) {
//     .header {
//         gap: 3%;
//         width: 100%;
//     }
// }

// @include minWidth(1700) {
//     .header {
//         gap: 5%;
//         width: 100%;
//     }
// }

.sort {
  position: relative;
  cursor: pointer;

  .sortList {
    background-color: $primaryWhite;
    border-radius: 6px;
    padding-top: 20px;
    position: absolute;
    top: 39px;
    z-index: 1;
    right: -26px;
    width: 320px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);

    .sortBy {
      color: $teritaryBlack;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistSemiBold;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 10px;
    }

    .sortOption {
      color: $teritaryBlack;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistRegular;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      cursor: pointer;

      &:hover {
        background-color: $tertiaryGray9;
      }

      &:active {
        color: $primaryPink;
      }
    }

    .lastOption {
      margin-bottom: 10px;
    }

    .selectedOpt {
      color: $primaryPink;
    }

    &::after {
      content: " ";
      position: absolute;
      right: 25px;
      border-width: 16px;
      top: -31px;
      border-style: solid;
      border-color: transparent transparent $primaryWhite transparent;
    }
  }
}
.activeFilter {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border: 0.5px #fff;
  background: #69a04a;
  right: 0px;
  top: 1px;
}
