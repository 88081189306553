@import "../../../../../index.scss";
.container {
  // padding-left: 40px;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 40px 40px;
  padding-bottom: 0;
  row-gap: 20px;
  .tabs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 34px;
    // margin-right: 40px;
    .tabsStyle {
      display: flex;
      flex-direction: column;
      flex: 1;
      .tabList {
        display: flex;
        gap: 40px;
        flex: 1;
        padding-bottom: 6px;
        .tabListItem {
          cursor: pointer;
          .label {
            color: $tertiaryGray1;
            font-family: $UrbanistRegular;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
          }
          .activeLabel {
            color: $primaryPink;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistSemiBold;
          }
          .border {
            border-top: 3px solid $primaryPink;
            border-radius: 4px;
            position: relative;
            bottom: -5.5px;
            z-index: 1;
          }
        }
      }
      .tabsBorder {
        border-bottom: 1px solid $tertiaryGray3;
        position: relative;
        top: -2px;
      }
    }
    .actions {
      display: flex;
      margin-left: 20px;
      gap: 30px;
      .icon {
        height: 24px;
        cursor: pointer;
        position: relative;
      }
      .search {
        .searchContainer {
          display: flex;
          justify-content: space-between;
          width: 516px;
          background-color: $primaryWhite;
          border: 1px solid $primaryWhite;
          border-radius: 40px;
          align-items: center;
          padding-top: 3px;
          padding-bottom: 3px;
          padding-right: 6px;
          padding-left: 10px;
          .searchIconInput {
            display: flex;
            align-items: center;
            width: 100%;
            .searchInput {
              height: 24px;
              padding-left: 10px;
              display: flex;
              flex: 1;
              padding-right: 10px;
            }
          }
          .cancel {
            cursor: pointer;
            height: 24px;
          }
          // &:hover {
          //   border-color: $primaryPink;
          // }
        }
        .searchIcon {
          height: 24px;
          cursor: pointer;
        }
      }
      .sort {
        position: relative;
        .sortList {
          background-color: $primaryWhite;
          border-radius: 6px;
          padding-top: 20px;
          position: absolute;
          top: 39px;
          z-index: 1;
          right: -26px;
          width: 251px;
          display: flex;
          flex-direction: column;
          box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
          .sortBy {
            color: $teritaryBlack;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistSemiBold;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 10px;
          }
          .sortOption {
            color: $teritaryBlack;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistRegular;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            &:hover {
              background-color: $tertiaryGray9;
            }
            &:active {
              color: $primaryPink;
            }
          }
          .lastOption {
            margin-bottom: 10px;
          }
          .selectedOpt {
            color: $primaryPink;
          }
          &::after {
            content: " ";
            position: absolute;
            right: 30px;
            border-width: 16px 10px 16px 10px;
            top: -31px;
            border-style: solid;
            border-color: transparent transparent $primaryWhite transparent;
          }
        }
      }
    }
    .actions2 {
      position: relative;
      top: 8px;
    }
  }

  .tabsContentCompleted {
    // overflow-x: auto;
    height: 100%;
    // width:95%;
    .header {
      display: flex;
      padding-left: 25px;
      padding-right: 20px;
      padding-top: 34px;
      margin-right: 40px;
      .labelStyle {
        color: $tertiaryGray2;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
      }
      .orderID {
        max-width: 270px;
        width: 100%;
        padding-left: 15px;
      }
      .placedOn {
        max-width: 217px;
        width: 100%;
      }

      .placedOnCompleted {
        max-width: 217px;
        width: 100%;
      }
      .buyerName {
        max-width: 149px;
        width: 100%;
      }
      .orderValue {
        max-width: 134px;
        width: 100%;
      }
      .actualAmount {
        max-width: 146px;
        width: 100%;
      }
      .orderItems {
        max-width: 167px;
      }
    }
    .tabslistContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-y: hidden;
      position: relative;
      height: calc(100% - 50px);

      .ordersList {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 13px;
        height: calc(100vh - 311px);
        overflow-y: auto;
        overflow-x: hidden;
        // margin-right: 40px;
        padding-right: 5px;
        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px $scrollBarColor;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: $scrollBarColor;
          border-radius: 10px;
        }
      }
      .paginationContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        // padding-right: 40px;
      }
    }
  }
  .tabsContent {
    height: calc(100% - 33px);
    .header {
      display: flex;
      padding-left: 27px;
      padding-right: 20px;
      padding-top: 34px;
      .migratedOrder {
        max-width: 270px;
        width: 100%;
        padding-left: 10px;
      }
      .labelStyle {
        color: $tertiaryGray2;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
      }
      .orderID {
        max-width: 270px;
        width: 100%;
        padding-left: 10px;
      }
      .placedOn {
        max-width: 217px;
        width: 100%;
      }

      .placedOnCompleted {
        max-width: 217px;
        width: 100%;
      }
      .buyerName {
        max-width: 149px;
        width: 100%;
      }
      .orderValue {
        max-width: 134px;
        width: 100%;
      }
      .actualAmount {
        max-width: 146px;
        width: 100%;
      }
      .orderItems {
        max-width: 167px;
        width: 100%;
      }
    }
    .tabslistContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-y: hidden;
      height: calc(100% - 50px);
      .ordersList {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 13px;
        height: calc(100vh - 311px);
        overflow-y: auto;
        // margin-right: 40px;
        padding-right: 5px;
        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px $scrollBarColor;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: $scrollBarColor;
          border-radius: 10px;
        }
      }
      .paginationContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        // padding-right: 40px;
      }
    }
  }
  .emptyContainer {
    display: flex;
    flex: 1 1;
    margin-top: 20px;
    margin-bottom: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin-right: 40px;
    background-color: $primaryWhite;
    margin-top: 10px;
    .woah {
      margin-top: 30px;
      margin-bottom: 4px;
      color: $teritaryBlack;
      font-size: 24px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistRegular;
    }
    .content {
      max-width: 394px;
      text-align: center;
      color: $tertiaryGray1;
      font-family: $UrbanistRegular;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      letter-spacing: 0.07px;
      text-align: center;
    }
  }
}
.emptyContainer {
  display: flex;
  flex: 1;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;
  .filters {
    height: 100%;
    width: 460px;
    background-color: $primaryWhite;
    position: absolute;
    top: 0px;
    right: 0px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 18px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 18px;
      border-bottom: 1px solid $tertiaryGray2;
      .filtersHeading {
        color: $teritaryBlack;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistSemiBold;
      }
      .cancelFilter {
        height: 24px;
        cursor: pointer;
      }
    }
    .form {
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 142px);
      height: 100%;
      overflow-y: auto;
      flex: 1;
      .formInputs {
        .payment {
          padding-top: 28px;
          .paymentLabel {
            color: $tertiaryGray1;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistSemiBold;
            padding-bottom: 20px;
          }
          .paymentInput {
            display: flex;
            gap: 30px;
            .paid {
              display: flex;
              align-items: center;
              .paidLabel {
                color: $tertiaryGray1;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                font-family: $UrbanistRegular;
                padding-left: 10px;
              }
            }
          }
        }
        .status {
          padding-top: 28px;
          .statusCheckbox {
            display: flex;
            gap: 10px;
            align-items: center;
            .statusLabel {
              color: $tertiaryGray1;
              font-size: 14px;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.5px;
              font-family: $UrbanistSemiBold;
            }
          }
          .statusInputs {
            padding-top: 20px;
            display: flex;
            gap: 30px;
            .column {
              display: flex;
              gap: 20px;
              flex-direction: column;
              min-width: 130px;
              .statusOption {
                display: flex;
                gap: 10px;
                align-items: center;
              }
            }
          }
        }
        .date {
          padding-top: 50px;
          .label {
            display: flex;
            padding-bottom: 10px;
            .from {
              display: flex;
              flex: 1;
            }
            .to {
              display: flex;
              flex: 1;
            }
          }
          .dateInputs {
            display: flex;
          }
        }
        .buyer {
          margin-top: 30px;
        }
      }
    }
    .filterActions {
      padding-top: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      column-gap: 30px;
      .reset {
        color: $primaryPink;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
        cursor: pointer;
      }
    }
    .commonLabel {
      color: $teritaryBlack;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistRegular;
    }
  }
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

@include minWidth(1500) {
  .tabsContent {
    .header {
      gap: 50px;
    }
  }
  .tabsContentCompleted {
    .header {
      gap: 40px;
    }
  }
}
@include minWidth(1700) {
  .tabsContent {
    .header {
      gap: 90px;
    }
  }

  .tabsContentCompleted {
    .header {
      gap: 90px;
    }
  }
}

@include maxWidth(1300) {
  .tabsContentCompleted {
    .orderID {
      min-width: 270px;
    }
    .placedOn {
      min-width: 217px;
    }

    .placedOnCompleted {
      min-width: 217px;
    }
    .buyerName {
      min-width: 149px;
    }
    .orderValue {
      min-width: 134px;
    }
    .actualAmount {
      min-width: 146px;
    }
    .orderItems {
      min-width: 82px;
    }
    .ordersList {
      margin-right: 0px !important;
      padding-right: 10px !important;
    }
  }
}

@include maxWidth(1245) {
  .orderID {
    min-width: 183px !important;
    margin-right: 10px;
  }
  .placedOn {
    min-width: 192px !important;
  }

  .tabsContentCompleted {
    .header {
      width: 933px !important;
    }
    .tabslistContainer {
      width: 933px !important;
    }
    .paginationContainer {
      position: fixed;
      right: 0;
      bottom: 0;
      width: fit-content;
    }
    .orderID {
      min-width: auto;
      margin-right: 10px;
    }
    .placedOn {
      min-width: auto;
    }

    .placedOnCompleted {
      min-width: auto;
    }
    .buyerName {
      min-width: auto;
      width: 90% !important;
    }
    .orderValue {
      min-width: auto;
    }
    .actualAmount {
      min-width: auto;
    }
    .orderItems {
      width: 100%;
      min-width: auto;
    }
  }
  .searchContainer {
    width: 270px !important;
  }
}

@include maxWidth(1100) {
  .tabList {
    gap: 26px !important;
  }
}
.activeFilter {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border: 0.5px #fff;
  background: #69a04a;
  right: 0px;
  top: 1px;
}
