@import "../../../index.scss";

.businessSetupForm {
  display: flex;
  height: 100%;
  width: 100%;

  .left {
    background-color: $primaryLightGreen;
  }

  .right {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 29px;
    padding-bottom: 40px;
    background-color: $backgroundThemeColor;
    overflow: hidden;

    .actionsOnUser {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 20px;
      float: right;
      // width: 390px;
    }

    .top {
      display: flex;
      justify-content: space-between;

      .greetings {
        padding-top: 11px;

        .user {
          color: $headingColorOD;
          font-family: $UrbanistSemiBold;
          font-size: 24px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          // text-transform: uppercase;
          font-feature-settings: "clig" off, "liga" off;
          padding-bottom: 20px;
        }

        .welcome {
          color: $tertiaryBlack;
          font-family: $UrbanistRegular;
          font-size: 14px;
          font-style: normal;
          line-height: 22px;
          letter-spacing: 0.5px;
          max-width: 645px;
          width: 100%;
        }
      }

      .icon {
        height: 117px;
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 40px;
      background-color: $primaryWhite;
      border-radius: 6px;
      height: 100%;
      overflow: auto;
    }

    .form {
      margin-bottom: 15px;
      overflow: auto;
      flex: 1;
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 30px;
      max-height: calc(100% - 40px);

      .businessSetup {
        display: flex;
        flex-direction: column;

        .setup {
          color: $headingColorOD;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          font-family: $UrbanistSemiBold;
          margin-bottom: 5px;
        }

        .content {
          color: $tertiaryBlack;
          font-size: 14px;
          font-style: normal;
          line-height: 22px;
          letter-spacing: 0.5px;
          font-family: $UrbanistRegular;
          margin-bottom: 30px;
        }

        .inputFields {
          display: flex;
          margin-bottom: 20px;
          display: flex;
          margin-bottom: 20px;
          align-items: flex-start;
          justify-content: flex-start;
          flex-wrap: wrap;
          column-gap: 40px;
          row-gap: 15px;
          .inputField {
            min-width: 400px;
            margin-bottom: 15px;
          }

          .marginBottom {
            margin-bottom: 20px;
          }
          .column1,
          .column2 {
            display: flex;
            flex-direction: column;
          }

          .column2 {
            margin-left: 40px;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $scrollBarColor;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: $scrollBarColor;
        border-radius: 10px;
      }
    }

    .action {
      display: flex;
      justify-content: flex-end;
      height: 40px;
      padding-right: 30px;

      .skip {
        all: unset;
        cursor: pointer;
        border-radius: 6px;
        background: $primaryWhite;
        border: 1px solid $primaryPink;
        width: 180px;
        height: 40px;
        color: $primaryPink;
        text-align: center;
        font-family: $UrbanistRegular;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        &:focus {
          outline: 1px solid $primaryPink;
        }
      }

      .submit {
        all: unset;
        cursor: pointer;
        border-radius: 6px;
        background: $primaryPink;
        width: 180px;
        height: 40px;
        color: $primaryWhite;
        font-family: $UrbanistRegular;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
        margin-left: 30px;
        &:focus {
          outline: 1px solid $primaryPink;
        }
      }
    }
  }
}

.label,
.mainLabel {
  color: $teritaryBlack;
  font-family: $UrbanistRegular;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.5px;
}

.uploadContainer {
  margin-top: 14px;
}

.typeOfPayment {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 0 !important;

  .radioBoxContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px !important;

    .radioBox {
      margin-left: -12px;
      display: flex;
      align-items: center;
    }
  }
}

.verifyBox {
  position: relative;

  .verify {
    color: $primaryPink;
    font-family: $UrbanistRegular;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.5px;
    position: absolute;
    top: 38px;
    right: 25px;
    cursor: pointer;
  }
}

.ibanValid {
  display: flex;
  align-items: center;
  margin-top: 4px;
  gap: 4px;

  .heading {
    color: $primaryGreen;
    font-family: $UrbanistSemiBold;
    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.5px;
  }
}

.submit {
  all: unset;
  cursor: pointer;
  border-radius: 6px;
  background: $primaryPink;
  width: 180px;
  height: 40px;
  color: $primaryWhite;
  font-family: $UrbanistRegular;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  margin-left: 30px;
}

.ibanInValid {
  display: flex;
  align-items: center;
  margin-top: 4px;
  gap: 4px;

  .heading {
    color: $secondaryRed;
    font-family: $UrbanistRegular;
    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.5px;
  }
}

@include minWidth(1400) {
  .form {
    .businessSetup {
      justify-content: center;
      margin: auto;

      .inputFields {
        justify-content: space-between;

        .column1,
        .column2 {
          width: 45%;
        }
      }
    }
  }
}

@include maxWidth(1400) {
  .column1,
  .column2 {
    width: 400px;
  }
}

@include maxWidth(1200) {
  .form {
    .businessSetup {
      justify-content: space-between;
      width: 98%;

      .inputFields {
        justify-content: space-between;

        .column1,
        .column2 {
          width: 47%;
        }
      }
    }
  }
}
