@import "../../../index.scss";

.invoiceBlock {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;

  .right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    overflow-y: auto;

    .searchContainer {
      width: 100%;
      margin-top: 24px;
      padding-right: 30px;
    }

    .breadcrumb_nav_block {
      display: flex;
      justify-content: space-between;
      margin: 25px 0 20px 0;
      height: 24px;
      align-items: center;
      padding-right: 40px;
    }

    .navBlock {
      display: flex;
      width: 30%;
      justify-content: flex-end;
      gap: 30px;
      position: relative;
    }

    .downloadInvoice {
      margin-right: 30px;
    }

    .contentContainer {
      // padding-bottom: 50px;
      overflow-x: hidden;
      overflow-y: auto;
      padding-right: 10px;
      margin-right: 23px;
      height: 100%;

      .invoiceDetailsBlock {
        background-color: $primaryWhite;
        border-radius: 6px;
      }

      .productCards {
        margin-top: 50px;
        .heading {
          color: $tertiaryGray1;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          font-family: $UrbanistBold;
        }

        .cards {
          display: flex;
          gap: 30px;
          column-gap: 30px;
          row-gap: 30px;
          background-color: $primaryWhite;
          padding: 30px;
          flex-wrap: wrap;
          margin-top: 10px;
        }
      }
      &::-webkit-scrollbar {
        width: 6px !important;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $scrollBarColor;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: $scrollBarColor;
        border-radius: 10px;
      }
    }
    .overridecontentContainer {
      height: calc(100% - 100px);
    }
  }
}

@include maxWidth(1365) {
  .contentContainer {
    margin-right: 30px !important;
    padding-right: 5px !important;
  }

  .cards {
    column-gap: 12px !important;
    padding: 20px !important;
  }
}

@include maxWidth(1100) {
  .cards {
    column-gap: 11px !important;
    padding: 20px !important;
  }
}

@include minWidth(1400) {
  .cards {
    column-gap: 50px !important;
  }
}

@include rangeWidth(1450, 1530) {
  .cards {
    column-gap: 70px !important;
  }
}

@include minWidth(1530) {
  .cards {
    column-gap: 80px !important;
  }
}

@include minWidth(1550) {
  .cards {
    column-gap: 80px !important;
  }
}
