@import "../../../../index.scss";

.ParentContainer {
  display: flex;
  gap: 40px;
  padding-left: 40px;
  height: 100%;
  .container {
    // margin-top: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    .heading {
      padding-top: 30px;
      padding-bottom: 20px;
      color: $tertiaryGray1;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistSemiBold;
    }

    .tabs {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      height: 34px;

      .tabsStyle {
        display: flex;
        flex-direction: column;
        flex: 1;

        .tabList {
          display: flex;
          gap: 40px;
          flex: 1;
          padding-bottom: 6px;

          .tabListItem {
            cursor: pointer;

            .label {
              color: $tertiaryGray1;
              font-family: $UrbanistRegular;
              font-size: 14px;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.5px;
            }

            .activeLabel {
              color: $primaryPink;
              font-size: 14px;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.5px;
              font-family: $UrbanistSemiBold;
            }

            .border {
              border-top: 3px solid $primaryPink;
              border-radius: 4px;
              position: relative;
              bottom: -4px;
              z-index: 1;
            }
          }
        }

        .hr {
          border-bottom: 1px solid $tertiaryGray3;
          position: relative;
          top: -4px;
        }
      }

      .actions {
        display: flex;
        margin-left: 20px;
        gap: 30px;
        align-items: center;

        .icon {
          height: 24px;
          cursor: pointer;
        }

        .search {
          .searchContainer {
            display: flex;
            justify-content: space-between;
            width: 516px;
            background-color: $primaryWhite;
            border: 1px solid $primaryWhite;
            border-radius: 40px;
            align-items: center;
            padding-top: 3px;
            padding-bottom: 3px;
            padding-right: 6px;
            padding-left: 10px;

            .searchIconInput {
              display: flex;
              align-items: center;
              width: 100%;

              .searchInput {
                height: 24px;
                padding-left: 10px;
                display: flex;
                flex: 1;
                padding-right: 10px;
              }
            }

            .cancel {
              cursor: pointer;
              height: 24px;
            }

            &:hover {
              border-color: $primaryPink;
            }
          }

          .searchIcon {
            height: 24px;
            cursor: pointer;
          }
        }

        .sort {
          position: relative;

          .sortList {
            background-color: $primaryWhite;
            border-radius: 6px;
            padding-top: 20px;
            position: absolute;
            top: 39px;
            z-index: 1;
            right: -26px;
            width: 251px;
            display: flex;
            flex-direction: column;
            box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);

            .sortBy {
              color: $teritaryBlack;
              font-size: 14px;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.5px;
              font-family: $UrbanistSemiBold;
              padding-left: 20px;
              padding-right: 20px;
              padding-bottom: 10px;
            }

            .sortOption {
              color: $teritaryBlack;
              font-size: 14px;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.5px;
              font-family: $UrbanistRegular;
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 10px;
              padding-bottom: 10px;

              &:hover {
                background-color: $tertiaryGray9;
              }

              &:active {
                color: $primaryPink;
              }
            }

            .lastOption {
              margin-bottom: 10px;
            }

            .selectedOpt {
              color: $primaryPink;
            }

            &::after {
              content: " ";
              position: absolute;
              right: 25px;
              border-width: 16px;
              top: -31px;
              border-style: solid;
              border-color: transparent transparent $primaryWhite transparent;
            }
          }
        }
      }

      .actions2 {
        position: relative;
        top: 8px;
        height: 32px;
      }

      .addNew {
        margin-left: 30px;
        position: relative;
        top: 6px;
      }
    }

    .tabsContent {
      .tabslistContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        overflow: hidden;

        .ordersList {
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-top: 15px;
          height: calc(100vh - 250px);
          overflow-y: auto;
          padding-right: 5px;
          &::-webkit-scrollbar {
            width: 6px;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px $scrollBarColor;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: $scrollBarColor;
            border-radius: 10px;
          }
        }

        .paginationContainer {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
        }
      }
    }
    .emptyContainer {
      display: flex;
      flex: 1;
      margin-top: 20px;
      margin-bottom: 50px;
    }
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;

  .filters {
    height: 100%;
    width: 460px;
    background-color: $primaryWhite;
    position: absolute;
    top: 0px;
    right: 0px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 18px;
    padding-bottom: 30px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 18px;
      border-bottom: 1px solid $tertiaryGray2;

      .filtersHeading {
        color: $teritaryBlack;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistSemiBold;
      }

      .cancelFilter {
        height: 24px;
        cursor: pointer;
      }
    }

    .form {
      display: flex;
      flex-direction: column;

      .formInputs {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .payment {
          padding-top: 28px;

          .paymentLabel {
            color: $tertiaryGray1;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistSemiBold;
            padding-bottom: 20px;
          }

          .paymentInput {
            display: flex;
            gap: 30px;

            .paid {
              display: flex;
              align-items: center;

              .paidLabel {
                color: $tertiaryGray1;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                font-family: $UrbanistRegular;
                padding-left: 10px;
                min-width: 100px;
              }
            }
          }
        }
      }

      .filterActions {
        position: absolute;
        bottom: 30px;
        padding-right: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        gap: 30px;

        .reset {
          color: $primaryPink;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          font-family: $UrbanistRegular;
          cursor: pointer;
        }
      }
    }

    .commonLabel {
      color: $teritaryBlack;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistRegular;
    }
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.details {
  color: #949494;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
.headerColor {
  width: 23%;
  min-width: 23%;
  max-width: 23%;
}

.productName {
  width: 25%;
  min-width: 25%;
  max-width: 25%;
}

.businessName {
  width: 18.5%;
  min-width: 18.5%;
  max-width: 18.5%;
}
.contactName {
  width: 16.5%;
  min-width: 16.5%;
  max-width: 16.5%;
}
.email {
  width: 11%;
  min-width: 11%;
  max-width: 11%;
}
.orderingUnit {
  width: 11%;
  min-width: 11%;
  max-width: 11%;
}
.address {
  width: 16%;
  min-width: 16%;
  max-width: 16%;
}

.tableHeader {
  padding: 0 !important;
}

.paid {
  gap: 10px;
}

.productNameAndCheckbox {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.btns {
  display: flex;
  align-self: end;
  flex: 1;
  gap: 20px;
  align-items: end;
  margin-right: 30px;
  margin-bottom: 30px;

  .editBtn {
    width: 120px;
  }
  .titleAndButtonSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
