@import "../../../../index.scss";

.referAFriendParent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .container {
        width:100%;
        background-color: $primaryWhite;
        padding: 30px 37px;
        border-radius: 6px;
        position: relative;  
    


        .header {
            display: flex;
            flex-direction: row;
            column-gap: 20px;

            .heading {
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                color: $teritaryBlack;
            }

            .subHeading {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                /* 142.857% */
                letter-spacing: 0.5px;
                color: $teritaryBlack;
                margin-top: 8px;

                .bolder {
                    font-weight: 600px;
                }
            }

        }

        .formContainer {
            margin-top: 40px;
            display: flex;
            flex-direction: column;

            .formRow {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 37px;

                .InputFieldContainer {
                    width: 48%;
                }

                .buttonsConatiner {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .radioButton {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 47%;
                        height: 40px;
                        border: 1px solid $tertiaryGray2;
                        border-radius: 6px;
                    }

                    .errorMessage{
                        font-family: $UrbanistItalic;
                        font-size: 12px;
                        letter-spacing: 0.5px;
                        color: $secondaryRed;
                        margin-top: 10px;
                    }

                }

                .ButtonFieldLabel {
                    font-size: 14px;
                    font-style: normal;
                    letter-spacing: 0.7px;
                    color: $teritaryBlack;
                    margin-bottom: 6px;
                }
            }

            .submitButton{
                width:100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .submit {
                    all: unset;
                    cursor: pointer;
                    border-radius: 6px;
                    background: $primaryPink;
                    width: 180px;
                    height: 40px;
                    color: $primaryWhite;
                    font-family: $UrbanistRegular;
                    font-size: 14px;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-align: center;
                    margin-left: 30px;
                }
            }

            
        }

        .close{
            position: absolute;
            right: 30px;
            top:18px;
            cursor: pointer;
        }

        .upperDesign{
            position: absolute;
            right:30px;
            top:0;
            
        }

        .lowerDesign{
            position: absolute;
            left:0;
            bottom:0;
        }

    }

}