@import '../../../../index.scss';

.editProfilrConatiner {
    height: 100%;
    width: 460px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .formContainer {
        flex: 1;
        margin-top: 30px;
        display: flex;
        flex-direction: column;


        form {
            display: flex;
            flex-direction: column;
            gap: 30px;
            flex: 1;
        }

        .buttonConatiner {
            width: 120px;
            // height: 30px;
            align-self: flex-end;
        }
    }
}