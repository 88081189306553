@import "../.././../index.scss";

.card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $primaryWhite;
  width: 100%;
  border-radius: 6px;
  padding: 25px 20px;
  border: 1px solid $primaryWhite;
  cursor: pointer;
  height: 76px;

  .product {
    display: flex;
    align-items: center;
    width: 27%;
    min-width: 27%;
    max-width: 27%;
    margin-right: 2%;

    .productImgContainer {
      height: 44px;
      // width: 44px;
      min-width: 44px;
      max-width: 44px;
      margin-right: 16px;
      .productImg {
        height: 100%;
        width: 100%;
        border: 1px solid $tertiaryGray3;
        border-radius: 4px;
        object-fit: contain;
        box-sizing: border-box;

        &.deactivateImg {
          filter: grayscale(1);
        }
      }
    }

    .productName {
      color: $tertiaryGray10;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistRegular;
      margin-right: 6px;
      max-width: 210px;
      // width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 24px;
      white-space: nowrap;
    }

    .active {
      color: $activeColor;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistRegular;
      // padding-left: 10px;
      // padding-right: 10px;
      background-color: $secondaryThickGreen;
      border-radius: 40px;
      height: 10px;
      width: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
    }

    .inactiveMark {
      color: $tertiaryGray1;
      background-color: $inactivebgColor;
      height: 10px;
      width: 10px;
    }
  }

  .businessName {
    width: 18%;
    min-width: 18%;
    max-width: 18%;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    font-family: "UrbanistRegular";
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    // margin-right: 3%;
    color: $tertiaryGrays2;
    font-size: 14px;
  }

  .activeProduct {
    width: 27%;
    min-width: 27%;
    max-width: 27%;
    margin-right: 2%;
  }

  .productId {
    width: 13%;
    width: 13%;
    min-width: 13%;
  }

  .quantity {
    width: 5%;
    min-width: 5%;
    max-width: 5%;
  }

  .brandName {
    width: 12%;
    min-width: 12%;
    max-width: 12%;
  }

  .country {
    display: flex;
    align-items: center;
    width: 12%;
    min-width: 12%;
    max-width: 12%;

    .countryFlag {
      margin-right: 5px;
      height: 15px;
    }
  }

  .countryOfOrigin {
    max-width: 9%;
    min-width: 9%;
    width: 9%;
  }

  .productType {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: flex-start;
    width: 14%;
    max-width: 14%;
    min-width: 14%;
    margin-left: 1.5%;

    // .separator {
    //     margin-left: 8px;
    // }

    .productCategory {
      color: $tertiaryGray2;
      font-size: 14px;
      font-style: italic;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistItalic;
      // margin-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }

  .textStyle {
    color: $textColor;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    font-family: $UrbanistRegular;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 2%;
  }
  .textStyle2 {
    width: max-content;
    max-width:100% ;
  }
  .textStyle3 {
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    font-family: $UrbanistRegular;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }

  &:hover {
    border: 1px solid $primaryPink;
    filter: drop-shadow(0px 0px 24px rgba(236, 24, 123, 0.15));
  }
}

.inactive {
  color: rgba(44, 47, 53, 0.28) !important;
}

.outOfStock {
  background-color: $tertiaryGray12;
  border: 1px solid $tertiaryGray3;
}

.cancelDelete {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
