@import "../../../../../index.scss";
.popup {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .cards {
        display: flex;
        gap: 30px;
    }
    .helpContainer {
        display: flex;
        align-items: center;
        .helpIcon {
            margin-right: 6px;
        }
        .helpContent {
            color: $tertiaryGray1;
            font-size: 14px;
            font-style: normal;
            line-height: 20px;
            letter-spacing: 0.07px;
            font-family: $UrbanistRegular;
            margin-bottom: 6px;
        }
        .helpContact {
            color: $tertiaryGray1;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistSemiBold;
        }
    }
}