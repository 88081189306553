@import "../../../index.scss";
.approvalPage {
    display: flex;
    height: 100%;
    width: 100%;
    .right {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 40px;
        .heading {
            padding-top: 30px;
            padding-bottom: 20px;
            color: $tertiaryGray1;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistSemiBold;
        }
        .orders {
            display: flex;
            flex:1;
        }
    }
}