@import "../../../common//styles/variables.scss";

.actions {
    margin-left: auto;
    position: relative;
}

.actionItems {
    background-color: $primaryWhite;
    border-radius: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    width: 251px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);


    .option {
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        position: relative;
        z-index: 6;

        .label {
            padding-left: 10px;
            color: $tertiaryBlack;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistRegular;
        }

        &:hover {
            background-color: $tertiaryGray9;
        }
    }
}