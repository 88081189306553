@import "../../../index.scss";

.app {
  display: flex;
  height: 100%;

  .outerContainer {
    margin: 20px 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;

    .searchContainer {
      width: 100%;
    }

    .showEdit {
      display: block !important;
    }

    .hideEdit {
      display: none;
    }
  }

  .editButtonContainer {
    width: 100%;
    height: 40px;
    margin-bottom: 11px;
    margin-top: 20px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .attributesWrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .horizontalLine {
    width: 100%;
    height: 1px;
    background-color: $tertiaryGray3;
    margin: 30px 0;
  }



  .container {
    background-color: $primaryWhite;
    display: flex;
    flex-direction: column;
    padding: 30px 30px 50px 30px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 0px;

    .productDetailsContainer {
      display: flex;
    }

    .imagePreview {
      width: 50%;
    }

    .productDescription {
      width: 50%;
      margin-left: 23px;
      margin-top: 9px;
      margin-bottom: 28px;

      .productName {
        color: $teritaryBlack;
        font-size: 24px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        text-wrap: wrap;
        font-weight: 400;
      }

      .additionalProductDetails {
        margin-top: 43.5px;
      }
    }
    .shareIcon{
      width: 24px;
      height: 24px;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }

    .productInformationGrid {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 0.7fr 1fr;
      grid-row-gap: 16px;
      grid-column-gap: 100px;
      margin-top: 9px;
    }

    .accordionItem {
      display: flex;
      flex-direction: column;
      row-gap: 1px;
      letter-spacing: 0.5px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      * {
        font-size: 14px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h3:nth-child(2) {
        cursor: default;
      }

      .productKey {
        color: $tertiaryGray2;
      }

      .productDescriptionColor {
        color: $teritaryBlack;
      }
    }

    .productTitle {
      color: $tertiaryBlack;
      font-size: 24px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
    }

    .productSubTitle {
      display: flex;
      flex-direction: row;
      gap: 7px;
      margin-top: 5px;
    }

    .subTitle {
      color: $tertiaryGray10;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
    }

    .subTitle__italic {
      color: $tertiaryGray2;
      font-size: 12px;
      font-style: italic;
      line-height: normal;
      letter-spacing: 0.5px;
    }

    .productQuantityGrid {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      column-gap: 20px;
      row-gap: 20px;
      width: 75%;
    }

    .buttonContainer {
      margin-top: 11px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }

    .line {
      border-top: 1px solid $tertiaryGray3;
      width: 232px;
      height: 0px;
    }

    .linkButtons {
      display: flex;
      flex-direction: row;
      column-gap: 13px;
      width: 60%;
      margin-left: 9px;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px $scrollBarColor;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollBarColor;
      border-radius: 10px;
    }
  }
}

.moreAttributesWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  flex-wrap: wrap;
  min-width: 150px;
  max-width: 250px;
  margin-top: 10px;
  padding: 16px 20px;

}

.popperArrow {
  z-index: -1;
}

.popperPaper{
  box-shadow: 0 4px 13px #00000025;
}
.attributesChip{
  border: 1px solid $tertiaryGray3;
  height: 20px;
}

@include maxWidth(1100) {
  .outerContainer {
    margin: 20px 25px !important;
  }
}