@import "../../../../../index.scss";

.emptyContainer {
  width: 100%;
  background-color: $primaryWhite;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 100%;
  margin-top: 10px;

  .woah {
    margin-top: 30px;
    margin-bottom: 4px;
    color: $teritaryBlack;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    font-family: $UrbanistRegular;
  }

  .content {
    padding: auto 24px;
    text-align: center;
    color: $tertiaryGray1;
    font-family: $UrbanistRegular;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.07px;
    text-align: center;
  }
}