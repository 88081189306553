@import "../../../index.scss";
.container {
    margin: 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    .txt {
        color: $teritaryBlack;
        font-family: $UrbanistRegular;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
    }
    .btn {
        max-width: 100px;
        width: 100%;
        display: flex;
        margin-left: auto;
    }
}