@import "../../../index.scss";

.container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2; // before 99 ,in future any issues raised  make it 99 and  fix accordingly notifications z-index
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: calc(100% - 75px);
  margin: 20px auto 0;

  .searchContainer {
    flex: 1;
  }

  .ctaContainer {
    display: flex;
    align-items: center;
    gap: 21px;

    .wishlist,
    .cart {
      img {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .cart {
      position: relative;

      .badge {
        position: absolute;
        top: -1px;
        right: -4px;
        background-color: $primaryPink;
        color: $primaryWhite;
        border-radius: 50%;
        padding: 5px;
        font-size: 12px;
        height: 16px;
        width: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: $UrbanistSemiBold;
        font-size: 12px;
        line-height: 20px;
        border: 1px solid $primaryWhite;
      }
    }
  }
}
