@import "../../../../../common/styles/variables.scss";
.emptyCartWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $primaryWhite;
  padding: 70px;

  .emptyCartText {
    width: 50%;
    color: $tertiaryBlack;
    font-size: 0.875rem;
    letter-spacing: 0.7px;
    margin-top: 4px;
    text-align: center;
  }

  .emptyCartHeading {
    font-size: 1.5rem;
    margin-top: 30px;
  }
  .marketPlaceBtn {
    width: 180px;
    margin-top: 30px;
  }
}
