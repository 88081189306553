@import "../../../../../common/styles/variables.scss";


.labelValueWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    .labelText {
      font-size: 0.875rem;
    }
    .labelValue {
      font-size: 0.875rem;
    }
  }