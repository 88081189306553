@import '../../../../../index.scss';

.overlayParent {
    width: 312px;
    background-color: $primaryWhite;
    padding: 20px;
    position: absolute;
    border-radius: 6px;
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
    top: 30px;
    cursor:auto;
    z-index: 3;


    .InfoContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        border-top: 1px solid $tertiaryGray2;
        padding-top: 12px;
        margin-bottom: 11px;



        .infoDetails {
            height: 100%;
            width: 100%;
            display: inline-flex;
            align-items: center;
            gap: 6px;
            cursor: pointer;

            .imageCOntainer {
                height: 42px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
            }

            .infoData {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;

                .productData {
                    color: $tertiaryBlack;
                    font-family: $UrbanistSemiBold;
                    font-size: 14px;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.5px;
                }

                .productInfo {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 6px;
                    color: $tertiaryBlack;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;

                }

            }
        }

        &:first-child {
            border-top: none;
            padding-top: 0px;

        }

        .delayedText {
            font-size: 12px;
            font-style: italic;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            color:$secondaryRed2;
            white-space: nowrap;
        }
    }

    .overlayFooter {
        display: flex;
        flex: row;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        .viewAllOrder {
            color: $primaryPink;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            white-space: nowrap;
            margin-left: 10px;
            margin-right: 10px;
            cursor:pointer;
        }

        span {
            border-top: 1px solid $tertiaryGray2;
            width: 100%;
        }
    }

    &::after {
        content: " ";
        position: absolute;
        left: 16px;
        border-width: 16px;
        top: -28px;
        border-style: solid;
        border-color: transparent transparent $primaryWhite transparent;
    }

}