@import "./common/styles/common.scss";
@import url("https://cdn.jsdelivr.net/npm/intro.js@7.0.1/minified/introjs.min.css");
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

* {
  font-family: $UrbanistRegular;
}

#root,
.App,
.parent_container,
.content_container {
  height: 100%;
}

.introjs-helperLayer {
  box-shadow: rgba(33, 33, 33, 0.8) 0px 0px 1px -5px,
    rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
  border-radius: 40px 0 0 40px;
}
.introjs-overlay {
  // background: $primaryBlack !important;
  // opacity: 0.6 !important;
}
.intro-highlight-1,
.intro-highlight-12,
.intro-highlight-13,
.intro-highlight-14,
.intro-highlight-15 {
  width: 164px !important;
  left: 5px !important;
  height: 34px !important;
  top: 230px !important;
  border-radius: 40px 0 0 40px !important;

  @include minWidth(1920) {
    top: 22.69vh !important;
  }
}

.intro-highlight-12 {
  top: 280px !important;

  @include minWidth(1920) {
    top: 27.2vh !important;
  }
}

.intro-highlight-13 {
  top: 376px !important;

  @include minWidth(1920) {
    top: 31.67vh !important;
  }
}

.intro-highlight-14 {
  top: 18px !important;
  width: 83.38vw !important;
  left: 14.1vw !important;
  height: 60px !important;
  border-radius: 40px !important;
  margin-top: -4px;
  margin-left: 6px;

  @include maxWidth(1280) {
    width: 82.2vw !important;
    left: 15.1vw !important;
  }

  @include maxWidth(1024) {
    width: 77.6vw !important;
    left: 19vw !important;
  }

  @include minWidth(1440) {
    width: 84.38vw !important;
    left: 190px !important;
  }

  @include minWidth(1536) {
    width: 85.38vw !important;
  }

  @include minWidth(1920) {
    left: 13.1vw !important;
  }
}

.intro-highlight-15 {
  top: 424px !important;

  @include minWidth(1920) {
    top: 36.02vh !important;
  }
}

.intro-highlight-1 + .introjs-tooltipReferenceLayer .introjs-tooltip {
  top: -160px !important;
  left: -39.5vw !important;
  margin-left: unset !important;
  margin-top: unset !important;
  min-width: 383px;

  @include minWidth(1024) {
    top: -22.5vh !important;
  }

  @include minWidth(1440) {
    left: -41.5vw !important;
    top: -27vh !important;
  }

  @include minWidth(1536) {
    top: -25.5vh !important;
    left: -41.5vw !important;
  }

  @include minWidth(1920) {
    top: -28.51vh !important;
    left: -41.5vw !important;
  }
}

.intro-highlight-2 + .introjs-tooltipReferenceLayer .introjs-tooltip {
  left: unset !important;
  top: unset !important;
}

.intro-highlight-3 {
  border-radius: 40px !important;
}

.intro-highlight-3 + .introjs-tooltipReferenceLayer .introjs-tooltip {
  left: 51% !important;
  top: 47px !important;
}

.intro-highlight-4 {
  width: 50px !important;
  height: 50px !important;
  border-radius: 100% !important;
}

.intro-highlight-5 {
  width: 50px !important;
  height: 50px !important;
  border-radius: 100% !important;
}

.intro-highlight-4 + .introjs-tooltipReferenceLayer .introjs-tooltip {
  left: unset !important;
  top: 47px !important;
  margin-left: unset !important;
  margin-top: unset !important;
}

.intro-highlight-5 + .introjs-tooltipReferenceLayer .introjs-tooltip {
  left: unset !important;
  top: 47px !important;
}

.intro-highlight-12 + .introjs-tooltipReferenceLayer .introjs-tooltip {
  top: -110px !important;
  left: -39.5vw !important;
  margin-left: unset !important;
  margin-top: unset !important;
  min-width: 383px;
  @include minWidth(1024) {
    top: -16.5vh !important;
  }

  @include minWidth(1440) {
    left: -41.5vw !important;
    top: -21vh !important;
  }

  @include minWidth(1536) {
    top: -20vh !important;
    left: -41.5vw !important;
  }

  @include minWidth(1920) {
    top: -24.5vh !important;
    left: -41.5vw !important;
  }
}

.intro-highlight-13 + .introjs-tooltipReferenceLayer .introjs-tooltip {
  top: -68px !important;
  left: -39.5vw !important;
  margin-left: unset !important;
  margin-top: unset !important;
  min-width: 383px;

  @include minWidth(1024) {
    top: -3.5vh !important;
  }

  @include minWidth(1440) {
    left: -41.5vw !important;
    top: -10.5vh !important;
  }

  @include minWidth(1536) {
    left: -41.5vw !important;
    top: -9.5vh !important;
  }

  @include minWidth(1920) {
    top: -20.5vh !important;
    left: -41.5vw !important;
  }
}

.intro-highlight-14 + .introjs-tooltipReferenceLayer .introjs-tooltip {
  top: -40.2vh !important;
  left: 6.8vw !important;
  margin-left: unset !important;
  margin-top: unset !important;

  @include minWidth(1024) {
    left: 2.8vw !important;
  }

  @include minWidth(1440) {
    top: -41.7vh !important;
  }

  @include minWidth(1536) {
    top: -41.3vh !important;
  }

  @include minWidth(1920) {
    top: -43.2vh !important;
  }
}

.intro-highlight-15 + .introjs-tooltipReferenceLayer .introjs-tooltip {
  top: -20px !important;
  left: -39.5vw !important;
  margin-left: unset !important;
  margin-top: unset !important;
  min-width: 383px;

  @include minWidth(1024) {
    top: 2.5vh !important;
  }

  @include minWidth(1440) {
    left: -41.5vw !important;
    top: -4.35vh !important;
  }

  @include minWidth(1536) {
    left: -41.5vw !important;
    top: -3.35vh !important;
  }

  @include minWidth(1920) {
    top: -15.5vh !important;
    left: -41.5vw !important;
  }
}

.introjs-tooltip {
  background: $primaryWhite;
  border: 2px solid $primaryGreen;
  box-shadow: 0px 0px 24px rgba($primaryBlack, 0.15);
  border-radius: 6px;
  position: absolute;
  min-width: 383px;
  width: 383px;
  padding: 24px 30px 30px;
  box-sizing: border-box;
  // height: 164px !important;
  // max-height: 164px !important;

  .introjs-tooltip-header,
  .introjs-tooltipbuttons,
  .introjs-arrow.top,
  .top-right {
    display: none !important;
  }

  .introjs-tooltiptext {
    padding: 0;
  }

  .welcome-card-details {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      display: flex;
      align-items: center;
      gap: 0px;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.5px;

      span {
        font-family: $UrbanistRegular;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.5px;
        color: $teritaryBlack;
      }
    }

    .desc {
      font-family: $UrbanistRegular;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: $teritaryBlack;
      height: 66px;
      font-weight: 400;
    }
  }
}

.welcome-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  height: 24px;

  .get-started {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      transform: rotate(0deg);
      cursor: pointer;
    }

    img {
      height: 24px;
      width: 24px;
      transform: rotate(90deg);
    }

    h3,
    .of {
      font-family: $UrbanistRegular !important;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.5px;
      color: $tertiaryGray11 !important;
      font-style: italic;
    }

    span {
      font-family: $UrbanistRegular;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: $teritaryBlack;
    }
  }
}

.card-details {
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    align-items: center;
    height: 24px;
    gap: 10px;
    margin-bottom: 20px;

    img {
      height: 24px;
      width: 24px;
    }

    span {
      font-family: $UrbanistSemiBold;
      letter-spacing: 0.5px;
      color: $primaryLightGreen;
      font-size: 14px;
      line-height: 10px;
    }
  }

  .desc {
    height: 54px;
    font-family: $UrbanistRegular;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: $teritaryBlack;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  height: 24px;

  .get-started {
    display: flex;
    align-items: center;
    gap: 10px;

    h3,
    .of {
      font-family: $UrbanistRegular;
      font-size: 12px;
      line-height: 8px;
      letter-spacing: 0.5px;
      // color: rgba($primaryBlack, 0.15);
      color: $tertiaryGray11;
      font-style: italic;
    }

    span {
      font-family: $UrbanistRegular;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: $teritaryBlack;
    }
  }
}

.intro-previous-button {
  // transform: rotate(-90deg);
  cursor: pointer;
}

img.intro-next-button {
  transform: rotate(180deg) !important;
  cursor: pointer;
}

.link-primary {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $primaryPink !important;
}

.dismiss-button {
  cursor: pointer;
  font-family: $UrbanistRegular !important;
  letter-spacing: 0.5px;
  line-height: 17px !important;
}

// report styles
.css-204u17-MuiDataGrid-main {
  padding: 0px 10px !important;
}

.css-1iyq7zh-MuiDataGrid-columnHeaders {
  border-bottom: 1px solid $primaryPink !important;
}

.css-igs3ac {
  border: transparent !important;
}

.css-slyssw {
  margin-right: 0 !important;
}

.css-a477zy-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  margin-top: 2px;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
}

.slick-prev:before,
.slick-next:before {
  color: $primaryPink !important;
  opacity: 1 !important;
}
.paginationContainer .css-eavzvn nav ul li button button {
  cursor: pointer;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select, .css-qiwgdb.MuiSelect-select{
  height: 1px !important;
  white-space: unset !important;
}
.css-zun73v.MuiCheckbox-indeterminate{
  color: $primaryPink !important;
}