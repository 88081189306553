@import "../../../index.scss";

.popupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: relative;

    h2 {
        color: $tertiaryBlack;
        font-family: $UrbanistSemiBold;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
    }

    .closeIcon {
        position: absolute;
        right: 0;
        top: 0;
        color: $primaryPink;
        font-family: $UrbanistSemiBold;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        cursor: pointer;
    }
}