@import "../../../index.scss";

.breadcrumbContainer {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: $breadcrumbColor;
  height: 24px;
  column-gap: 2px;
}

.breadcrumbLink {
  text-decoration: none;
  color: $primaryPink;
  cursor: pointer !important;
  font-family: $UrbanistRegular;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.breadcrumbLabel {
  flex: 1;
  font-family: $UrbanistRegular;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
}

.breadcrumbSeparator {
  // margin: 0 2px;
  color: $tertiaryGray1;
}
