@import '../../../../index.scss';

.wrapper {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 0px 40px;

    .sort {
      position: relative;
      cursor: pointer;

      .selectedSort {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        .content {
          display: flex;
          column-gap: 4px;
          flex-wrap: nowrap;
          padding-bottom: 6px;

          .sortByText {
            color: $tertiaryGray2;
            font-size: 14px;
            font-family: $UrbanistRegular;
            letter-spacing: 0.5px;
          }

          .selected {
            color: $tertiaryGrays2;
            font-size: 14px;
            font-family: $UrbanistRegular;
            letter-spacing: 0.5px;
          }
        }

        .icon {
          width: 24px;
          height: 24px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .sortList {
        background-color: $primaryWhite;
        border-radius: 6px;
        padding-top: 20px;
        position: absolute;
        top: 33px;
        z-index: 1;
        right: -30px;
        width: 320px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);

        .sortBy {
          color: $teritaryBlack;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          font-family: $UrbanistSemiBold;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 10px;
        }

        .sortOption {
          color: $teritaryBlack;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          font-family: $UrbanistRegular;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
          cursor: pointer;

          &:hover {
            background-color: $tertiaryGray9;
          }

          &:active {
            color: $primaryPink;
          }
        }

        .lastOption {
          margin-bottom: 10px;
        }

        .selectedOpt {
          color: $primaryPink;
        }

        &::after {
          content: "";
          position: absolute;
          right: 25px;
          border-width: 16px;
          top: -31px;
          border-style: solid;
          border-color: transparent transparent $primaryWhite transparent;
        }
      }
    }
  }

  .subCategoriesList {
    background-color: $primaryWhite;
    border-radius: 6px;
    border: 1px solid $primaryWhite;
    margin-top: 20px;

    .container {
      background-color: $primaryWhite;
      padding: 30px;
      display: flex;
      flex-wrap: nowrap;
      column-gap: 30px;
      overflow-y: hidden;
      overflow-x: auto;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $scrollBarColor;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: $scrollBarColor;
        border-radius: 10px;
      }
    }
  }

  .contentWrapper {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100% - 24px);
    padding: 0px 40px 50px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px $scrollBarColor;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollBarColor;
      border-radius: 10px;
    }

    .filtersAndProductsWrapper {
      display: flex;
      column-gap: 40px;
      flex-wrap: nowrap;
      margin-top: 50px;

      .filters {
        min-width: 219px;
        max-width: 219px;
      }

      .subSubCategoryProducts {
        display: flex;
        flex-direction: column;
        row-gap: 42px;
        flex: 1;

        .productsSection {
          display: flex;
          row-gap: 11px;
          flex-direction: column;

          .categoryHeader {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            .categoryName {
              color: $tertiaryGray1;
              font-family: $UrbanistSemiBold;
              font-size: 14px;
              letter-spacing: 0.5px;
            }

            .viewAll {
              text-decoration: none;
            }
          }

          .products {
            display: flex;
            column-gap: 30px;
            @include maxWidth(1280){
              column-gap: 25px;
            }
            @include maxWidth(1536){
              column-gap: 20px;
            }
          }
        }
      }
    }
  }

  .emptyWrapper {
    display: flex;
    flex-direction: column;
    padding: 0px 40px 50px;
    flex: 1;

    .emptyContainer {
      width: 100%;
      height: 100%;
      flex: 1;
    }
  }

}
