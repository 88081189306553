@import "../../../index.scss";

@import "../../../index.scss";

.parentContainer {
  width: 100%;
  flex-shrink: 0;
  background-color: $primaryWhite;
  padding: 30px;
  border-radius: 6px;

  .cardParent {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 25px;

    .buyerCard {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 40px;
      cursor: pointer;

      .progressBarContainer {
        // margin-left: 15px;
        // margin-right: 20px;
        flex-grow: 1;

        .progressBarInfo {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .buyerName {
            color: $tertiaryGray1;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 8px;
            /* 142.857% */
            letter-spacing: 0.07px;
          }

          .totalAED {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 6px;
            margin-right: 4px;
            color: $tertiaryGray1;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
          }
        }

        .progressBar {
          border-radius: 6px;
          overflow: hidden;

          progress {
            border-radius: 6px !important;
          }
        }
      }

      .requiredSKU {
        color: $tertiaryGray1;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.07px;
        white-space: nowrap;
        align-self: flex-end;
        margin-left: 20px;
      }
    }
  }

  .footerContainer {
    width: 100%;
    height: 60px;
    background-color: $primaryWhite;
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 16px;
    gap: 15px;

    .footerIcon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
    }

    .footerText {
      color: $tertiaryGray1;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 142.857% */
      letter-spacing: 0.07px;
    }
  }
}

.progressBarStyles {
  max-width: 100% !important;
  margin-right: 0px !important;
}

.customBarColor {
  color: $secondaryGold !important;
}
