@import '../../../index.scss';

.container {
    background: $primaryWhite;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
    max-width: 918px;
    width: 918px;
    // height: 471px;
    z-index: 9;
    position: relative;
    left: 100px;
    border-radius: 6px;
    padding: 16px 20px;

    .filterHeader {
        display: flex;
        align-items: center;

        .searchContainer {
            display: flex;
            align-items: center;
            border-radius: 4px;
            border: 1px solid $tertiaryGray2;
            background: $primaryWhite;
            margin-right: 36px;

            input {
                color: $tertiaryBlack3;
                font-family: $UrbanistRegular;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.07px;
                padding: 5px;
            }
        }

        .alphabet {
            margin: 0 5px;
            cursor: pointer;
        }

        .close {
            cursor: pointer;
            width: 100%;
            text-align: end;
        }
    }

    .filterBodyContainer {
        padding: 20px 0;

        .filterBody {
            display: flex;
            column-gap: 50px;
            row-gap: 20px;
            flex-direction: column;
            flex-wrap: wrap;
            max-height: 471px;
            overflow: auto;
            &::-webkit-scrollbar {
                height: 4px;
            }
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px $scrollBarColor;
                border-radius: 11px;
            }
            &::-webkit-scrollbar-thumb {
                background: $primaryPink;
                border-radius: 11px;
            }
            .supplierSection {

                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                max-height: 400px;

                .supplier {
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    width: 200px;


                    .supplierName {
                        margin: 0 3px 0 10px;
                        color: $primaryBlack;
                        font-family: $UrbanistRegular;
                        font-size: 14px;
                        line-height: normal;
                        letter-spacing: 0.5px;

                    }


                }
            }
        }
    }
}

.grayedOut {
    opacity: 0.2;
}