@import "../../../../index.scss";

.businessSetupForm {
    display: flex;
    height: 100%;
    width: 100%;

    .left {
        background-color: $primaryLightGreen;
    }

    .right {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 29px;
        padding-bottom: 40px;
        background-color: $backgroundThemeColor;
        overflow: hidden;

        .breadCrumbContainer {
            margin-bottom: 20px;
        }

        .top {
            display: flex;
            justify-content: space-between;

            .greetings {
                padding-top: 11px;

                .user {
                    color: $headingColorOD;
                    font-family: $UrbanistSemiBold;
                    font-size: 24px;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    font-feature-settings: 'clig' off, 'liga' off;
                    margin-bottom: 14px;
                }

                .welcome {
                    color: $tertiaryBlack;
                    font-family: $UrbanistRegular;
                    font-size: 14px;
                    font-style: normal;
                    line-height: 22px;
                    letter-spacing: 0.5px;
                    max-width: 645px;
                    width: 100%;
                }
            }

            .icon {
                height: 117px;
            }
        }

        .bottom {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 30px;
            padding-left: 30px;
            // padding-right: 30px;
            padding-bottom: 40px;
            background-color: $primaryWhite;
            border-radius: 6px;
            height: 100%;
            overflow: auto;
        }

        .form {
            margin-bottom: 30px;
            overflow: auto;
            flex: 1;
            max-height: calc(100% - 40px);

            .businessSetup {
                display: flex;
                flex-direction: column;

                .setup {
                    color: $headingColorOD;
                    font-size: 14px;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    font-family: $UrbanistSemiBold;
                    margin-bottom: 5px;
                }

                .content {
                    color: $tertiaryBlack;
                    font-size: 14px;
                    font-style: normal;
                    line-height: 22px;
                    letter-spacing: 0.5px;
                    font-family: $UrbanistRegular;
                    margin-bottom: 30px;
                }

                .inputFields {
                    display: flex;
                    margin-bottom: 20px;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    column-gap: 40px;
                    row-gap: 15px;
                    .inputField {
                        width: 400px;
                        margin-bottom: 15px;
                    }

                    .marginBottom {
                        margin-bottom: 20px;
                    }
                    .column1,
                    .column2 {
                        display: flex;
                        flex-direction: column;
                    }

                    .column2 {
                        margin-left: 40px;
                    }
                }
                .defOutlet {
                    margin-bottom: 58px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 32px;
    
                    .checkbox label {
                        font-size: 16px;
                        line-height: 14px;
                        color: $tertiaryGray1;
                        margin-left: 8px;
                    }
    
                    .checkbox {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        cursor: pointer;
    
                    }
    
                    ::ng-deep .p-checkbox {
                        width: 20px !important;
                        height: 20px !important;
                        display: flex;
    
                        .p-checkbox-box {
                            width: 20px !important;
                            height: 20px !important;
                            border: 1px solid $tertiaryGray4;
                        }
    
                        .p-checkbox-box.p-highlight {
                            background: $primaryPink  !important;
                            border-radius: 2px !important;
                            border: none !important;
                        }
    
                        .p-checkbox-box.p-focus {
                            box-shadow: none !important;
                        }
                    }
                }
            }
            .businessSetup2{
                height: 20px;
                // background-color: aquamarine;
            }

            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px $scrollBarColor;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background: $scrollBarColor;
                border-radius: 10px;
            }
        }

        .action {
            display: flex;
            justify-content: flex-end;
            height: 40px;
            padding-right: 30px;

            .skip {
                all: unset;
                cursor: pointer;
                border-radius: 6px;
                background: $primaryWhite;
                border: 1px solid $primaryPink;
                width: 180px;
                height: 40px;
                color: $primaryPink;
                text-align: center;
                font-family: $UrbanistRegular;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
            }

            .submit {
                all: unset;
                cursor: pointer;
                border-radius: 6px;
                background: $primaryPink;
                width: 180px;
                height: 40px;
                color: $primaryWhite;
                font-family: $UrbanistRegular;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                text-align: center;
                margin-left: 30px;
            }
        }
        .action2 {
            display: flex;
            justify-content: flex-start;
            height: 40px;
            padding-right: 30px;
            .submit {
                all: unset;
                cursor: pointer;
                border-radius: 6px;
                background: $primaryPink;
                width: 180px;
                height: 40px;
                color: $primaryWhite;
                font-family: $UrbanistRegular;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                text-align: center;
                // margin-left: 30px;
            }
        }
    }
}

.label,
.mainLabel {
    color: $tertiaryBlack;
    font-family: $UrbanistRegular;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.5px;
}

.uploadContainer {
    margin-top: 14px;
}

.typeOfPayment {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 0 !important;

    .radioBoxContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px !important;

        .radioBox {
            margin-left: -12px;
            display: flex;
            align-items: center;
            color: $teritaryBlack2;
            font-size: 14px;
            letter-spacing: 0.5px;
        }
    }
}

.verifyBox {
    position: relative;

    .verify {
        color: $primaryPink;
        font-size: 14px;
        line-height: normal;
        letter-spacing: 0.5px;
        position: absolute;
        top: 38px;
        right: 25px;
        cursor: pointer;
    }
}

.ibanValid {
    display: flex;
    align-items: center;
    margin-top: 4px;
    gap: 4px;

    .heading {
        color: $primaryGreen;
        font-family: $UrbanistSemiBold;
        font-size: 12px;
        line-height: normal;
        letter-spacing: 0.5px;
    }

}

.ibanInValid {
    display: flex;
    align-items: center;
    margin-top: 4px;
    gap: 4px;

    .heading {
        color: $secondaryRed;
        font-family: $UrbanistRegular;
        font-size: 12px;
        line-height: normal;
        letter-spacing: 0.5px;
    }

}


.errorMessage {
    color: $tertiaryRed;
    font-size: 12px;
    font-style: italic;
    line-height: normal;
    letter-spacing: 0.5px;
    font-family: $UrbanistRegular;
    width: 400px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 15px;
}


@include minWidth(1400) {
    .form {
        .businessSetup {
            justify-content: center;
            // width: 90%;
            margin: auto;
            padding-right: 30px;

            .inputFields {
                justify-content: space-between;

                .column1,
                .column2 {
                    width: 45%;
                }
            }
        }

        .deliveryAddress {
            margin-top: 15px;
        }

    }
}


@include maxWidth(1400) {

    .column1,
    .column2 {
        width: 400px;
    }
}



@include maxWidth(1200) {
    .form {
        .businessSetup {
            justify-content: space-between;
            width: 98%;

            .inputFields {
                justify-content: space-between;

                .column1,
                .column2 {
                    width: 47%;
                }
            }
        }


    }
}
.action3{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    .skip {
        all: unset;
        cursor: pointer;
        border-radius: 6px;
        background: $primaryWhite;
        border: 1px solid $primaryPink;
        width: 180px;
        height: 40px;
        color: $primaryPink;
        text-align: center;
        font-family: $UrbanistRegular;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
    }

    .submit {
        all: unset;
        cursor: pointer;
        border-radius: 6px;
        background: $primaryPink;
        width: 180px;
        height: 40px;
        color: $primaryWhite;
        font-family: $UrbanistRegular;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
    }
}