@import "../../../index.scss";

.buyerDetailsContainer {
  width: 100%;
  flex-shrink: 0;
  background: $primaryWhite;
  padding: 16px 30px 30px 30px;
  margin-bottom: 46px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 4px;
  gap: 10px;
  .detailsContainer{
    max-width:98%;
     min-width: 35%;
  .invoice {
    margin-top: 14px;
    margin-bottom: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    .invoiceNo {
      color: $headingColorOD;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistSemiBold;
    }
    .paid {
      color: $paidColor;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistRegular;
      border-radius: 40px;
      background: $paidBackgroundColor2;
      height: 20px;
      padding: 5px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .unpaid {
      color: $tertiaryGray1;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistRegular;
      border-radius: 40px;
      background: $unpaidBackgroundColor;
      height: 20px;
      padding: 5px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .productInformationGrid {
    align-self: flex-start;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: clamp(0px, 43%, 43%) clamp(0px, 43%, 43%);;
    grid-row-gap: 20px;
    margin-top: 14px;
    grid-column-gap: 50px;
    .accordionItem {
      display: flex;
      flex-direction: column;
      row-gap: 1px;
      letter-spacing: 0.5px;
      font-size: 14px;
      .productKey {
        color: $tertiaryGray2;
      }
      .productDescriptionColor {
        color: $primaryBlack;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
        overflow-wrap: break-word;
      }
    }
  }
}
}

.statusCardContainer {
  width: 317px;
  height: 317px;
  flex-shrink: 0;
  background-color: $primaryWhite;
  box-shadow: 0px 0px 24px rgba($primaryBlack, 0.15);
  border-radius: 4px;
}

@include minWidth(1900) {
  .buyerDetailsContainer {
    justify-content: space-between;
    gap: 50px;
    .productInformationGrid {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 100px;
    }
  }
}

.adminBuyerDetails{
  @include minWidth(1280){
    padding-left: 15px;
  }
}
.preLine{
  white-space: pre-line;
}
