@import '../../../../index.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .headingContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .heading {
            color: $tertiaryGray1;
            font-family: $UrbanistSemiBold;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
        }

    }

    .cardContainer {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        gap: 29px;
        background-color: $primaryWhite;
        border-radius: 6px;
        padding: 30px;
        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px $scrollBarColor;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: $scrollBarColor;
            border-radius: 10px;
        }
    }
}