@import "../../../index.scss";

.notificationBody {
  margin-left: 169px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.3);
  z-index: 100;
  position: absolute;
  top: 0;
  padding-bottom: 37px;
  height: 100vh;
  width: 480px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  // &::before{
  //   position: absolute;
  //   content: '';
  //   top: 50%;
  //   width: 0;  
  //   height: 0;  
  //   border-left: 30px solid red;
  //   border-right: 30px solid transparent;
  //   border-bottom: 30px solid transparent;
  //   transform: rotate(315deg);
  //   left: -3%;
  //   z-index: 999 !important;
  // }
  &::-webkit-scrollbar {
    width: 0px;
  }
  .unreadNotificationBlock {
    padding: 0px 20px 0px 20px;
    background-color: #eef2f4;
    padding-top: 30px;
    cursor: pointer;
    .notificationDesc {
      display: flex;
      gap: 6px;
      padding-bottom: 12px;
      .descriptionText {
        font-weight: 600;
      }
    }
    .notiificationTimeline {
      display: flex;
      gap: 6px;
      align-items: end;
      .timelineTime {
        color: #949494;
        font-weight: 400;
        font-style: italic;
        line-height: 16px;
        font-size: 14px;
        position: relative;
        top: 6.5px;
      }
      .timelineBorder {
        flex-grow: 1;
        height: 1px;
        background-color: #b9b9b9;
      }
    }
  }
  .unreadNotificationBlock:hover {
    background-color: #ffeded;
  }
  .readNotificationBlock {
    padding: 0px 20px 0px 20px;
    background-color: #fff;
    padding-top: 30px;
    cursor: pointer;
    .notificationDesc {
      display: flex;
      gap: 6px;
      padding-bottom: 12px;
      .descriptionText {
        font-weight: 600;
      }
      .readText {
        font-weight: 400;
      }
    }
    .notiificationTimeline {
      display: flex;
      gap: 6px;
      align-items: end;
      .timelineTime {
        color: #949494;
        font-weight: 400;
        font-style: italic;
        line-height: 16px;
        font-size: 14px;
        position: relative;
        top: 6.5px;
      }
      .timelineBorder {
        flex-grow: 1;
        height: 1px;
        background-color: #b9b9b9;
      }
    }
  }
  .readNotificationBlock:hover {
    background-color: #ffeded;
  }
  .noNotifications {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
}
