@import "../../../../src/index.scss";

.fetauredCard {
    display: flex;
    flex-direction: column;
    min-width: 242px;
    height: 212px;
    border-radius: 5px;
    background-color: $primaryWhite;
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));

    .brandImg {
        height: 121px;
        border-bottom: 1px solid $tertiaryGray3;
        text-align: center;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

    }

    .descriptionCard {
        display: flex;
        justify-content: space-between;
        margin-left: 20px;
        margin-right: 20px;

        .description {
            display: flex;
            flex: 1;
            flex-direction: column;
            margin-top: 20px;
            margin-bottom: 17px;
            width: 126px;

            .brand {
                color: $tertiaryGray1;
                font-size: 16px;
                font-style: normal;
                line-height: 24px;
                font-family: $UrbanistMedium;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .products {
                color: $tertiaryGray1;
                font-size: 12px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                font-family: $UrbanistRegular;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                -webkit-line-clamp: 2;
            }
        }

        .offer {
            background-color: $primaryPink;
            border-radius: 0px 0px 5px 5px;
            margin-bottom: 20px;
            padding: 22px 8px 20px 8px;
            line-height: 11px;
            width: 56px;
            height: 71px;

            .offerValue {
                color: $primaryWhite;
                font-family: $UrbanistRegular;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                text-align: center;
            }

            .offerText {
                color: $primaryWhite;
                font-family: $UrbanistRegular;
                font-size: 10px;
                font-style: normal;
                letter-spacing: 0.5px;
                line-height: normal;
                text-align: center;
            }

            #off {
                padding-left: 2px;
            }
        }
    }
}