@import "../../../index.scss";
.ordersListing {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  .right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search {
      padding-top: 18px;
    }
    .heading {
      padding-top: 25px;
      padding-bottom: 15px;
      padding-left: 40px;
      padding-right: 40px;
      color: $tertiaryGray1;
      font-size: 20px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistSemiBold;
    }
    .orders {
      padding-left: 40px;
      padding-right: 40px;
      display: flex;
      flex: 1;
    }
  }
}
