@import '../../../index.scss';

.categoryCardContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 209px;
  transition: all 0.4s ease-in-out;
  border-radius: 6px 6px 0px 0px;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    .cardContainer {
      opacity: 0;
      visibility: hidden;
    }

    .hoverContainer {
      left: 0;
    }
  }
&.marketplaceCard {
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
  // width: 241px;
  height: 110px;
  padding: 30px;
  padding-right: 24px;
}
  .cardContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.4s ease-in-out;

    .imgContainer {
      width: 68.4000015258789px;
      height: 68.4000015258789px;
      top: 10.7998046875px;
      left: 10.80078125px;
      border: 1px;
      &.marketplaceImg {
        width: 50px;
        height: 50px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title {
      color: $tertiaryBlack;
      text-align: left;
      font-family: $UrbanistRegular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0.5px;
      margin-top: 15px;
      &.marketplaceTitle {
        margin-top: 0px;
        text-overflow: ellipsis;
        width: 128px;
        overflow:hidden;
        white-space:nowrap;
      }
    }
  }

  .hoverContainer {
    transition: all 0.4s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 30px;
    position: absolute;
    left: -100%;

    .hoverTitle {
      color: $tertiaryBlack;
      text-align: center;
      font-family: $UrbanistBold;
      font-size: 20px;
      line-height: normal;
      letter-spacing: 0.5px;
    }
  }
}

.searchCard {
  .cardContainer {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 0;

    position: relative;

    .imgContainer {
      margin-bottom: 22px;
      margin-top: 70px;
    }

    .title {
      margin-top: 50px;
    }


  }

  &:hover {
    .cardContainer {
      opacity: 1;
      visibility: visible;
    }

    .hoverContainer {
      left: -100%;
    }
  }
}

.head {
  color: $teritaryBlack;
  font-family: $UrbanistRegular;
  font-size: 24px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  margin-top: 9px; // Add margin to separate head from title
  &.marketplaceHead {
    display: none;
  }
}