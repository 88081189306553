@import "../../../../../../common//styles//variables.scss";
.selectDeliveryTimeWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items:center;
  border: 1px solid $tertiaryGray3;
  padding: 5px;
  color: $tertiaryBlack;
  letter-spacing: 0.5px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;

  .timeText {
    font-size: 0.875rem;
    line-height: 24px;
    min-width: 20%;
  }
}

.timesHovered {
  border: 1px solid $primaryPink;
  box-shadow: 0px 0px 24px 0px rgba(236, 24, 123, 0.15);
}

.timeSelected {
  border: 1px solid $primaryPink;
}
