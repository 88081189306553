@import "../../../../../common/styles/variables.scss";
.reviewCommentWrapper {
  .profileDetailsWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    .profileImg {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 1px solid $tertiaryGray3;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    }
    .profileNameRatingWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 15px;
      .profileNameWrapper {
        font-size: 0.875rem;
        letter-spacing: 0.5px;
        color: $summaryColor;

        .profileName {
          color: $primaryBlack;
          margin-bottom: 4px;
        }
      }
      .individualRatingWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        .ratingIcon {
          margin-bottom: -2px;
        }
      }
    }
  }
  .reviewText {
    color: $summaryColor;
    margin-top: 14px;
    font-size: 0.875rem;
    line-height: 20px;
    letter-spacing: 0.5px;
  }
}
