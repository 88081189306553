@import '../../../index.scss';

.container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid $tertiaryGray2;
    background: $primaryWhite;
    outline: none;
    height: 40px;
    max-width: 400px;
    position: relative;
    top: 2px;

    .individualDateContainer {
        flex: 1;
        .label,
        .error {
            position: absolute;
            top: -25px;
            color: $tertiaryBlack;
            font-family: $UrbanistRegular;
            font-size: 14px;
            line-height: normal;
            letter-spacing: 0.5px;
        }

        .error {
            color: $secondaryRed;

            sup {
                color: $secondaryRed;
                position: absolute;
            }
        }

        .errorMessage {
            color: $secondaryRed;
            font-style: italic;
            font-family: $UrbanistRegular;
            font-size: 14px;
            line-height: normal;
            letter-spacing: 0.5px;
            position: absolute;
            bottom: -20px;
        }
    }

    .seperator {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 20px;
        background-color: $tertiaryGray7;
        border-radius: 4px;
    }
}