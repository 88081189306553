@import "../../../index.scss";

.userswitchContainer {
  height: 22px;
  border-radius: 4px;
  margin: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  pointer-events: none;
  visibility: hidden;//remove this line when the userswitch is needed

  .MuiTabList-root {
    height: 22px;
    border-radius: 6px;
    padding: 0;
    min-width: 65px;
    background: transparent;
    width: 100%;

    .MuiTab-root {
      transition: all ease 0.3s;
      min-height: 20px;
      border: none;
      color: $tertiaryGray1;
      text-align: center;
      font-size: 10px;
      font-family: $UrbanistSemiBold;
      line-height: normal;
      margin: 1px;
      flex: 1;
      border-radius: 4px;

      &:hover {
        background: $primaryWhite;
      }

      &.Joy-selected {
        background: $primaryPink;
        color: $primaryWhite;
      }
    }
    .css-4cae1q-JoyTab-root ::after {
      display: none !important;
    }
    .css-1yhnohm-JoyTab-root ::after {
      display: none !important;
    }
  }
  .selected {
    background-color: $primaryPink !important;
    color: $primaryWhite !important;
  }
}
