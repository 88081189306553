@import "../../../../index.scss";

.container {
  display: flex;
  height: 100%;
  width: 100%;

  .right {
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .search {
      padding-top: 24px;
    }

    .content {
      margin-top: 32px;
      margin-left: 40px;
      margin-right: 20px;
      margin-bottom: 100px;

      .greetings {
        color: $tertiaryGray1;
        font-size: 24px;
        font-style: normal;
        line-height: 27px;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
        margin-bottom: 14px;
      }

      .contentContainer {
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 18px;

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px $scrollBarColor;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: $scrollBarColor;
          border-radius: 10px;
        }
      }

      .introCards {
        display: flex;
        gap: 30px;
      }

      .cards {
        margin-top: 50px;

        .cardHeader {
          display: flex;
          justify-content: space-between;

          .heading {
            color: $tertiaryGray1;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistSemiBold;
            margin-bottom: 11px;
          }

          .viewAll {
            color: $primaryPink;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistRegular;
            cursor: pointer;
          }
        }

        .cardContent {
          display: flex;
          gap: 30px;
          background-color: $primaryWhite;
          border-radius: 6px;
          overflow: hidden;
          padding: 30px;
          height: 327px;
          flex-wrap: nowrap;
        }

        .articleContent {
          display: flex;
          gap: 30px;
          background-color: $primaryWhite;
          border-radius: 6px;
          overflow: hidden;
          padding: 30px;
          height: 393px;
          flex-wrap: nowrap;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 6px !important;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px $scrollBarColor;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollBarColor;
      border-radius: 10px;
    }
  }
}

.viewDashboard {
  color: $primaryPink;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  font-family: $UrbanistRegular;
  cursor: pointer;
  text-align: right;
  margin: 10px 0px;
}

@include minWidth(1400) {
  .introCards {
    justify-content: space-between;
  }

  .cardContent {
    justify-content: space-between;
  }

  .articleContent {
    justify-content: space-between;
  }
}

@include maxWidth(1300) {
  .introCards {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .cardContent {
    justify-content: space-between;
  }

  .articleContent {
    justify-content: space-between;
  }
}

@include maxWidth(1300) {}