@import "../../../index.scss";

.offlineSuppliersWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 30px 40px;
  position: relative;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 20px;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    justify-content: space-between;

    .containerBody {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
      row-gap: 15px;

      .tabs {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 34px;

        .tabsStyle {
          display: flex;
          flex-direction: column;
          flex: 1;

          .tabList {
            display: flex;
            gap: 40px;
            flex: 1;
            padding-bottom: 6px;

            .tabListItem {
              cursor: pointer;

              .label {
                color: $tertiaryGray1;
                font-family: $UrbanistRegular;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
              }

              .activeLabel {
                color: $primaryPink;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                font-family: $UrbanistSemiBold;
              }

              .border {
                border-top: 3px solid $primaryPink;
                border-radius: 4px;
                position: relative;
                bottom: -4px;
                z-index: 1;
              }
            }
          }

          .hr {
            border-bottom: 1px solid $tertiaryGray3;
            position: relative;
            top: -4px;
          }
        }

        .actions {
          display: flex;
          align-items: center;
          margin-left: 20px;
          gap: 30px;

          .icon {
            height: 24px;
            cursor: pointer;
          }

          .sort {
            position: relative;

            .sortList {
              background-color: $primaryWhite;
              border-radius: 6px;
              padding-top: 20px;
              position: absolute;
              top: 39px;
              z-index: 1;
              right: -26px;
              width: 251px;
              display: flex;
              flex-direction: column;
              box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);

              .sortBy {
                color: $teritaryBlack;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                font-family: $UrbanistSemiBold;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 10px;
              }

              .sortOption {
                color: $teritaryBlack;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                font-family: $UrbanistRegular;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 10px;
                padding-bottom: 10px;

                &:hover {
                  background-color: $tertiaryGray9;
                }

                &:active {
                  color: $primaryPink;
                }
              }

              .lastOption {
                margin-bottom: 10px;
              }

              .selectedOpt {
                color: $primaryPink;
              }

              &::after {
                content: " ";
                position: absolute;
                right: 25px;
                border-width: 16px;
                top: -31px;
                border-style: solid;
                border-color: transparent transparent $primaryWhite transparent;
              }
            }

          }
        }
      }

      .tableHeader {
        display: flex;
        padding: 0px 20px;
        width: 100%;
        column-gap: 5px;

        .header {
          font-family: $UrbanistRegular;
          color: $tertiaryGray2;
          font-size: 14px;
          letter-spacing: 0.5px;
        }
      }

      .contentWrapper {
        width: 100%;
        height: 100%;
        flex: 1;
        overflow: auto;
        max-height: calc(100% - 173px);

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px $scrollBarColor;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: $scrollBarColor;
          border-radius: 10px;
        }

        .tabsContent {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
        }
      }

      .emptyContainer {
        width: 100%;
        height: 100%;
        flex: 1;
      }
    }

    .paginationContainer {
      position: absolute;
      right: 40px;
      bottom: 30px;
    }
  }

}