@import "../../../index.scss";
.BlogsAndArticles {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width:100%;
  min-width: 332px;
  max-width: 332px;
  height: 333px;
  background-color: $primaryWhite;
  box-shadow:0px 0px 24px rgba(0, 0, 0, 0.15);
  cursor:pointer;
  .image {
    min-height: 187px;
    & img{
        width:100%;
        height:100%;
        object-fit: cover;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }
  }
  .details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .info {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 17px;
      .title {
        color: $tertiaryGray1;
        font-family: $UrbanistSemiBold;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

      }
      .author {
        color: $tertiaryBlack;
        font-family: $UrbanistRegular;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        padding-top:8px;
      }
    }
    .cardFooter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 19px;
      padding-bottom: 26px;
      padding-top: 27px;
      width:"100%";
      .horizontalRule{
        border-top:1px solid $tertiaryGray2;
        flex: 1;
      }
      .arrow {
        margin-left: 23px;
        height: 18px; 
      }
      .readMore {
        color: $primaryPink;
        font-family: $UrbanistRegular;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        margin-left:10px;
      }
    }
  }
  &:hover{
    .details{
        background-color: $primaryPink;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        box-shadow:0px 0px 24px rgba(0, 0, 0, 0.15);
        .title{
            color:$primaryWhite;
        }
        .author{
            color:$primaryWhite;
        }
        .cardFooter{
            padding-right:28.75px;
            padding-bottom: 25px;
            .horizontalRule{
                border-color:$primaryWhite;
            }
        }
      
    }
  }
}


@include maxWidth(2100){
  .BlogsAndArticles{
      max-width: 370px;
      
  }
}

@include maxWidth(1600){
  .BlogsAndArticles{
      max-width: 280px;
      min-width: 280px;
      
  }
}

@include maxWidth(1500){
  .BlogsAndArticles{
      max-width: 332px;
      min-width: 332px;
      
  }
}

@include maxWidth(1300){
  .BlogsAndArticles{
      max-width: 450px;
      
  }
  .horizontalRule{
    max-width: 320px ;
  }
}

@include maxWidth(1210){
  .BlogsAndArticles{
      max-width: 332px;
      
  }
}








