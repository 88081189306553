.arrow {
    position: absolute;
    width: 1.5em;
    height: 1.5em ;
    box-sizing: border-box;
    z-index: 2;
    &:before {
      content: '';
      margin: auto;
      display: block;
      width: 100%;
      height: 100%;
      background-color: white;
      transform: rotate(45deg);
    }
}