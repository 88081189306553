@import "../../../../../common//styles/variables.scss";
.deliveryAddressTimeWrapper {
  width: 70%;
  .subHeading {
    color: $teritaryBlack;
    font-family: $UrbanistSemiBold;
    font-weight: 600;
  }
  .horizontalLine {
    width: 100%;
    height: 1px;
    background-color: $tertiaryGray3;
    margin: 30px 0;
  }
  .textBtn {
    margin-top: 30px;
    background-color: transparent;
    border: none;
    outline: none;
    color: $primaryPink;
    cursor: pointer;
  }
  .addressListWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    margin-top: 10px;
    flex: 1;
  }

  .selectDeliveryTimeWrapper {
    display: flex;
    gap: 20px;
  }
}
