@import "../../../../../index.scss";

.businessSetupForm {
  display: flex;
  height: 100%;
  width: 100%;

  .right {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 29px;
    padding-bottom: 40px;
    background-color: $backgroundThemeColor;
    // overflow: auto;
    .top {
      display: flex;
      justify-content: space-between;

      .greetings {
        padding-top: 11px;

        .user {
          color: $headingColorOD;
          font-family: $UrbanistSemiBold;
          font-size: 24px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          margin-bottom: 14px;
        }
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 40px;
      background-color: $primaryWhite;
      border-radius: 6px;
      height: 100%;
      width:100%;
      // overflow: auto;
      .form {
        margin-bottom: 15px;
        width:100%;
        // overflow: auto;
        flex: 1;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        max-height: calc(100% - 40px);
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px $scrollBarColor;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background: $scrollBarColor;
          border-radius: 10px;
        }
        .businessSetup {
          display: flex;
          flex-direction: column;

          .inputFields {
            display: flex;
            margin-bottom: 20px;

            .column1,
            .column2, .column3 {
              display: flex;
              flex-direction: column;
              flex:1;
              .inputField {
                // margin-bottom: 30px;
              }
              .inputFieldSugg {
                position: relative;
                max-width: 400px;
                .inputFieldSuggestionsContainer {
                  display: block;
                  position: absolute;
                  background-color: $primaryWhite;
                  // min-height: max-content;
                  max-height: 250px;
                  width: 100%;
                  fill: $primaryWhite;
                  border-radius: 8px;
                  left: 0;
                  top: 53px;
                  z-index: 3;
                  overflow: auto;
                  margin-top: 10px;
                  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
                  border: 1px solid $tertiaryGray3;
                  padding: 5px 0px;
              
                  &::-webkit-scrollbar {
                    width: 6px;
                  }
              
                  &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px $scrollBarColor;
                    border-radius: 10px;
                  }
              
                  &::-webkit-scrollbar-thumb {
                    background: $scrollBarColor;
                    border-radius: 10px;
                  }
              
                  .productSuggestionItem {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 17px 0px;
                    margin: 0px 15px;
                    cursor: pointer;
                    color: $tertiaryBlack;
                    font-family: $UrbanistRegular;
                    font-size: 14px;
                    line-height: normal;
                    letter-spacing: 0.5px;
              
                    &:hover {
                      background-color: $tertiaryGray9;
                    }
                  }
              
                  .seperator {
                    border-bottom: 1px solid $tertiaryGray3;
                  }
                }
              }
              .marginBottom {
                margin-bottom: 20px;
              }
            }

            .column2, .column3 {
              margin-left: 20px;
            }
          }
        }
        .variants {
          .heading {
            font-size: 20px;
            font-family: $UrbanistRegular;
            letter-spacing: 0.05px;
            color: $primaryBlack;
          }
          .tableContainer {
            // overflow-x: auto;
            // overflow-y: auto;
            border-radius: 6px;
            position: relative;
            height: 100%;
            .table {
              margin-top: 20px;
              // width: 80%;
              width:100%;
              .tableHeader {
                display: flex;
                padding-left: 10px;
                column-gap: 15px;
                border-bottom: 1px solid $tertiaryGray2;
                .tableColumn {
                  color: $tertiaryGray2;
                  font-family: $UrbanistRegular;
                  font-size: 14px;
                  font-weight: 400;
                  letter-spacing: 0.5px;
                  text-align: left;
                  flex: 1;
                  padding-bottom: 10px;
                  max-width:265px;
                  width: 100%;
                  &:first-child {
                    max-width: 500px;
                  }
                }
              }
              .tableBody {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                width:100%;
                max-height: calc(100vh - 437px);
                height:100%;
                overflow: auto;
                .tableRow {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  border-bottom: 1px solid $tertiaryGray2;
                  column-gap: 15px;
                //   flex: 1;
                  padding: 10px 10px 10px 10px;
                  .tableData {
                    max-width:265px;
                    width: 100%;
                    color: $tertiaryGray10;
                    font-family: $UrbanistRegular;
                    font-size: 14px;
                    letter-spacing: 0.5px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    column-gap: 10px;
                    &:first-child {
                      max-width: 500px;
                    }
                  }
                }
                &::-webkit-scrollbar {
                  width: 6px !important;
                  height: 6px !important;
                }
            
                &::-webkit-scrollbar-track {
                  border-radius: 10px;
                  background-color: transparent;
                }
            
                &::-webkit-scrollbar-thumb {
                  background: $scrollBarColor;
                  border-radius: 10px;
              }
              }
            }

        //   &::-webkit-scrollbar {
        //     width: 6px !important;
        //     height: 6px !important;
        //   }
      
        //   &::-webkit-scrollbar-track {
        //     border-radius: 10px;
        //     background-color: transparent;
        //   }
      
        //   &::-webkit-scrollbar-thumb {
        //     background: $scrollBarColor;
        //     border-radius: 10px;
        // }
          }
        }
        .emptyContainer {
          margin-top: 50px;
        }
      }
      .paginationContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-right: 30px;
    }

    }
    .action {
        display: flex;
        justify-content: flex-end;
        height: 40px;
        padding-top: 15px;

        .skip {
          all: unset;
          cursor: pointer;
          border-radius: 6px;
          background: $primaryWhite;
          border: 1px solid $primaryPink;
          width: 180px;
          height: 40px;
          color: $primaryPink;
          text-align: center;
          font-family: $UrbanistRegular;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
        }

        .submit {
          all: unset;
          cursor: pointer;
          border-radius: 6px;
          background: $primaryPink;
          width: 180px;
          height: 40px;
          color: $primaryWhite;
          font-family: $UrbanistRegular;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          text-align: center;
          margin-left: 30px;
        }
        .disable {
          cursor: default;
          background: $tertiaryGray3;
          color: $tertiaryGray2 !important;
        }
      }
  }
}
.preview {
  height: 405px;
  display: flex;
  flex-direction: column;
    .listContainer {
        display: flex;
        flex-direction: column;
        flex:1;
        .row{
            display: flex;
            flex:1;
            align-items:baseline;
            gap: 10px;
            .cell {
                min-width: 150px;
                max-width: 160px;
                width: 100%;
                color: $tertiaryBlack;
                font-family: $UrbanistRegular;
                font-size: 14px;
                letter-spacing: 0.5px;
                // padding-bottom: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

            }
            .heading {
                color: $tertiaryGray2;
                // border-bottom: 1px solid $tertiaryGray2;
                // margin-bottom: 12px
            }
        }
        .header {
          padding: 20px;
        }
        .hr {
          border-top: 1px solid $tertiaryGray2;
          flex: 1;
          // width: 100%;
          margin: 0px 20px;
        }
        .list {
            padding:20px;
            padding-bottom: 0px;
            min-height: 270px;
            max-height: 270px;
            height: 100%;
            overflow: auto;
            // margin-bottom: 30px;
            &::-webkit-scrollbar {
                width: 6px !important;
                height: 6px !important;
              }
          
            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: transparent;
            }
          
            &::-webkit-scrollbar-thumb {
                background: $scrollBarColor;
                border-radius: 10px;
            }
        }
    }
    .actions {
        padding:20px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap:20px;
        .btnStyles {
            max-width: 150px;
        }
    }
}
// .editFormDisabled{
//  background-color: rgba(194, 195, 197, 0.2784313725);
// }

@include minWidth(1400) {
  .form {
    .businessSetup {
      justify-content: center;
      margin: auto;

      .inputFields {
        justify-content: space-between;

        .column1,
        .column2 {
          width: 45%;
        }
      }
    }
  }
}

@include maxWidth(1400) {
  .column1,
  .column2 {
    width: 400px;
  }
}

@include maxWidth(1200) {
  .form {
    .businessSetup {
      justify-content: space-between;
      width: 98%;

      .inputFields {
        justify-content: space-between;

        .column1,
        .column2 {
          width: 47%;
        }
      }
    }
  }
}
