@import "../../../index.scss";
.reviewContainer {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  .top {
    display: flex;
    gap: 15px;
    .profileImg {
      height: 40px;
      width: 40px;
      border: 1px solid $tertiaryGray3;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: contain;
      }
    }
    .nameAndSummary {
      .customerName {
        color: $primaryBlack;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
      }
      .summary {
        color: $summaryColor;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
      }
    }
    .rating {
      display: flex;
      align-items: center;
    }
  }
  .bottom {
    color: $summaryColor;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.5px;
  }
}
