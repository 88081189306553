@import "../../../index.scss";

.card {
  display: flex;
  align-items: center;
  background-color: $primaryWhite;
  width: 100%;
  border-radius: 6px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
  border: 1px solid $primaryWhite;
  cursor: pointer;

  .product {
    display: flex;
    align-items: center;
    max-width: 250px;
    width: 100%;

    .productImg {
      border: 1px solid $tertiaryGray3;
      border-radius: 4px;
      margin-right: 16px;
      height: 44px;
      width: 44px;
      object-fit: contain;
      box-sizing: border-box;
    }

    .productName {
      font-size: 20px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistRegular;
      margin-right: 6px;
      color: $tertiaryGray10;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 50%;
    }
    .width {
      min-width: 31% !important;
      max-width: 75% !important;
    }
    .active {
      color: $activeColor;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistRegular;
      padding-left: 10px;
      padding-right: 10px;
      background-color: $activeBgColor;
      border-radius: 40px;
      height: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .inactive {
      color: $tertiaryGray1;
      background-color: $inactivebgColor;
    }
  }
  .width1 {
    min-width: 31% !important;
    width: 40% !important;
    max-width: 40% !important;
  }
  .activeProduct {
    max-width: 200px;
    width: 100%;
  }
  .overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .productId {
    max-width: 118px;
    width: 100%;
  }

  .quantity {
    max-width: 145px;
    width: 100%;
  }

  .brandName {
    max-width: 137px;
    width: 100%;
  }

  .country {
    max-width: 155px;
    width: 100%;
    display: flex;
    align-items: center;

    .countryFlag {
      margin-right: 5px;
      height: 15px;
    }
  }

  .userType {
    max-width: 100px;
    width: 100%;
  }

  .productType {
    display: flex;
    align-items: center;

    .separator {
      margin-left: 8px;
    }

    .productCategory {
      color: $tertiaryGray2;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistItalic;
      margin-left: 10px;
    }
  }

  .actions {
    display: flex;
    margin-left: auto;
    position: relative;

    .actionItems {
      background-color: $primaryWhite;
      border-radius: 6px;
      padding-top: 10px;
      padding-bottom: 10px;
      position: absolute;
      top: 39px;
      z-index: 1;
      right: -20px;
      width: 251px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);

      .option {
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;

        .label {
          padding-left: 10px;
          color: $tertiaryBlack;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          font-family: $UrbanistRegular;
        }

        &:hover {
          background-color: $tertiaryGray9;
        }
      }

      &::after {
        content: " ";
        position: absolute;
        right: 16px;
        border-width: 16px;
        top: -31px;
        border-style: solid;
        border-color: transparent transparent $primaryWhite transparent;
      }
    }
  }

  .textStyle {
    color: $textColor;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    font-family: $UrbanistRegular;
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    border: 1px solid $primaryPink;
    filter: drop-shadow(0px 0px 24px rgba(236, 24, 123, 0.15));
  }
}

.removeHoverEffect {
  background-color: $backgroundThemeColor;
  border: none;
  padding-bottom: 0px;
  padding-top: 16px;

  &:hover {
    border: none;
  }
}

.businessName {
  max-width: 75% !important;
}
.hiddenOnHover {
  visibility: hidden;
  margin-left: 10px;
}

.card:hover .userType .hiddenOnHover {
  visibility: visible;
}
