@import "../../../index.scss";

.uploadCard {
  width: 400px;
  height: 150px;
  border-radius: 4px;
  background: $uploadCardBgColor;
  border: 1px dashed $primaryPink;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .maxUpload {
    color: $tertiaryBlack;
    text-align: center;
    font-family: $UrbanistRegular;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    margin-top: 10px;
  }

  .content {
    color: $tertiaryGray1;
    font-family: $UrbanistRegular;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:focus{
      outline: 1px dashed $primaryPink;
    }

    .uploadButton {
      cursor: pointer;
      color: $primaryPink;
      margin-left: 4px;
    }
  }

  .selectedFile {
    overflow: hidden;
  }
  .selectedFile img {
    filter: blur(3px);
  }

  .fileDetails {
    display: flex;
    justify-content: space-between;
    align-items: end;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 148px;
    border-radius: 4px;
    // background: linear-gradient(180deg, rgba(33, 33, 33, 0) 0%, #e0e0e0 100%);
    background: linear-gradient(180deg, rgba(33, 33, 33, 0) 0%, #212121 100%);
    padding: 0px 15px 15px 15px;

    .file {
      display: flex;
      align-items: center;

      .fileName {
        color: $primaryWhite;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
        padding-left: 10px;
      }
    }

    .trashIcon {
      cursor: pointer;
      height: 24px;
    }
  }

  .uploadOverlay {
    height: 150px;
    width: 400px;
    position: absolute;
    background-color: rgba($primaryPink, 0.05);
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    .overlayButtons {
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: $primaryPink;
      display: flex;
      flex-direction: row;
      column-gap: 20px;
      padding: 8px 16px;
      border-radius: 4px 0px 4px 0px;
      .eyeButton {
        cursor: pointer;
      }

      .deleteButton {
        cursor: pointer;
      }
    }
  }

  &.uploadCard:hover {
    .uploadOverlay {
      opacity: 1;
      visibility: visible;
    }
  }
}

.errorMessage {
  color: $tertiaryRed;
  font-size: 12px;
  font-style: italic;
  line-height: normal;
  letter-spacing: 0.5px;
  font-family: $UrbanistRegular;
  width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 15px;
}

.attachments {
  color: $headingColorOD;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  font-family: $UrbanistRegular;
  margin-top: 15px;
}

.multipleFiles {
  gap: 5px;
  margin-top: 15px;
  display: flex;
  gap: 5px;

  .multipleFileDetails {
    border: 2px dotted $primaryPink;
    border-radius: 5px;
    display: flex;
    width: 151px;
    height: 151px;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .file {
      display: flex;
      align-items: center;

      .fileIcon {
        height: 24px;
      }

      .fileName {
        padding-left: 10px;
        color: $headingColorOD;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
      }
    }

    .trashIcon {
      cursor: pointer;
      height: 24px;
    }
  }

  .divider {
    width: 400px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $tertiaryGray3;
  }

  .uploadImg {
    position: absolute;
    padding: 5px;
    width: 151px;
    height: 151px;
    margin: 0px;
    object-fit: cover;
  }
}

.editFormStyles {
  -webkit-filter: grayscale(80%);
  filter: grayscale(80%);
  pointer-events: none;
}

.disableMode {
  display: none;
}

.imageBox {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  //   img {
  //     &:first-child {
  //       position: absolute;
  //       width: 100px;
  //       height: 100px;
  //       right: 0;
  //       top: 20px;
  //     }
  width: 700px;
  // height: 800px;
  object-fit: contain;
  .pdfType{
    width: 400px;
    height: 400px;
    background-color: $primaryWhite;
    padding: 10px;
    .pdfImg{
      position: unset !important;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      transform: rotate(0deg) !important;
    }
  }
}
// }

.imageModal {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  .header {
    height: 40px;
    position: absolute;
    background-color: #000;
    // width: 180%;
    // top: 8px;
    width: 100vw;
    top: 0;
    p {
      color: #fff;
      position: absolute;
      left: 40px;
      top: 10px;
      font-family: $UrbanistRegular;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .imageBox {
    outline: none;
    img {
      &:first-child {
        cursor: pointer;
        position: absolute;
        width: 30px;
        height: 30px;
        transform: rotate(180deg);
        fill: #fff;
        left: 0;
        top: 5px;
      }
      width: 100%;
      height: 500px;
      object-fit: contain;
      outline: none;
    }
  }
}

.eyeButton {
  display: flex;
}

.iconContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.noPreview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 400px;
  background: #fff;
}

