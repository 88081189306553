@import "../../../../index.scss";

.app {
  height: auto;
  display: flex;
  gap: 40px;
  margin: 0 40px;

  .headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .rightBodyContainer {
    width: 100%;

    .searchBar {
      margin-top: 17px;
    }

    .searchMargin {
      margin: 0 30px 0 0 !important;
    }

    .outerBox {
      box-sizing: border-box;
      height: calc(100vh - 185px);
      background: $primaryWhite;
      border-radius: 6px;
      padding-bottom: 30px;
      margin-bottom: 40px;
      overflow: hidden;

      .catalogueContainer {
        margin: 0 30px;
        padding-bottom: 15px;
      }

      .catalogueBorder {
        height: 1px;
        background-color: $tertiaryGray3;
        margin: 0 30px;
      }
    }

    .adminCatalogue {
      margin-top: 40px;
      height: calc(100% - 85px);
    }
  }

  .disabledMode {
    pointer-events: none;
  }

  .horizontal {
    height: 1px;
    background-color: $tertiaryGray3;
    margin: 0px 30px 0px 30px;
  }

  .OuterFormBox {
    width: 100%;
    height: auto;
    margin-top: 0px;
    padding: 0px 0px 0px 30px;
  }

  .outerFormBox2 {
    width: 100%;
    height: auto;
    margin-top: 10px;
    padding: 0px 0px 0px 0px;
    position: relative;
  }

  .towTopForm {
    padding: 0px 30px;
  }

  .certificateForm {
    padding: 0px 30px 0px 30px;
  }

  .formTwo {
    padding-right: 30px;
  }

  .textArea {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-bottom: 28px;
    margin-top: 26px;
  }

  .textAreaSecondary {
    display: flex;
    margin-bottom: 24px;
    margin-top: 28px;
  }

  .discountTextArea {
    display: flex;
    gap: 40px;
    width: calc(100% - 158px);
  }

  .textAreaMargin {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-bottom: 35px;
    margin-top: 26px;
  }

  .textAreaLast {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-top: 26px;
    margin-bottom: 17px;
  }

  .textArea1 {
    width: 840px !important;
  }

  .input1 {
    display: flex;
    flex-direction: column;
    height: 105px;
    width: 100%;
  }

  .input {
    width: 100%;
    margin-top: 0px;
    flex: 1;
  }

  .smallInput {
    width: 200px;
  }

  .formfield {
    width: 90px;
    background-color: $primaryWhite !important;
    border: 1px solid $primaryPink;
    color: $primaryPink;
  }

  .inputText {
    width: 370px;
    height: 42px;
    top: 665px;
    left: 679px;
    font-family: $UrbanistSemiBold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
    text-align: left;
    margin-top: 25px;

    span {
      &:last-child {
        color: $tertiaryGray7;
        font-family: $UrbanistRegular;
        font-size: 12px;
        line-height: normal;
        letter-spacing: 0.5px;
        margin-left: 2px;
      }
    }

    .HS {
      font-family: $UrbanistSemiBold;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.5px;
      text-align: left;
    }
  }

  .uploadLabel {
    margin-bottom: 5px;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
  }

  .uploadedText {
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 29px;
    width: 100%;
    margin-top: 20px;
    padding-right: 25px;
  }

  .buttonContainer2 {
    display: flex;
    justify-content: flex-end;
    gap: 29px;
    width: calc(100% - 70px);
    margin-top: 20px;
    padding-top: 32px;
    position: absolute;
    bottom: 60px;
    right: 40px;
    background: $primaryWhite;
  }

  .formButtonCancel {
    background-color: $primaryWhite;
    border: 1px solid $primaryPink;
    color: $primaryPink;
  }

  .generateBtn {
    position: absolute;
    width: 70px;
    top: 2px;
    cursor: pointer;
    color: $primaryPink;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 20px;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
  }

  .generateBox {
    position: relative;
  }

  .generateBoxColor {
    padding-bottom: 22px;
  }

  .errorMessage {
    font-family: $UrbanistItalic;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: $secondaryRed;
    margin-top: 10px;
  }
}

.multipleUpload {
  label {
    cursor: pointer;
    display: flex;
    height: 150px;
    border-radius: 4px;
    background: $uploadCardBgColor;
    border: 1px dashed $primaryPink;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    gap: 7px;

    .upperText {
      display: flex;
      gap: 5px;
    }

    span {
      color: $primaryPink;
    }
  }
}

.uploadedFilesContainer {
  display: flex;
  gap: 22px;
  margin-top: 20px;

  .uploadedFile {
    border: 1px dashed $primaryPink;
    width: 150px;
    height: 150px;
    border-radius: 6px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .uploadOverlay {
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgba($primaryPink, 0.05);
      border-radius: 4px;
      top: 0;
      opacity: 0;
      visibility: hidden;

      .overlayButtons {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: $primaryPink;
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        padding: 8px 16px;
        border-radius: 4px 0px 4px 0px;

        .eyeButton {
          cursor: pointer;
        }

        .deleteButton {
          cursor: pointer;
        }
      }
    }

    &:hover {
      .uploadOverlay {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .imageName {
    margin-top: 10px;
    color: $teritaryBlack;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    margin-top: 7px;
    pointer-events: none;
  }
}

.tootltipLabel {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}

.rightBodyContainer {
  width: 100%;

  .adminOuterBox {
    height: calc(100vh - 110px) !important;
  }

  .outerBox {
    box-sizing: border-box;
    height: auto;
    background: $primaryWhite;
    border-radius: 6px;

    .producingPricing {
      height: auto;
      // min-height: 260px;
      min-height: 290px;
      flex-shrink: 0;
      background-color: $primaryWhite;
      // margin-top: 15px;

      .producingPricingForm {
        fill: #fff;
        filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.05));
        // filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
        margin-left: 15px;
        margin-right: 15px;
        background-color: $primaryWhite;
      }

      .producingPricingText {
        color: $headingColorOD;
        font-family: $UrbanistSemiBold;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        margin-bottom: 20px;
        padding: 28px 37px 0 30px;
      }

      .producingPricingTextArea {
        display: flex;
        gap: 40px;
        margin-top: 30px;
        width: calc(100% - 158px);
      }
    }

    .innerBox {
      height: auto;
      min-height: 260px;
      flex-shrink: 0;
      background-color: $primaryWhite;
      filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
      margin-top: 25px;

      .innerText {
        color: $headingColorOD;
        font-family: $UrbanistSemiBold;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        margin-left: 30px;
        letter-spacing: 0.5px;
        padding-top: 25px;
        margin-bottom: 20px;
      }

      .textArea {
        display: flex;
        gap: 40px;
        margin-top: 30px;
      }
    }
  }
}

.orderTextArea {
  display: flex;
  width: 100%;
}

.Addbtn {
  margin: 27px 0 30px 0;
  width: 120px;
  height: 40px;
}

.text1 {
  color: $tertiaryBlack;
  font-family: $UrbanistSemiBold;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
}

.text2 {
  color: $tertiaryBlack;
  font-family: $UrbanistRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.checkboxFrame {
  margin-top: 15px;
  margin-right: 30px;
  display: flex;
  gap: 30px;
  margin-bottom: 70px;
}

.checkInput {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: $UrbanistRegular;
  color: $tertiaryGray1;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
}

.certificationsText {
  margin-right: 30px;
  margin-top: 20px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  width: 100%;
}

.formButtonCancel {
  background-color: $primaryWhite;
  border: 1px solid $primaryPink;
  color: $primaryPink;
}

.tableHeading {
  display: flex;
  height: 37px;
  align-items: center;
  color: $tertiaryGray2;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  padding: 0px 0px 0px 30px;
}

.tableHeadData1 {
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 20px;
}

.tableHeadData2 {
  width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 20px;
  display: flex;
  .optionValue {
    max-width: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      .optionValueHover {
        display: inline;
        background-color: black;
        border: none;
      }
    }
  }

  .optionValueHover {
    // display: block;
  }
}

.tableHeadData3 {
  width: 165px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 20px;
}

.tableHeadData4 {
  width: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
  // padding-right: 20px
}

.tableHeadData5 {
  width: 150px;
  color: $primaryLightGreen;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tableContainer {
  margin-top: 25px;
  margin-bottom: 49px;
}

.line {
  width: 1px;
  height: 30px;
  background-color: $tertiaryGray1;
  margin-right: 28px;
  margin-left: 20px;
}

.horizontal {
  height: 1px;
  width: auto;
  margin-left: 1px;
  background-color: $tertiaryGray3;
}

.tableData {
  display: flex;
  height: 56px;
  align-items: center;
  color: $primaryBlack;
  font-family: $UrbanistRegular;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  width: 100%;
  padding: 0px 0px 0px 30px;

  & .remove {
    display: none;
  }

  &:hover .remove {
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: 5px;

    & .remove::after {
      content: " ";
      position: relative;
      width: 120%;
      left: -30px;
    }
  }
  .productName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.tableData:hover {
  background-color: $tertiaryGray9;

  &:hover .tableHeadData {
    width: 230px;
  }
}

.remove {
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 23px;
  cursor: pointer;
}

.delete {
  color: $primaryPink;
  font-family: $UrbanistRegular;
  width: max-content;
}

.label {
  font-family: $UrbanistRegular;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  text-align: left;
}

.tooltipContainer {
  position: absolute;
  left: 180px;
}

.formButtonCancel,
.formButton {
  width: 180px !important;
  height: 40px !important;
}

.horizontalTop {
  margin-left: 30px;
  margin-top: 24px;
}

.breadcrumb {
  margin-top: 25px;
  margin-bottom: 21px;
}

.customPadding {
  padding: 0 !important;
}

.disabledCheckbox {
  opacity: 0.3;

  span {
    &:first-child {
      color: $gray !important;
    }
  }
}

.addIndividualFormBlock {
  height: 100% !important;
}

.addIndividualForm {
  width: 100%;
  height: calc(100% - 60px) !important;
  overflow-y: auto;
}

.addIndividualFormTwo {
  width: 100%;
  height: calc(100% - 110px) !important;
  overflow-y: auto;
}

.noteMessage {
  color: $tertiaryGray1;
  padding: 0px 0px 10px 0px;
  font-size: 12px;
  font-style: italic;
  justify-content: flex-start;
}

.generalInfoBlock {
  height: calc(100% - 70px) !important;
  overflow-y: auto;
  padding-right: 20px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $scrollBarColor;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollBarColor;
    border-radius: 10px;
  }
}

.lineDivider {
  border-left: 1px solid $tertiaryGray7;
  height: 20px;
  margin: 32px 0 0 -1px;
}

.discountText {
  input {
    color: $primaryLightGreen !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: 0.5px !important;
  }
}

@include maxWidth(1300) {
  .tableHeadData1 {
    width: 215px !important;
  }
}

@include maxWidth(1367) {
  .tableHeadData5 {
    width: 100px !important;
  }
}

@include maxWidth(1260) {
  .producingPricing {
    padding: 0px 0px 5px 0px;

    .Addbtn {
      margin: 27px 0 30px 0px !important;
    }

    .orderTextArea {
      width: 300px !important;
    }

    .producingPricingTextArea {
      width: 100% !important;
    }

    .textAreaSecondary {
      flex-direction: column;

      .discountTextArea {
        width: 100% !important;
      }
    }

    .delete {
      display: none;
    }
  }

  .tableHeadData1 {
    width: 180px !important;
  }

  .tableHeadData2 {
    width: 120px !important;
  }

  .tableHeadData3 {
    width: 120px !important;
  }

  .tableHeadData5 {
    width: 130px;
  }

  .remove {
    margin-left: 12px !important;
  }
}

@include minWidth(1300) {
  .textArea {
    width: 79.5%;
  }

  .textAreaMargin {
    width: 79.5%;
  }

  .textAreaLast {
    width: 79.5%;
  }

  .multipleUpload {
    width: 79.5%;
  }
}

@include minWidth(1400) {
  .textArea {
    width: 100%;
  }

  .textAreaMargin {
    width: 100%;
  }

  .textAreaLast {
    width: 100%;
  }

  .multipleUpload {
    width: 100%;
  }
}

@include minWidth(1400) {
  .tableHeadData1 {
    width: 160px !important;
  }

  .tableHeadData2 {
    width: 160px !important;
  }

  .tableHeadData3 {
    width: 160px !important;
  }

  .tableHeadData5 {
    width: 140px;
  }

  .remove {
    margin-left: 30px !important;
    &:first-child {
      margin-left: 10px !important;
    }
  }
}

// @include minWidth(1400) {
//   .tableHeadData1 {
//     width: 220px !important;
//   }

//   .tableHeadData2 {
//     width: 190px !important;
//   }

.tableHeadData3 {
  width: 160px !important;
}

//   .tableHeadData5 {
//     width: 140px;
//   }

//   .remove {
//     margin-left: 30px !important;
//   }
// }

@include minWidth(1800) {
  .tableHeadData1 {
    width: 310px !important;
  }

  .tableHeadData2 {
    width: 200px !important;
  }

  .tableHeadData3 {
    width: 160px !important;
  }

  .tableHeadData5 {
    width: 180px;
  }

  // .remove {
  //   margin-left: 50px !important;
  // }
}

.imgForTootltip {
  background-color: red;

  &:hover {
    background-color: black !important;

    &_MuiTooltip-tooltip {
      display: none !important;
    }
  }
}

.moqPadding {
  padding-top: 15px;
}

.mediaMargin {
  margin-top: 40px;
}

.imageBoxMargin {
  margin-top: 30px;
}

.attributeContainer {
  display: flex;
  flex-direction: column;
  margin-top: 28px;
}

.moqPadding {
  margin-top: 15px;
}

.attriburteViewontainer {
  display: flex;
  flex-direction: column;

  .attributeViewBody {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .attributeView {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
      gap: 4px;

      .attributeName {
        color: #949494;
        font-family: $UrbanistSemiBold;
        font-size: 13px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
      }

      .attributeValue {
        color: $tertiaryBlack;
        font-family: $UrbanistSemiBold;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
      }
    }
  }
}

.attributeErrMsg {
  font-family: "UrbanistItalic";
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #eb4848;
  margin-top: 10px;
}

.rejectBtn {
  text-align: right;
}

.productNameSugg {
  position: relative;
  .productNameSuggestionsContaine {
    display: block;
    position: absolute;
    background-color: $primaryWhite;
    min-height: max-content;
    max-height: 250px;
    width: 100%;
    fill: $primaryWhite;
    border-radius: 8px;
    left: 0;
    top: 53px;
    z-index: 3;
    overflow: auto;
    margin-top: 10px;
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
    border: 1px solid $tertiaryGray3;
    padding: 5px 0px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px $scrollBarColor;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollBarColor;
      border-radius: 10px;
    }

    .productSuggestionItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 17px 15px;
      cursor: pointer;
      color: $tertiaryBlack;
      font-family: $UrbanistRegular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0.5px;

      &:hover {
        background-color: $tertiaryGray9;
      }
    }

    .seperator {
      border-bottom: 1px solid $tertiaryGray3;
    }
  }
}
.vatContainer {
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}
.actionsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pointerCursor {
  cursor: pointer;
}

.imageModal {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  .header {
    height: 40px;
    position: absolute;
    background-color: $primaryBlack;
    width: 100vw;
    top: 0;
    left: 0;
    p {
      color: $primaryWhite;
      position: absolute;
      left: 40px;
      top: 10px;
      font-family: $UrbanistRegular;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .imageBox {
    outline: none;
    z-index: 1;
    img {
      &:first-child {
        cursor: pointer;
        position: absolute;
        width: 30px;
        height: 30px;
        transform: rotate(180deg);
        fill: #fff;
        left: 0;
        top: 5px;
      }
      width: 100%;
      height: 500px;
      object-fit: contain;
      outline: none;
    }
  }
}

.eyeButton {
  display: flex;
}

.iconContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.noPreview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 400px;
  background: #fff;
}


.attributesWrapper {
  background-color: #fff;
  fill: #fff;
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.05));
  margin-left: 15px;
  margin-right: 15px;
  background-color: $primaryWhite; 
  margin-bottom: 20px;
  padding: 30px;
}