@import "../../../../../index.scss";

.card {
    display: flex;
    background-color: $primaryWhite;
    border: 1px solid $primaryWhite;
    align-items: center;
    justify-content: space-between;
    height: 76px;
    padding-left: 20px;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-right: 20px;
    border-radius: 6px;
    width: 100%;
    cursor: pointer;

    .details {
        display: flex;
        align-items: center;

        .orderID {
            display: flex;
            align-items: center;
            max-width: 263px;
            // width: 100%;
            min-width: 225px;

            .orderDetails {
                display: flex;
                flex-direction: column;
                margin-left: 18px;
                min-width: 165px;

                .id {
                    display: flex;
                    align-items: center;

                    .idNo {
                        color: $gray2;
                        font-size: 20px;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        font-family: $UrbanistRegular;
                    }

                    .paid {
                        margin-left: 6px;
                        color: $paidColor;
                        font-size: 14px;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        font-family: $UrbanistRegular;
                        border-radius: 40px;
                        background: $paidBackgroundColor;
                        height: 20px;
                        padding: 5px 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }

                    .unpaid {
                        margin-left: 6px;
                        color: $tertiaryGray1;
                        font-size: 14px;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        font-family: $UrbanistRegular;
                        border-radius: 40px;
                        background: $unpaidBackgroundColor;
                        height: 20px;
                        padding: 5px 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }

        .deliveryStatus {
            color: $gray2;
            font-size: 14px;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistRegular;
        }

        .placedOn {
            min-width: 164px;
            max-width: 165px;
            text-overflow: ellipsis;
            margin-left: 54px;
            overflow: hidden;
        }

        .buyerName {
            min-width: 100px;
            max-width: 101px;
            margin-left: 54px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .orderValue {
            min-width: 120px;
            // max-width: 86px;
            margin-left: 28px;
            overflow: hidden;
            text-overflow: ellipsis;
            // line-height: 10px;
        }

        .actualAmount {
            margin-left: 50px;
            min-width: 85px;
            max-width: 96px;
        }

        .orderItems {
            margin-left: 58px;
            min-width: 55px;
            max-width: 79px;
        }

        .orderItemsMargin {
            margin-left: 50px;
            min-width: 55px;
            max-width: 79px;
        }
    }

    .actions {
        display: flex;
        align-items: center;
        gap: 30px;

        .action {
            display: flex;
            align-items: center;

            .actionTxt {
                color: $primaryPink;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                font-family: $UrbanistRegular;
                margin-left: 2px;
            }
        }
    }

    &:hover {
        border: 1px solid $primaryPink;
        filter: drop-shadow(0px 0px 24px rgba(236, 24, 123, 0.15));
    }
}

@include maxWidth(1150) {
    .actions {
        gap: 5px;

        .actionTxt {
            display: none;
        }
    }
}

@include maxWidth(1200) {

    .card {
        .orderDetails {
            min-width: 125px !important;
            margin-left: 5px !important;

            .idNo {
                font-size: 14px !important;
            }
        }

        .orderID {
            min-width: 183px !important;
            margin-right: 10px;
        }

        .placedOn {
            min-width: 130px !important;
            max-width: 165px !important;
            margin-left: 0px !important;

        }

        .buyerName {
            margin-left: 25px !important;
        }

        .orderValue {
            margin-left: 20px !important;
        }

        .orderItemsMargin {
            margin-left: 40px !important;
        }


    }
}

@include maxWidth(1400) {
    .actions {
        gap: 10px !important;

    }
    .orderItemsMargin {
        margin-left: 40px !important;
    }

}

@include minWidth(1500) {

    .details {
        gap: 50px;
    }

    .completedDetails {
        gap: 40px;
    }
}

@include minWidth(1700) {

    .details {
        gap: 90px;
    }

    .completedDetails {
        gap: 90px;
    }
}