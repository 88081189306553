@import "../../../../index.scss";
.addoutletContainer {
    width: 500px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 17px;
        margin-left: 30px;
        margin-right: 30px;
        padding-bottom: 16px;
        border-bottom: 1px solid $tertiaryGray2;
    
        .heading {
          color: $tertiaryBlack;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          font-family: $UrbanistSemiBold;
        }
    
        .cancel {
          cursor: pointer;
        }
      }


      .bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 40px;
        background-color: $primaryWhite;
        border-radius: 6px;
        height: 100%;
        overflow: auto;
        .form {
            // margin-bottom: 30px;
            overflow: auto;
            flex: 1;
            max-height: calc(100% - 40px);
            .inputFields {
                display: flex;
                flex-direction: column;
                // margin-bottom: 20px;

                .inputField {
                    margin-bottom: 25px;
                }
                .marginBottom {
                    margin-bottom: 20px;
                }
            }
            .defOutlet {
                margin-bottom: 58px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .checkbox label {
                    font-size: 16px;
                    line-height: 14px;
                    color: $tertiaryGray1;
                    margin-left: 8px;
                }

                .checkbox {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    font-style: italic !important;
                    cursor: pointer;
                }

                ::ng-deep .p-checkbox {
                    width: 20px !important;
                    height: 20px !important;
                    display: flex;

                    .p-checkbox-box {
                        width: 20px !important;
                        height: 20px !important;
                        border: 1px solid $tertiaryGray4;
                    }

                    .p-checkbox-box.p-highlight {
                        background: $primaryPink  !important;
                        border-radius: 2px !important;
                        border: none !important;
                    }

                    .p-checkbox-box.p-focus {
                        box-shadow: none !important;
                    }
                }
            }
        }
        .action {
            display: flex;
            justify-content: flex-end;
            height: 40px;
            padding-right: 30px;

            .skip {
                all: unset;
                cursor: pointer;
                border-radius: 6px;
                background: $primaryWhite;
                border: 1px solid $primaryPink;
                width: 180px;
                height: 40px;
                color: $primaryPink;
                text-align: center;
                font-family: $UrbanistRegular;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
            }

            .submit {
                all: unset;
                cursor: pointer;
                border-radius: 6px;
                background: $primaryPink;
                width: 180px;
                height: 40px;
                color: $primaryWhite;
                font-family: $UrbanistRegular;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                text-align: center;
                margin-left: 30px;
            }
        }
    }
}