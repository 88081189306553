@import "../../../../common/styles/variables.scss";
.cartSummaryWrapper {
  min-width: 30%;
  border-radius: 4px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
  margin-left: 10px;
  .viewCouponsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 0.875rem;
    font-family: $UrbanistMedium;
    font-style: italic;
    color: $tertiaryGray1;
    background-color: rgba(242, 202, 0, 0.15);
    padding: 15px;
    // opacity: 0.15;
    .viewCouponBtn {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      color: $primaryPink;
    }
  }
  .cartModeOfPaymentWrapper {
    padding: 0 30px;
    padding-bottom: 20px;
  }
  .totalCartAmountWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    .amountText {
      font-size: 1.5rem;
    }
    .currency {
      font-size: 0.625rem;
    }
    .estimatedText {
      font-size: 0.875rem;
    }
  }
  .placeOrderBtn {
    margin-top: 35px;
  }
}
