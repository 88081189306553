@import "../../../index.scss";

.businessSetupForm {
    display: flex;
    height: 100%;
    width: 100%;

    .left {
        background-color: $primaryLightGreen;
    }

    .right {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 29px;
        padding-bottom: 40px;
        background-color: $backgroundThemeColor;
        overflow: hidden;

        .adminEditSupplierHeader {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .actionsContainer {
                display: flex;
                flex-direction: row;
                column-gap: 20px;
            }
        }

        .top {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            overflow: hidden;

            .greetings {
                padding-top: 11px;
                padding-bottom: 20px;

                .user {
                    color: $headingColorOD;
                    font-family: $UrbanistSemiBold;
                    font-size: 24px;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    // text-transform: uppercase;
                    font-feature-settings: 'clig' off, 'liga' off;
                    // margin-bottom: 14px;
                }

                .welcome {
                    color: $tertiaryBlack;
                    font-family: $UrbanistRegular;
                    font-size: 14px;
                    font-style: normal;
                    line-height: 22px;
                    letter-spacing: 0.5px;
                    max-width: 645px;
                    width: 100%;
                }
            }

            .icon {
                display: flex;
                // align-items: end;
                justify-content: flex-end;
                padding-bottom: 20px;
                height: 117px;
            }

            .actionsOnUser {
                display: flex;
                justify-content: flex-end;
                padding-bottom: 20px;
                // width: 390px;
            }
        }

        // overriding styles for admin buyer form 
        .overrideTop {
            display: block;

            .overrideGeetings {
                display: flex;
                justify-content: space-between;
            }
        }

        .bottom {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 30px;
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 40px;
            background-color: $primaryWhite;
            border-radius: 6px;
            height: 100%;
            overflow: auto;
        }

        // overriding styles for admin buyer form
        .overrideBottom {
            height: calc(100vh - 120px);
            padding-bottom: 60px;
        }

        .form {
            margin-bottom: 15px;
            overflow: auto;
            flex: 1;
            max-height: calc(100% - 40px);

            .businessSetup {
                display: flex;
                flex-direction: column;

                .setup {
                    color: $headingColorOD;
                    font-size: 14px;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    font-family: $UrbanistSemiBold;
                    margin-bottom: 5px;
                }

                .content {
                    color: $tertiaryBlack;
                    font-size: 14px;
                    font-style: normal;
                    line-height: 22px;
                    letter-spacing: 0.5px;
                    font-family: $UrbanistRegular;
                    margin-bottom: 30px;
                }

                .inputFields {
                    display: flex;
                    margin-bottom: 20px;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    column-gap: 40px;
                    row-gap: 15px;

                    .inputField {
                        min-width: 400px;
                        margin-bottom: 15px;
                    }

                    .marginBottom {
                        margin-bottom: 20px;
                    }

                    .column1,
                    .column2 {
                        display: flex;
                        flex-direction: column;


                    }

                    .column2 {
                        margin-left: 40px;
                    }

                    .addressField {
                        max-width: 400px;
                        width: 100%;
                    }
                }

                .uploadFields {
                    display: flex;
                    margin-bottom: 20px;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    column-gap: 40px;
                }
            }

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px $scrollBarColor;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background: $scrollBarColor;
                border-radius: 10px;
            }
        }

        .action {
            display: flex;
            justify-content: flex-end;
            height: 40px;

            .skip {
                all: unset;
                cursor: pointer;
                border-radius: 6px;
                background: $primaryWhite;
                border: 1px solid $primaryPink;
                width: 180px;
                height: 40px;
                color: $primaryPink;
                text-align: center;
                font-family: $UrbanistRegular;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;

                &:focus {
                    outline: 1px solid $primaryPink;
                }
            }


        }
    }

    .fieldError {
        color: $secondaryRed;
    }

    .outletBtn {
        margin-left: 0;
    }

    .outletsWrapper {
        margin-top: 1rem;
        max-width: 95%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

.label,
.mainLabel {
    color: $teritaryBlack;
    font-family: $UrbanistRegular;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.5px;
}

.uploadContainer {
    margin-top: 14px;
}

.uploadWithInput {
    margin-top: 30px;
}

.typeOfPayment {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 0 !important;

    .radioBoxContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px !important;

        .radioBox {
            margin-left: -12px;
            display: flex;
            align-items: center;

            &:focus {
                outline: 1px solid $primaryPink;
            }
        }
    }
}

.verifyBox {
    position: relative;

    .verify {
        color: $primaryPink;
        font-size: 14px;
        line-height: normal;
        letter-spacing: 0.5px;
        position: absolute;
        top: 35px;
        right: 25px;
        cursor: pointer;
    }
}

.ibanValid {
    display: flex;
    align-items: center;
    margin-top: 4px;
    gap: 4px;

    .heading {
        color: $primaryGreen;
        font-family: $UrbanistSemiBold;
        font-size: 12px;
        line-height: normal;
        letter-spacing: 0.5px;
    }

}

.ibanInValid {
    display: flex;
    align-items: center;
    margin-top: 4px;
    gap: 4px;

    .heading {
        color: $secondaryRed;
        font-family: $UrbanistRegular;
        font-size: 12px;
        line-height: normal;
        letter-spacing: 0.5px;
    }

}

.submit {
    all: unset;
    cursor: pointer;
    border-radius: 6px;
    background: $primaryPink;
    width: 180px;
    height: 40px;
    color: $primaryWhite;
    font-family: $UrbanistRegular;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    margin-left: 30px;

    &:focus {
        outline: 2px solid $primaryPink;
    }
}


.editFormInputStyles {
    pointer-events: none;
}

@include minWidth(1400) {
    .deliveryAddress {
        margin-top: 15px;
    }
}

.dropDownMargin {
    margin-top: 6px !important;
}

.ratingModal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .modalFullContainer {
        background: $primaryWhite;
        border: 2px solid $primaryLightGreen;
        padding: 18px 30px 30px;
        filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
        max-width: 460px;
        min-width: 460px;
        max-height: 480px;
        min-height: 480px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        overflow: hidden;

        .heading {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .leftContainer {
                color: $teritaryBlack;
                font-size: 14px;
                letter-spacing: 0.5px;
                line-height: normal;
                font-family: $UrbanistSemiBold;
            }

            .rightContainer {
                cursor: pointer;
            }
        }

        .seperator {
            height: 1px;
            width: 100%;
            background-color: $tertiaryGray2;
            margin-top: 10px;
        }

        .modalBody {
            flex: 1;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            overflow: auto;

            .body {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                flex: 1;

                .ratingContainer {
                    display: flex;
                    justify-content: space-between;

                    .ratingBox {
                        display: flex;
                        flex-direction: column;

                        .upContainer {
                            display: flex;
                            gap: 10px;
                            font-family: $UrbanistRegular;
                            font-size: 12px;

                            .ratingValue {
                                span {
                                    color: $tertiaryGray1;
                                    font-family: $UrbanistRegular;
                                    font-size: 14px;
                                    line-height: normal;
                                    letter-spacing: 0.5px;

                                    &:first-child {
                                        font-size: 24px;
                                    }
                                }
                            }

                            .ratingStarContainer {
                                display: flex;
                                align-items: center;
                                padding-top: 3px;
                            }
                        }

                        .ratingBlock {
                            color: $tertiaryGray1;
                            font-family: $UrbanistRegular;
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: 0.07px;
                        }
                    }
                }

                .questionContainer {
                    font-family: $UrbanistSemiBold;
                    flex: 1;
                    margin-top: 10px;

                    .heading {
                        color: $tertiaryGray1;
                        font-family: $UrbanistSemiBold;
                        font-size: 14px;
                        line-height: normal;
                        letter-spacing: 0.5px;
                    }

                    .options {
                        display: flex;
                        flex-direction: column;
                        max-height: 322px;
                        overflow-y: auto;

                        &::-webkit-scrollbar {
                            width: 6px;
                        }

                        &::-webkit-scrollbar-track {
                            box-shadow: inset 0 0 5px $scrollBarColor;
                            border-radius: 10px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: $scrollBarColor;
                            border-radius: 10px;
                            ;
                        }

                        .option {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            margin-bottom: 12px;

                            .label {
                                color: $tertiaryGray1;
                                font-family: $UrbanistRegular;
                                font-size: 14px;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: 0.5px;
                                cursor: pointer;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    &::-webkit-scrollbar {
                        width: 6px;
                    }

                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 5px $scrollBarColor;
                        border-radius: 10px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: $scrollBarColor;
                        border-radius: 10px;
                        ;
                    }
                }

                .textAreaContainer {
                    display: flex;
                    height: 80px;
                    align-items: flex-start;
                    gap: 10px;
                    flex-shrink: 0;
                    align-self: stretch;
                    border-radius: 4px;
                    border: 1px solid $tertiaryGray2;
                    background: $primaryWhite;
                    overflow: hidden;
                    margin-top: 30px;

                    textArea {
                        width: 100%;
                        height: 100%;
                        padding: 11px 10px;
                        resize: none;

                    }
                }
            }
        }

        .footer {
            display: flex;
            justify-content: flex-end;
            column-gap: 30px;

            .button {
                height: 40px;
                margin-top: -15px;
            }

        }
    }
}

.overrideActions {
    align-items: flex-end;
}

.checkInput {
    display: flex;
    align-items: center;
    gap: 3px;
    font-family: $UrbanistRegular;
    color: $tertiaryGray1;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    margin-bottom: unset !important;
    height: 63px;
}

.productNameSugg {
    position: relative;

    .productNameSuggestionsContainer {
        display: block;
        position: absolute;
        background-color: $primaryWhite;
        min-height: max-content;
        max-height: 250px;
        width: 100%;
        fill: $primaryWhite;
        border-radius: 8px;
        left: 0;
        top: 53px;
        z-index: 3;
        overflow: auto;
        margin-top: 10px;
        filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
        border: 1px solid $tertiaryGray3;
        padding: 5px 0px;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px $scrollBarColor;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: $scrollBarColor;
            border-radius: 10px;
        }

        .productSuggestionItem {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 17px 0px;
            margin: 0px 15px;
            cursor: pointer;
            color: $tertiaryBlack;
            font-family: $UrbanistRegular;
            font-size: 14px;
            line-height: normal;
            letter-spacing: 0.5px;

            &:hover {
                background-color: $tertiaryGray9;
            }
        }

        .seperator {
            border-bottom: 1px solid $tertiaryGray3;
        }
    }
}
.action3{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    .skip {
        all: unset;
        cursor: pointer;
        border-radius: 6px;
        background: $primaryWhite;
        border: 1px solid $primaryPink;
        width: 180px;
        height: 40px;
        color: $primaryPink;
        text-align: center;
        font-family: $UrbanistRegular;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
    }

    .submit {
        all: unset;
        cursor: pointer;
        border-radius: 6px;
        background: $primaryPink;
        width: 180px;
        height: 40px;
        color: $primaryWhite;
        font-family: $UrbanistRegular;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
    }
}
.defaultBadge {
    display: inline-block;
    padding: 4px 10px;
    font-size: 0.875rem;
    border-radius: 25px;
    margin-left: 8px;
    background-color: $paidBackgroundColor2;
    color: $activeColor;
  }