@import "../../styles/variables.scss";

.Parentdiv {
  height: 8px;
  margin-right: 20px;
  width: 100%;
  max-width: 323px;
  background-color: $progressBarBgColor;
  border-radius: 40px;
  .Childdiv {
    height: 100%;
    background-color: $secondaryThickGreen;
  }
}
