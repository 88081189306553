@import "../../../index.scss";

.container {
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  flex: 1;

  .tabsContent {
    display: flex;
    flex-direction: column;
    flex: 1;
    .tableHeader {
      display: flex;
      padding: 0px 20px;
      width: 100%;
      margin-top: 16px;
      .headerColumn1 {
        max-width: 250px;
        width: 100%;
        font-family: $UrbanistRegular;
        color: $tertiaryGray2;
        font-size: 14px;
        letter-spacing: 0.5px;
      }
      .headerColumn2 {
        max-width: 200px;
      }
      .headerColumn3 {
        max-width: 145px;
      }
      .headerColumn4 {
        max-width: 137px;
      }
      .headerColumn5 {
        max-width: 155px;
      }
    }
    .tabslistContainer {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      overflow: hidden;

      .ordersList {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 15px;
        height: calc(100vh - 250px);
        overflow-y: auto;
        padding-right: 5px;
        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px $scrollBarColor;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: $scrollBarColor;
          border-radius: 10px;
        }
      }

      .paginationContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    .emptyContainer {
      display: flex;
      flex: 1;
      margin-top: 20px;
      margin-bottom: 50px;
    }
  }
}

.searchContainer {
  display: flex;
  justify-content: space-between;
  width: 516px;
  background-color: $primaryWhite;
  border: 1px solid $primaryWhite;
  border-radius: 40px;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 6px;
  padding-left: 10px;

  .searchIconInput {
    display: flex;
    align-items: center;
    width: 100%;

    .searchInput {
      height: 24px;
      padding-left: 10px;
      display: flex;
      flex: 1;
    }
  }

  .cancel {
    cursor: pointer;
    height: 24px;
  }

  &:hover {
    border-color: $primaryPink;
  }
}

.searchIcon {
  height: 24px;
  cursor: pointer;
}

// .hr {
//   border-bottom: 1px solid $tertiaryGray3;
//   position: relative;
//   top: 0;
//   width: 95%;
// }

.searchMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  height: 32px;
}

.actions {
  display: flex;
  margin-left: 20px;
  gap: 30px;
  align-items: center;
  .icon {
    height: 24px;
    cursor: pointer;
  }

  .search {
    .searchContainer {
      display: flex;
      justify-content: space-between;
      width: 516px;
      background-color: $primaryWhite;
      border: 1px solid $primaryWhite;
      border-radius: 40px;
      align-items: center;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-right: 6px;
      padding-left: 10px;

      .searchIconInput {
        display: flex;
        align-items: center;
        width: 100%;

        .searchInput {
          height: 24px;
          padding-left: 10px;
          display: flex;
          flex: 1;
        }
      }

      .cancel {
        cursor: pointer;
        height: 24px;
      }

      &:hover {
        border-color: $primaryPink;
      }
    }

    .searchIcon {
      height: 24px;
      cursor: pointer;
    }
  }

  .sort {
    position: relative;

    .sortList {
      background-color: $primaryWhite;
      border-radius: 6px;
      padding-top: 20px;
      position: absolute;
      top: 39px;
      z-index: 1;
      right: -26px;
      width: 251px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);

      .sortBy {
        color: $teritaryBlack;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistSemiBold;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
      }

      .sortOption {
        color: $teritaryBlack;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;

        &:hover {
          background-color: $tertiaryGray9;
        }

        &:active {
          color: $primaryPink;
        }
      }

      .lastOption {
        margin-bottom: 10px;
      }

      .selectedOpt {
        color: $primaryPink;
      }

      &::after {
        content: " ";
        position: absolute;
        right: 25px;
        border-width: 16px;
        top: -31px;
        border-style: solid;
        border-color: transparent transparent $primaryWhite transparent;
      }
    }
  }
}

.actions2 {
  position: relative;
  display: flex;
  align-items: center;
}
.details {
  color: #949494;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.businessNameHeader {
  width: 24%;
  min-width: 24%;
  max-width: 24%;
}
.headerColor {
  width: 29.5%;
  min-width: 29.5%;
  max-width: 29.5%;
}
.contactName {
  width: 19%;
  min-width: 19%;
  max-width: 19%;
}
.email {
  width: 13%;
  min-width: 13%;
  max-width: 13%;
}
.address {
  width: 12%;
  min-width: 12%;
  max-width: 12%;
}

.tableHeader {
  padding: 0 !important;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;

  .filters {
    height: 100%;
    width: 460px;
    background-color: $primaryWhite;
    position: absolute;
    top: 0px;
    right: 0px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 18px;
    padding-bottom: 30px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 18px;
      border-bottom: 1px solid $tertiaryGray2;

      .filtersHeading {
        color: $teritaryBlack;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistSemiBold;
      }

      .cancelFilter {
        height: 24px;
        cursor: pointer;
      }
    }

    .form {
      display: flex;
      flex-direction: column;

      .formInputs {
        display: flex;
        flex-direction: column;
        gap: 28px;
        height: calc(100vh - 150px);
        overflow-y: auto;
        width: calc(100% + 15px);

        .payment {
          padding-top: 28px;

          .paymentLabel {
            color: $tertiaryGray1;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistSemiBold;
            padding-bottom: 13px;
          }

          .paymentInput {
            display: flex;
            gap: 30px;

            .paid {
              display: flex;
              align-items: center;
              min-width: 130px;
              gap: 10px;

              .paidLabel {
                color: $tertiaryGray1;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                font-family: $UrbanistRegular;
                padding-left: 10px;
                min-width: 100px;
              }
              .checkboxField {
                height: 20px;
                width: 20px;
              }
              .checkboxLabel {
                font-size: 14px;
                margin-top: 6px;
              }
            }
          }
        }
        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px $scrollBarColor;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: $scrollBarColor;
          border-radius: 10px;
        }
      }

      .filterActions {
        position: absolute;
        bottom: 30px;
        padding-right: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        gap: 30px;

        .reset {
          color: $primaryPink;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          font-family: $UrbanistRegular;
          cursor: pointer;
        }
      }
    }

    .commonLabel {
      color: $teritaryBlack;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      font-family: $UrbanistRegular;
    }
  }
}

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 34px;
  width: 100%;
  .tabsStyle {
    display: flex;
    flex-direction: column;
    flex: 1;

    .tabList {
      display: flex;
      gap: 40px;
      flex: 1;
      padding-bottom: 6px;

      .tabListItem {
        cursor: pointer;

        .label {
          color: $tertiaryGray1;
          font-family: $UrbanistRegular;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
        }

        .activeLabel {
          color: $primaryPink;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          font-family: $UrbanistSemiBold;
        }

        .border {
          border-top: 3px solid $primaryPink;
          border-radius: 4px;
          position: relative;
          bottom: -4px;
          z-index: 1;
        }
      }
    }

    .hr {
      border-bottom: 1px solid $tertiaryGray3;
      position: relative;
      top: -4px;
    }
  }
}
.masterProductHeader {
  min-width: 36.5%;
  width: 36.5%;
  max-width: 36.5%;
}
