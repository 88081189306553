@import "../../../../../../common/styles/variables.scss";

.cartProductWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 30px 0px;
  .productImageWrapper {
    width: 180px;
    height: 140px;
    .productImage {
      width: 100%;
      height: 100%;
      border: 1px solid $tertiaryGray3;
      border-radius: 6px;
    }
  }
  .productDetails {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
    .productNameQuantityWrapper {
      .productName {
        font-size: 0.875rem;
        color: $teritaryBlack;
        font-family: $UrbanistSemiBold;
        line-height: 20px;
      }
      .productOrderUnitsBtn {
        background-color: transparent;
        outline: none;
        border: none;
        font-size: 0.75rem;
        letter-spacing: 0.5px;
        color: $primaryPink;
        cursor: pointer;
      }
    }
    .productOfferPriceQuantityDetailsWrapper {
      width: 100%;
      margin-top: 27px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .productOfferPriceDetails {
        .productCountryFlagOfferWrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          .productCountryFlag {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 4px;
          }
          .seperator {
            width: 1px;
            height: 20px;
            background-color: $tertiaryGray2;
          }
          .productOffers {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
          }
          .countryText {
            font-size: 0.875rem;
          }
        }
        .productPrice {
          margin-top: 10px;
          font-size: 0.625rem;
          color: $tertiaryGray1;
          font-family: $UrbanistRegular;
          letter-spacing: 0.5px;
          .productPriceText {
            font-size: 1.5rem;
          }
          .productOriginalPrice {
            margin-left: 6px;
            text-decoration: line-through;
          }
          .productDiscountText {
            margin-top: 5px;
            font-size: 0.75rem;
            font-family: $UrbanistRegular;
            font-style: normal;
            font-weight: 400;
          }
        }
      }
    }
    .productActions {
        display: flex;
        justify-content: flex-start;
        align-items: flex-center;
        gap: 20px;

        .removeDefault {
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer;
        }
    }
  }
}

.cartProductWrapper:not(:last-child) {
  border-bottom: 1px solid $tertiaryGray3;
}
