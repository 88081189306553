@import "../../../../../../common/styles/variables.scss";

.supplierDetailsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 13px;
    border-bottom: 1px solid $tertiaryGray3;
    .supplierNamePaymentDetails {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 6px;
      font-family: $UrbanistRegular;
      color: $teritaryBlack;
      .supplierName {
        font-size: 1.5rem;
      }
      .movText {
        font-size: 0.75rem;
        color: $tertiaryGray2;
        margin-left: 6px;
      }
      .modeOfPaymentText {
        font-size: 0.75rem;
        font-family: $UrbanistItalic;
        color: $tertiaryGray2;
      }
    }
    .orderQuantityWrapper {
      color: $tertiaryGray1;
      font-size: 0.75rem;
      letter-spacing: 0.5px;
      .orderCount {
        margin-right: 8px;
        font-size: 0.75rem;
      }
      .orerAmount {
        font-size: 24px;
        color: $tertiaryGray1
      }
    }
  }