@import "../../../index.scss";
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;
  .videoHeader {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    .headingContainer {
      cursor: pointer;
      display: flex;
      .leftArrow {
        width: 24px;
        height: 24px;
      }
      .heading {
        color: $primaryWhite;
        font-size: 20px;
        font-style: normal;
        font-family: $UrbanistRegular;
        line-height: normal;
        letter-spacing: 0.5px;
        margin-left: 4px;
      }
    }
  }
  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    .image {
      width: 400px;
      // height: 440px;
      background-color: $primaryWhite;
      display: flex;
      img {
        width: 100%;
        // height: 100%;
        margin: auto;
      }
    }
    .btns {
      display: flex;
      align-items: center;
      align-self: center;
      width: 90px;
      background-color: $primaryWhite;
      gap: 30px;
      padding: 6px;
      border-radius: 48px;
      margin-top: 54px;
      .zoom {
        height: 24px;
        cursor: pointer;
      }
    }
  }
}
