@import '../../../index.scss';

.parentContainer {
    width: 340px;
    position: absolute;
    background: $primaryWhite;
    padding: 20px;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    top: 45px;
    right:0;
    z-index: 1;

    .heading {
        font-size: 18px;
        font-style: normal;
        line-height: 27px;
        letter-spacing: 0.5px;
        color: $tertiaryGray1;
    }

    .mainContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        margin-top: 20px;

        .infoContaioner {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 6px;
            color: $tertiaryGray1;
            font-size: 12px;
            a{
                text-decoration: none;
                color: $tertiaryGray1; 

                &:hover{
                    color: $primaryPink;
                    text-decoration: underline;
                }
            }
        }

        .phoneContact{
            display: flex;
            flex-direction: row;
            column-gap: 20px;
        }
        .buttonContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;


            .closeButton {
                all: unset;
                cursor: pointer;
                border-radius: 6px;
                background: $primaryPink;
                width: 120px;
                height: 40px;
                color: $primaryWhite;
                font-family: $UrbanistRegular;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                text-align: center;
                margin-left: 30px;
            }
        }

    }

     &::after {
                content: " ";
                position: absolute;
                right: 16px;
                border-width: 16px;
                top: -31px;
                border-style: solid;
                border-color: transparent transparent $primaryWhite transparent;
            }
}