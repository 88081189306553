@import "../../../index.scss";

.packageTrackerConatiner {
    width: 100%;
    height: 184px;
    margin-top: 13px;
    margin-bottom: 30px;
    flex-shrink: 0;
    background-color: $primaryWhite;
    padding: 16px 25px;
    border-radius: 4px;


    .packageStatusTracker {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 25px 0px;
    }

    .statusLine {
        height: 1px;
        width: 170px;
        background-color: $tertiaryGray2;
        opacity: 0.3;
        margin-left: 5px;
        margin-right: 5px;
    }

    .orderID {
        color: $tertiaryGray1;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        letter-spacing: 0.07px;
        margin-left: 5px;
    }

    .statusChip {
        color: $paidColor;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
        border-radius: 40px;
        background: $paidBackgroundColor2;
        height: 20px;
        padding: 5px 10px;
    }


}

.statuscardConatiner {
    position: relative;
    z-index: 0;

    .orderStatusmessage {
        position: absolute;
        z-index: 1;
        width: 150px;
        top: 55px;
        left: 5px;

    }

    .svgIcons {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .statusTitleContainer {
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
    }
    .inactive {
        color: $tertiaryGray3;
    }
    .statusDetailsContainer {
        font-size: 12px;
        font-style: normal;
        line-height: normal;
    }
}


@include minWidth(1400){
    
    .packageStatusTracker{
        width: 94% !important;

        .statusLine {
            width: 19% !important;
           
        }
    } 
    
}


@include minWidth(1900){
   
    .packageStatusTracker{
        width: 98% !important;

    .statusLine {
        width: 19% !important;
       
    }
    .statusTitleContainer{
        font-size:22px !important;
    }

    .statusDetailsContainer{
        font-size: 16px !important;
    }
}
}




@include maxWidth(1340){
    .packageStatusTracker{
        width: 94% !important;
    } 

}