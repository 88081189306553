@import "../../../../index.scss";

.accountVerification,
.resetLink,
.resetComplete {
    padding: 0 30px 29px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__body {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 152px;
    width: 100%;


        .icon {
            display: flex;
            margin-bottom: 34px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .headerText {
            letter-spacing: 0.5px;
            text-transform: uppercase;
            font-family: $UrbanistSemiBold;
            color: $tertiaryGray6; // changed after rebase
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 12px;
        }

        .subheaderText {
            letter-spacing: 0.5px;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: $teritaryBlack;
            max-width: 306px;
            margin-bottom: 50px;

            .email {
                font-family: $UrbanistSemiBold;
                margin-left: 2px;
            }
        }

        .resendSection {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.5px;
            color: $tertiaryGray1;
        }
    }

    .link_primary {
        margin-left: 7px;
        font-weight: 500;
font-size: 14px;
line-height: 19px;
    }
}

.resetLink {
    padding: 27px 30px 29.5px;

    &__goback {
        display: flex;
        gap: 8px;
        width: 100%;
        cursor: pointer;

        span {
            letter-spacing: 0.5px;
            color: $tertiaryGray1;
            font-size: 14px;
            line-height: 17px;
        }
    }

    &__body {
        margin-top: 108px;
    }
}

.resetComplete {
    padding: 137px 30px;

    &__body {
        margin-top: 0;
        flex: revert;

        .icon {
            width: 66px;
            height: 84px;
            margin-bottom: 34px;
        }

        .header-text {
            margin-bottom: 11px;
        }

        .subheader-text {
            margin-bottom: 47px;
        }
    }

  

}
.resetComplete_button {
    width: 100%;
}
