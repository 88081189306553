@import "../../../index.scss";
.card {
    display: flex;
    background-color: $primaryWhite;
    border: 1px solid $primaryWhite;
    align-items: center;
    height: 76px;
    padding-left: 20px;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-right: 20px;
    border-radius: 6px;
    width: 100%;
    cursor: pointer;
    .orderInfo {
        display: flex;
        gap: 16px;
        align-items: center;
        min-width: 18.3%;
        max-width: 18.3%;
        width: 18.3%;
        div:nth-child(2){
            .orderID {
                color: $tertiaryGray10;
                font-size: 20px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                font-family: $UrbanistRegular;
                width: 100%;
               
            }
        }
        
    }
    .amount {
        min-width: 16%;
        max-width: 16%;
        width: 16%;
        .quantity {
            display: flex;
            gap: 9px;
            margin-top: 3px;
        }
    }
    .area {
        min-width: 15.4%;
        max-width: 15.4%;
        width: 15.4%;
        display: flex;
        flex-direction: column;
        gap: 3px;
        padding-right: 40px;
    }
    .placedInfo {
        min-width: 9%;
        max-width: 9%;
        width: 9%;
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    .deliveryStatus {
        display: flex;
        min-width: 10%;
        max-width: 10%;
        width: 10%;
        align-items: center;
        margin: 0px 10px;
        .hr {
            flex:1;
            border-top: 1px solid $tertiaryGray2;
        }
        .onTime {
            color: $activeColor;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistRegular;
            padding: 5px 10px;
            background-color: $activeBgColor;
            border-radius: 40px;
        }
        .delayed {
            color: $delayedColor;
            background-color: $delayedBgColor;
            padding: 5px 10px;
            border-radius: 40px;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            font-family: $UrbanistRegular;
        }
    }
    .deliveryInfo {
        display: flex;
        flex-direction: column;
        gap: 3px;
        padding-left: 30px;
    }
    &:hover{
        border: 1px solid $primaryPink;
        filter: drop-shadow(0px 0px 24px rgba(236, 24, 123, 0.15));
    }
    .commonTxtStyle {
        color: $tertiaryGray10;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .buyerName{
        max-width:60%;
    }
    .cardDetails {
        max-width: 270px;
        width: 100%;
        padding-left: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .align {
            display: flex;
            align-items: center;
        }
      }
    &.hover:hover{
        border: 1px solid $primaryWhite;
        filter: none;
        cursor: default;
    }
}


@include maxWidth(1150){
    .actions{
        gap:5px;
    .actionTxt{
        display: none;
    }
}
}

@include minWidth(1500){

    .details{
        gap:50px;
    }
}

@include minWidth(1700){

    .details{
        gap:90px;
    }
}

.offlineOrder{
    height: 100%;
    background-color: $activeBgColor;
    font-family: $UrbanistRegular;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.5px;
    color: $activeColor;
    border-radius: 40px;
    padding: 5px;
    margin-left: 5px;
}