@import "../../../index.scss";

.commonBtn {
    height: 36px;
    background: $primaryPink;
    border: 1px solid transparent;
    color: $primaryWhite;
    border-radius: 6px;
    text-align: center;
    font-size: 14px;
    font-family: $UrbanistRegular;
    letter-spacing: 0.5px;
    cursor: pointer;
    transition: all ease 0.5s;
    width: 100%;
}

.secondary {
    border: 1px solid $primaryPink;
    background: $primaryWhite;
    color: $primaryPink;
    transition: all ease 0.5s;

    &:hover {
        background: $tertiaryGray4;
    }
}

.disabled {
    background: $tertiaryGray3;
    color: $tertiaryGray2 !important;
    border: transparent;
    cursor: default;

    &:hover {
        background: $tertiaryGray3;
    }
}

.disableSecondary {
    background: $tertiaryGray5;
    background: $tertiaryGray4;
    color: $primaryBlack;
    border-color: $tertiaryGray3;
    cursor: default;
    &:hover {
        background: $tertiaryGray5;
        background: $tertiaryGray4;
    }
}

.link {
    background: transparent;
    border: none;
    color: $primaryPink;
    transition: all ease 0.5s;
    width: max-content;
    &:hover {
        text-decoration: underline;
    }
}
.tabIndex{
    &:focus{
        outline: 1px solid $primaryPink;
    }
}