@import "../../styles/variables.scss";

.productQuantityContainer {
  min-width: 100%;
  // height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // gap: 10px;
  stroke-width: 1px;
  border: 1px solid $tertiaryGray3;
  border-radius: 5px;
  padding: 10px 15px 10px 10px;
  height: fit-content;
  &.isSelected {
    border-color: $primaryPink;
  }
  &.cursor {
    cursor: pointer;
  }

  h3 {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  .subContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .quantityAndPrice {
      color: $teritaryBlack2;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
    }

    .discount {
      color: $primaryPink;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
    }

    .grossPrice {
      color: $tertiaryGray1;
      font-size: 10px;
      font-style: normal;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-decoration: line-through;
    }

    .quantityAndDiscount {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }

    .quantity {
      color: $tertiaryGray2;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      display: flex;
      gap: 10px;
      align-items: center;
      .separator {
        color: $tertiaryGray1;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        font-family: $UrbanistRegular;
      }
    }

    .supplierDiscount {
      color: $primaryLightGreen;
      font-size: 14px;
      font-style: normal;
      font-family: $UrbanistSemiBold;
      line-height: normal;
      letter-spacing: 0.5px;
    }

    .quantityAndAed {
      color: $tertiaryGray1;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 7px;
    }

    .line {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
      color: $tertiaryGray1;
      margin-bottom: 3px;
    }
  }
}
.attributesContainer {
  width: 100%;
 display: flex;
//  justify-content: space-between;
 align-items: flex-start;
  border-top: 1px solid #b9b9b9;
  row-gap: 10px;
  column-gap: 20px;
  flex-wrap: wrap;
  padding-top: 10px;
  // padding-left: 20px;
  height: auto !important;
  // max-height: 90px;
  overflow: hidden;
  .attributesDatasub {
    max-width: 100%;
    display: flex;
    align-items: baseline;
    .attributeKey {
      font-weight: bolder;
      color: #949494;
      font-family: $UrbanistSemiBold;
      font-size: 13px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
    }

    .attributeValue {
      color: $tertiaryBlack;
      font-family: $UrbanistSemiBold;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
      max-width: 100%;
      overflow-wrap: break-word;
    }
  }
}
