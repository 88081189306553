@import "../../../index.scss";

.app {
  display: flex;

  .searchBar {
    width: 100%;
    height: 40px;
    position: relative;
    left: 7px;
    top: 18px;
  }

  .text {
    margin-left: 40px;
    margin-top: 26px;
    font-family: $UrbanistSemiBold;
    color: $tertiaryGray1;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
  }
  .text2 {
    color: $tertiaryGray1;
    margin-top: 5px;
    margin-left: 40px;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.5px;
  }

  .outerBox {
    box-sizing: border-box;
    height: 409px;
    border-radius: 6px;
    margin: 0 40px;
    background: $primaryWhite;
    padding: 30px;
    margin-top: 30px;

    .outerBox1 {
      display: flex;
      justify-content: space-between;
      top: 0px;
      gap: 30px;
      margin-bottom: 34px;
      position: relative;
    }

    .card1 {
      cursor: pointer;
      position: relative;
      width: 100%;
      height: 269px;
      background: $primaryWhite;
      box-shadow: 0px 0px 20px 0px rgba($primaryBlack, 0.1);
      border: 1px solid transparent;
      border-radius: 6px;

      &:hover {
        border: 1px solid $primaryPink;
      }
    }

    .bulkUpload {
      display: none;
    }

    .card2 {
      width: 100%;
      cursor: pointer;
      position: relative;
      height: 269px;
      background: $primaryWhite;
      box-shadow: 0px 0px 20px 0px rgba($primaryBlack, 0.1);
      border: 1px solid transparent;
      border-radius: 6px;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      color: $tertiaryGray1;
      letter-spacing: 0.5px;

      .card2Text {
        padding: 20px 16px 18px 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: $primaryWhite;
        box-shadow: 0px 0px 20px 0px rgba($primaryBlack, 0.1);
        border: 1px solid transparent;
        position: absolute;
        width: 100%;
        border-radius: 6px;
        bottom: 0px;
        gap: 3px;
      }

      &:hover {
        border: 1px solid $primaryPink;
      }
    }

    .contact {
      display: inline-flex;
      gap: 6px;
      // margin-left: 30px;

      .logo {
        display: flex;
        width: 50px;
        height: 50px;
        padding: 0px 1.639px 5.266px 1.521px;
        justify-content: center;
        align-items: center;

        .imgLogo {
          width: 46.84px;
          height: 39.469px;
          flex-shrink: 0;
        }
      }

      .contactText {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;

        .contactText1 {
          color: $tertiaryGray1;
          font-family: $UrbanistRegular;
          font-size: 14px;
          font-style: normal;
          line-height: 20px;
          letter-spacing: 0.05px;
        }

        .contactText2 {
          color: $tertiaryGray1;
          font-family: $UrbanistSemiBold;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.downloadLink {
  text-decoration: none;
  color: $tertiaryGray1;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.05px;
  align-self: flex-start;
}

.downloadText {
  color: $primaryPink;
  cursor: pointer;
  margin-left: 2px;
}

.downloadTextSecondary {
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.05px;
  // margin-left: 4px;
}

.inputField {
  display: none;
}

.rightBodyContainer {
  width: 100%;
}


@include minWidth(1500){

  .outerBox{
    height:500px !important;
    
    .outerBox1{
      margin-bottom: 50px !important;
    }
  }

  .card1{
    height:290px !important;

  }

  .card2{
    height:290px !important;
  }
  
}

@include minWidth(1700){

  .outerBox{
    height:550px !important;
    
    .outerBox1{
      margin-bottom: 70px !important;
    }
  }

  .card1{
    height:340px !important;

  }

  .card2{
    height:340px !important;
  }
  
}