@import "../../../../../index.scss";
.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0px 40px 40px;
    // row-gap: 30px;
    overflow: hidden;
    .tabs {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .border {
            height: 1px;
            background-color: $tertiaryGray3;
            display: flex;
            flex: 1;
            margin-right: 5px;
        }
    }
    .editcelltext {
      display: flex;
      justify-content: flex-end;
      padding: 0 5px 5px 0;
      font-weight: 400;
      align-items: center;
      gap: 4px;
    }
    .textCenter{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      color: #383434;
      font-family: $UrbanistSemiBold;
      font-size: 14px;
      line-height: 12px;
      letter-spacing: 0.5px;
      cursor: pointer;
    }
    .tableContainer {
        background: $primaryWhite;
        overflow-x: auto;
        overflow-y: auto;
        border-radius: 6px;
        position: relative;
        height: 100%;
        margin-bottom: 30px;
        .tableParent {
          height: 100%;
          .table {
            width: 100%;
            position: relative;
            border-spacing: 0 10px;
            margin:10px;
            margin-top:0px;
            tr {
              &:not(:last-child) {
                box-shadow: 0 1px 1px #e3e3e3;
    
              }
              td {
                position: relative;
    
                &:first-child {
                  position: sticky;
                  left: 0;
                  width: 172px;
                  z-index: 3;
                  background: $primaryWhite;
                  // box-shadow: 10px 0px 4px -6px rgba(0, 0, 0, 0.12);
                }
              }
            }
            .theading {
              display: flex;
              flex:1;
              // padding-bottom: 12px;
              box-sizing: content-box;
              text-align: left;
              top: 0;
              position: sticky;
              background-color: #fff;
              // border-bottom: 1px solid $tertiaryGray2;
              z-index: 5;
              .cell {
                padding-top: 12px;
                min-width: 220px;
                width: 106px;
                color: $tertiaryGray2;
                font-family: $UrbanistRegular;
                font-size: 14px;
                letter-spacing: 0.5px;
                border-bottom: 1px solid $tertiaryGray2;
                padding-right: 50px;
                padding-bottom: 12px;
                padding-left: 14px;
                box-sizing: content-box;
                text-align: left;
                top: 0;
                position: sticky;
                background-color: #fff;
                z-index: 1;
                .required {
                  color: $secondaryRed2;
                }
                &:first-child {
                  position: sticky;
                  left: 0;
                  width: 172px;
                  z-index: 5;
                  background: $primaryWhite;
                }
              //   &:nth-child(4), &:nth-child(5), &:nth-child(8) {
              //     min-width: 80px;
              // }
              }
              .prodName {
                min-width: 340px;
              }
              .width {
                min-width: 85px;
              }
            }
            .body {
              // padding-top: 20px;
            }
          }
        }
    
        &::-webkit-scrollbar {
          width: 6px !important;
          height: 6px !important;
        }
    
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          margin-top: 40px !important;
          margin-left: 240px !important;
          background-color: transparent;
        }
    
        &::-webkit-scrollbar-thumb {
          background: $scrollBarColor;
          border-radius: 10px;
        }
    
    }
    .paginationContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}
.popper {
  z-index: 10;
  .popperPaper {
    padding: 14px 10px;
    margin: 10px;
    .condition {
      width: max-content;
      text-wrap: wrap;
      .font {
        color: $tertiaryGray10;
        font-family: $UrbanistRegular;
        font-size: 14px;
        letter-spacing: 0.5px;
      }
    }
    
  }
}