@import "../../../../index.scss";

.dataGridContainer {
    background-color: $primaryWhite;
    min-height: 100px;
}

.overlayWrapper {
    min-height: 100px;
}
