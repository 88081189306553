@import "../../../index.scss";

.container {
  display: flex;
  height: 100%;
  background-color: $backgroundThemeColor;

  .rightBodyContainer {
    display: flex;
    padding: 40px;
    flex: 1;
    // height: 100vh;
    overflow: auto;

    .container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      flex: 1;
      background-color: $primaryWhite;

      .loadingImage {
        width: 260px;
        height: 231px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .para1 {
        color: $teritaryBlack;
        font-family: $UrbanistRegular;
        font-size: 24px;
        letter-spacing: 0.5px;
        margin-top: 30px;
      }

      .para2 {
        color: $tertiaryGray1;
        font-family: $UrbanistRegular;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.07px;
        margin: 4px 0px 20px 0px;
      }

      .button {
        color: $primaryWhite;
        background-color: $primaryPink;
        border-radius: 6px;
        border: 0px;
        width: 180px;
        height: 40px;
        font-family: $UrbanistRegular;
        font-size: 14px;
        letter-spacing: 0.5px;
        cursor: pointer;
      }
    }
  }
}