$primaryWhite: #fff;
$primaryBlack: #000;
$primaryPink: #ec187b;
$primaryGreen: #4c862e;
$primaryLightGreen: #68a14b;
$primaryGrayBackground: #eef2f4;
$primaryRed: #C95100;

$secondaryThickGreen: #82bd54;
$secondaryLightGreen: #6be3a2;
$secondarySkyBlue: #12d9e3;
$secondaryLightBlue: #babffc;
$secondaryPink: #FC9C9C;
$secondaryGold: #fca66c;
$secondaryYellow: #f2ca00;
$secondaryRed: #EB4848;
$secondaryRed2: #E93B3B;


$tertiaryBlack: #1c1919;
$tertiaryGray1: #383434;
$tertiaryGray2: #949494;
$tertiaryGray3: #b9b9b9;
$tertiaryGold: #FF9853;
$tertiaryGray4: #FFF4F9;
$tertiaryGray5: #f2f2f2;
$tertiaryGray6: #16202e;
$tertiaryGray7: #9AA5BA;
$tertiaryGray9: #ffeded;
$tertiaryGray10: #4F4F4F;
$tertiaryGray11: #666666;
$tertiaryGray12: #DFD3D324;
$tertiaryGrays1: #ffeded;
$tertiaryGrays2: #4F4F4F;
$teritaryBlack: #1c1919;
$teritaryBlack2: #242329;
$tertiaryGrays2: #383434;
$tertiaryRed: #EC3E3D;
$tertiaryBlack3: #282828;
$tertiaryBlack4: #171717;
$tertiaryBlack5:#2F2C2D;
$tertiaryBlue: #16202E40;

$headingColorOD: #3D3939;
$backgroundThemeColor: #EEF2F4;

$activeColor: #3A6822;
$activeBgColor: #D4E5CC;
$inactivebgColor: #DDD;
$textColor: #4F4F4F;
$buttonBorderGrey : #D0D1D9;

$secondaryThickGreen: #82bd54;
$secondaryLightGreen: #6be3a2;
$secondarySkyBlue: #12d9e3;
$secondaryLightBlue: #babffc;
$secondaryPink: #FC9C9C;
$secondaryGold: #fca66c;
$secondaryYellow: #f2ca00;
$secondaryRed: #EB4848;
$delayedColor: #AC5113;
$delayedBgColor: #F0DBCC;

$blue: #0000ff;
$gray: #808080;
$gray2: #4f4f4f;
$paidColor: #3a6822;
$paidBackgroundColor: #D4E5CC;
$unpaidBackgroundColor: #ddd;
$paidBackgroundColor2: #E3F0DC;

$breadcrumbColor: #333;
$editDropdownColor: #94949442;
$uploadCardBgColor: #FFF5F5;
$summaryColor: #505050;
$scrollBarColor: #D0D0D0;
$progressBarBgColor: #d9d9d9;

$disabledFormFieldColor: #c2c3c547;
$disabledFormFieldBorderColor: #2c2f3547;
$disabledFontColor: rgb(148, 148, 148);
$UrbanistItalic: "UrbanistItalic";
$UrbanistRegular: "UrbanistRegular";
$UrbanistSemiBold: "UrbanistSemiBold";
$UrbanistLight: "UrbanistLight";
$UrbanistBold: "UrbanistBold";
$UrbanistMedium: "UrbanistMedium";
