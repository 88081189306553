@import "../../../../index.scss";

.heading {
  margin-bottom: 13px;
  color: $tertiaryBlack4;
  font-size: 14px;
  font-style: normal;
  font-family: $UrbanistSemiBold;
  line-height: normal;
  letter-spacing: 0.5px;
}

.relatedProductsWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  overflow: auto;
  padding: 20px 20px 20px 10px;
}

.relatedProduct {
  flex-shrink: 0;
  border-radius: 6px;
  width: 234px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 20px 0px #ffc5df;
  }

  img {
    width: 100%;
    height: 120px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    object-fit: fill;
  }

  .relatedProductData {
    padding: 15px;
  }

  .productName {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}