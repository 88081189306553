@import "../../../../index.scss";

.forgotPassword {
  padding: 30px;
  padding-top: 27px;
  padding-left: 29px;
  overflow: auto;
  width: 100%;
  &__goback {
    display: flex;
    gap: 10px;
    margin-bottom: 61px;
    cursor: pointer;  
    align-items: center;
    img {
      margin-left: 4px;
      width: 14px;
      height: 10px;
    }
    span {
      letter-spacing: 0.5px;
      color: $tertiaryGray1;
      font-size: 14px;
      line-height: 17px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .headerText {
      letter-spacing: 0.5px;
      text-transform: uppercase;
      font-family: $UrbanistSemiBold;
      color: $tertiaryGray6;
      font-size: 24px;
      line-height: 29px;
    }

    .subheaderText {
      letter-spacing: 0.5px;
      color: $teritaryBlack;
      font-size: 14px;
      line-height: 17px;
      max-width: 332px;
    }
  }

  &__form {
    margin-top: 45px;

    .formField {
      .formFieldItem {
        display: flex;
        flex-direction: column;

        .label {
          font-size: 14px;
          line-height: 16px;
          color: $tertiaryBlack;
          display: flex;
          justify-content: space-between;
          margin-top: 1px;
          letter-spacing: 0.5px;
        }

        .forminput {
          background: $primaryWhite;
          border: 1px solid $tertiaryGray4;
          border-radius: 4px;
          height: 36px;
          min-height: 36px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-left: 15px;

          input {
            border: none;
            flex: 1;
          }
        }
      }
    }

    .forgotPasswordButton {
      width: 100%;
      margin-top: 50px;
    }
  }
}

@include maxWidth(1080) {
  .forgotPassword {
    &__header {
      .header-text {
        font-size: 20px;
      }
    }
  }
}
.forgotPwContainer{
  background-color: white;
  height: 100dvh;
}