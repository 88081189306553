@import "../../styles/variables.scss";

.knowYourProduct {
  margin-top: 50px;
  margin-bottom: 30px;

  .knowYourProductTitle {
    margin-bottom: 13px;
    color: $tertiaryBlack4;
    font-size: 14px;
    font-style: normal;
    font-family: $UrbanistSemiBold;
    line-height: normal;
    letter-spacing: 0.5px;
  }

  .knowYourProductContent {
    color: $tertiaryGray1;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.5px;
    width: 100%;
    overflow-wrap: break-word;

    .longText {
      height: 0;
      overflow: hidden;
      display: inline-block;
    }
    .expanded {
      text-overflow: ellipsis;
      overflow: auto;
      height: auto;
    }
  }
  .ellipsisButton {
    color: $primaryPink;
    background-color: $primaryWhite;
    border: none;
    cursor: pointer;
    font-size: 12px;
    margin-left: 5px;
  }
}
