.backNavigationBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  outline: none;
  border: none;
  margin-bottom: 15px;
  cursor: pointer;
  .backNavigationBtnText {
    font-size: 0.875rem;
    letter-spacing: 0.5px;
  }
}
